import {
  createSlice,
  createAsyncThunk,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import $api from '../../core/api';
import { LoginState, UserData } from 'shared/models';



const initialState: LoginState = {
  user: null,
  isLoading: false,
  error: null,
  isAuthChecked: false,
};

export const resetState = createAction('RESET_STATE');

export const checkAuth = createAsyncThunk(
  'auth/checkAuth',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get('/api/user/refresh');
      localStorage.setItem('token', response.data.accessToken);
      return response.data.user;
    } catch (error: any) {
      localStorage.removeItem('token');
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserData>) => {
      state.user = action.payload;
      state.isAuthChecked = true;
      localStorage.setItem('user', JSON.stringify(action.payload));
      // Здесь добавляем флаг авторизации
      if (state.user) {
        state.user.isAuth = true; // Добавляем свойство isAuth к объекту user
      }
    },
    logoutUser: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      state.user = null;
      state.isAuthChecked = false;
    },
    updateUser: (state, action: PayloadAction<Partial<UserData>>) => {
      if (state.user) {
        // console.log("🚀 ~ state.user:", state.user)
        if (typeof state.user.user === 'object' && state.user.user !== null) {
          //@ts-ignore
          state.user.user = { ...state.user.user, ...action.payload };
        } else {
          state.user = { ...state.user, ...action.payload };
        }
        localStorage.setItem('user', JSON.stringify(state.user));
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuth.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(checkAuth.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.user = action.payload;
        state.error = null;
        state.isAuthChecked = true;
      })
      .addCase(checkAuth.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload || 'Authorization error';
        state.isAuthChecked = true; // проверка завершена, даже если неудачная
      });
  },
});

export const { setUser, logoutUser, updateUser } = loginSlice.actions;

export default loginSlice.reducer;
