import React from 'react';
import GreenEmailIcon from 'assets/icons/GreenEmailIcon';
// import { useNavigate } from 'react-router-dom';
import styles from './RecoveryPasswordCheck.module.scss'
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';


const RecoveryPasswordCheck = () => {
  // const navigate = useNavigate();

  // const handleClick = () => {
  //   navigate('/');
  // };

  // setTimeout(() => {
  //   navigate('/login');
  // }, 4000);

  return (
    <div className={styles.recoveryContainer}>
      <GreenEmailIcon />
      <div className={styles.recoverySection}>
        <div className={styles.recoveryContent}>
          <TextUI variant="h1" align='center'>Проверьте почту</TextUI>
          <TextUI variant="bodyL" align='center'>Ссылка для сброса пароля успешно отправлена на вашу почту.</TextUI>
        </div>
        <ButtonUI label="Вернуться к авторизации" variant="main2" to='/login' fullWidth />
      </div>
      {/* <div className='recovery-main check-password'>
        <p className='recovery-main-body'>
          Ссылка для сброса пароля успешно отправлена на вашу почту.{' '}
        </p>
      </div>
      <div className='registration__signin-section'>
        <p>Нет аккаунта?</p>
        <button onClick={handleClick}>Зарегистрироваться</button>
      </div> */}
    </div>
  );
};

export default RecoveryPasswordCheck;
