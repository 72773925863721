import React, { useMemo, useState, useEffect } from 'react';
import MainAppLayout from 'app/layouts/MainAppLayout/MainAppLayout';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import Table from 'components/ui/Table/ui/Table';
import CustomCalendarSelect from 'components/ui/CustomCalendarSelect/ui/CustomCalendarSelect';
import { SingleSelectSearch } from 'components/ui/SingleSelectSearch/ui/SingleSelectSearch';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { OptionType } from 'components/Offers/model/types';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery'; // Хук для мобильной версии
import FiltersIcon from 'assets/icons/FiltersIcon'; // Иконка фильтров
import { MobileFilter } from 'components/ui/MobileFilter'; // Добавляем MobileFilter

import styles from './StatisticByOffers.module.scss';

const getDefaultDateRange = (): [Date, Date] => {
  // const endDate = new Date();
  // const startDate = new Date();
  // startDate.setMonth(startDate.getMonth() - 2);  // Два месяца назад
  // return [startDate, endDate];
  const today = new Date();
  // Set the start date to January 1st of the current year
  const startOfYear = new Date(today.getFullYear(), 0, 1); 
  return [startOfYear, today];
};

const StatisticByOffers = () => {
  const data: any = [
    {
      offerId: '123456',
      offerName: 'ВТБ',
      product: 'Дебетовая карта',
      all: 195,
      approved: 1,
      inProgress: 192,
      rejected: 3,
      date: '2024-07-22',
    },
    {
      offerId: '969696',
      offerName: 'Сбер',
      product: 'Кредитная карта',
      all: 195,
      approved: 2,
      inProgress: 192,
      rejected: 3,
      date: '2024-07-19',
    },
    {
      offerId: '222333',
      offerName: 'ВТБ',
      product: 'Кредитная карта',
      all: 195,
      approved: 3,
      inProgress: 192,
      rejected: 3,
      date: '2024-09-28',
    },
    {
      offerId: '181818',
      offerName: 'Сбер',
      product: 'Дебетовая карта',
      all: 195,
      approved: 4,
      inProgress: 192,
      rejected: 3,
      date: '2024-09-29',
    },
  ];

  const [filteredData, setFilteredData] = useState(data);
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultDateRange());
  const [selectedOffer, setSelectedOffer] = useState<string | null>(null);
  const [resetSelect, setResetSelect] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для мобильного модального окна
  const [resetDates, setResetDates] = useState<boolean>(false);
  const isMobile = useMediaQuery(768); // Определяем мобильную версию

  const offerOptions = useMemo(() => {
    return data.map((offer: any) => ({ label: offer.offerName, value: offer.offerId }));
  }, [data]);

  const columns = useMemo(() => [
    {
      accessorKey: 'offerId',
      header: 'Оффер ID',
    },
    {
      accessorKey: 'offerName',
      header: 'Оффер',
      cell: (info: any) => (
        <div>
          <strong>{info.getValue()}</strong>
          <div>{info.row.original.product}</div>
        </div>
      ),
      className: styles.offerColumn, // Добавляем класс для стилизации столбца "Оффер"
    },
    {
      accessorKey: 'all',
      header: 'Все',
      className: styles.withSeparator, // Добавляем класс для разделительной линии
    },
    {
      accessorKey: 'approved',
      header: 'Принято',
    },
    {
      accessorKey: 'inProgress',
      header: 'В работе',
    },
    {
      accessorKey: 'rejected',
      header: 'Отклонено',
    },
  ], []);

  useEffect(() => {
    let filtered = data;

    if (dateRange) {
      const [startDate, endDate] = dateRange;
      filtered = filtered?.filter((item: any) => {
        const itemDate = new Date(item?.date);
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    if (selectedOffer) {
      filtered = filtered.filter((item: any) => item.offerId === selectedOffer);
    }

    setFilteredData(filtered);
  }, [dateRange, selectedOffer]);

  const handleReset = () => {
    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1);
  
    setSelectedOffer(null);
    setResetSelect(true);
    setDateRange([startOfYear, today]);
    setFilteredData(data); 
    localStorage.setItem('dateRange', JSON.stringify([startOfYear, today])); 
      setTimeout(() => setResetDates(false), 100);
  };
  

useEffect(() => {
  let filtered = data;

  if (dateRange) {
    const [startDate, endDate] = dateRange;
    filtered = filtered.filter((item: any) => {
      const itemDate = new Date(item.date);
      return itemDate >= startDate && itemDate <= endDate;
    });
  }

  if (selectedOffer) {
    filtered = filtered.filter((item: any) => item.offerId === selectedOffer);
  }

  setFilteredData(filtered);
}, [dateRange, selectedOffer]);

  

  const handleDateChange = (range: [Date, Date]) => {
    setDateRange(range);
    localStorage.setItem('dateRange', JSON.stringify(range)); // Сохраняем диапазон в LocalStorage
  };

  const handleOfferSelect = (selected: OptionType | null) => {
    if (selected) {
      const selectedValue = typeof selected.value === 'string' ? selected.value : String(selected.value);
      setSelectedOffer(selectedValue);
    } else {
      setSelectedOffer(null);
    }
    setResetSelect(false);
  };

  return (
    <MainAppLayout>
      <TextUI variant="h1" className={styles.marginBottom}>Статистика по офферам</TextUI>

      {isMobile ? (
        <>
          {/* Иконка для открытия фильтров на мобильной версии */}
          <div className={styles.filterMenu} onClick={() => setIsModalOpen(true)}>
            <FiltersIcon />
          </div>

          <div className={styles.twoFilters}>
            <SingleSelectSearch
              options={offerOptions}
              onOptionSelect={handleOfferSelect}
              placeholder="Поиск по офферу"
              reset={resetSelect}
            />
          </div>

          {/* Модальное окно фильтров для мобильной версии */}
          {isModalOpen && (
            <MobileFilter
              title="Параметры"
              onClose={() => setIsModalOpen(false)}
              onShow={() => setIsModalOpen(false)}
              onReset={handleReset}
              filterBtnClassName={styles.offerBtnMobileFilter}
            >
              <CustomCalendarSelect onDateChange={handleDateChange} resetDates={resetDates} dateRange={dateRange} />
            </MobileFilter>
          )}
        </>
      ) : (
        <div className={styles.filters}>
          <div className={styles.twoFilters}>
            <SingleSelectSearch
              options={offerOptions}
              onOptionSelect={handleOfferSelect}
              placeholder="Поиск по офферу"
              reset={resetSelect}
            />
            <CustomCalendarSelect
              onDateChange={handleDateChange}
              resetDates={resetDates}
              dateRange={dateRange}
              title="Дата заявки"
            />
          </div>
          <div className={styles.filterButtons}>
            {/* <ButtonUI label="Показать" variant="main" height='42px' onClick={() => { }} /> */}
            <ButtonUI label="Сбросить фильтр" variant="main2" height='42px' onClick={handleReset} />
          </div>
        </div>
      )}

      <div className={styles.statistics}>
        <Table columns={columns} data={filteredData} showTitleRow tablePageSize={Number(7)} />
      </div>
    </MainAppLayout>
  );
};

export default StatisticByOffers;
