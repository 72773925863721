import React from "react";

export const useMediaQuery = (width: number) => {
    const [isMatch, setIsMatch] = React.useState(window.innerWidth <= width);

    React.useEffect(() => {
        const handleResize = () => {
            setIsMatch(window.innerWidth <= width);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [width]);

    return isMatch;
};

// use: const isMobile = useMediaQuery(768);