import React from 'react'
import AuthLayout from 'app/layouts/AuthLayout/AuthLayout'
import RegistrationForm from 'components/Forms/RegisterForm/RegistrationForm'

export default function RegistrationPage() {
    return (
        <AuthLayout>
            <RegistrationForm />
        </AuthLayout>
    )
}

