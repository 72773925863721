import React from 'react'
import AuthLayout from 'app/layouts/AuthLayout/AuthLayout'
import RegistrationDone from 'components/RegistrationDone/RegistrationDone'

export default function RegistrationDonePage() {
    return (
        <AuthLayout>
            <RegistrationDone />
        </AuthLayout>
    )
}