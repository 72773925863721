import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface PrivateRouteProps {
    redirectTo?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ redirectTo = '/login' }) => {
    const isAuth = useSelector((state: any) => state.login?.user?.isAuth); // Измените под ваш store
    return isAuth ? <Outlet /> : <Navigate to={redirectTo} />;
};

export default PrivateRoute;
