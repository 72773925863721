import LoadingOverlay from 'components/ui/LoadingOverlay/LoadingOverlay';
import React, { useState } from 'react';
import { useFetchPartnerQuery } from 'services/api';
import styles from './PartnerDetails.module.scss';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import arrowIcon from '../../assets/icons/arrow icon.svg'
import upIcon from '../../assets/icons/upicon.svg'


interface PartnerDetailsProps {
  partnerId: string;
}

const PartnerDetails: React.FC<PartnerDetailsProps> = ({ partnerId }) => {
  const { data: partner, error, isLoading } = useFetchPartnerQuery(partnerId);
  // console.log("🚀 ~ partner:", partner)

  const [openProfileForm, setOpenProfileForm] = useState(true)

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error loading partner data</div>;
  }

  const handleOpenMobileBox = () => {
    setOpenProfileForm(true)
  }

  const handleCloseMobileBox = () => {
    setOpenProfileForm(false)
  }


  return (
    <div className={styles.partnerDetails}>

      {openProfileForm && partner ? (
        <>
          <div className={styles.mobileArrow}>
            <TextUI variant="h2" >Агент</TextUI>
            <img src={upIcon} onClick={handleCloseMobileBox} className={styles.upIcon} />
          </div>
          <div className={styles.details}>
            <div className={styles.detailsElements}><p className={styles.ProfileFormProfileForm}>ID:</p> <p>{partner.affiseId}</p></div>
            <div className={styles.detailsElements}><p className={styles.ProfileFormProfileForm}>Номер договора:</p> <p>{partner.phone}</p></div>
            <div className={styles.detailsElements}><p className={styles.ProfileFormProfileForm}>ФИО:</p> <p>{partner.name}</p></div>
            <div className={styles.detailsElements}><p className={styles.ProfileFormProfileForm}>Телефон:</p> <p>{partner.customFields[1].value}</p></div>
            <div className={styles.detailsElements}><p className={styles.ProfileFormProfileForm}>Email:</p> <p>{partner.email}</p></div>
            <div className={styles.detailsElements}><p className={styles.ProfileFormProfileForm}>Юр. лицо:</p> <p>{partner.login}</p></div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.mobileArrow}>
            <TextUI variant="h2" >Агент</TextUI>
            <img src={arrowIcon} onClick={handleOpenMobileBox} className={styles.upIcon} />
          </div>
        </>
      )}


    </div>
  );
};

export default PartnerDetails;
