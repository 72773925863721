import React from 'react';
// import { useDispatch } from 'react-redux';

import styles from './Step9.module.scss';
import TelegramIcon from 'assets/icons/TelegramIcon';

// interface Step7Props {
//     setIsFormValid: (isValid: boolean) => void; // Проп для управления состоянием валидности формы
//     onFormSubmit: (data: any) => void; // Проп для обработки отправки данных формы
// }

const Step9: React.FC = ({ }) => {
    // const dispatch = useDispatch();

    const handleFormSubmit = (data: any) => {
        console.log("🚀 ~ handleFormSubmit ~ data:", data);
    };

    const handleFormValidity = (data: any) => {
        console.log("🚀 ~ handleFormValidity ~ data:", data);
    };

    return (
        <div className={styles.step9}>
            <div className={styles.stepWrapper}>
                <TelegramIcon />
                <div className={styles.textCenter}>Банк отправил Клиенту СМС
                    с пакетом документов
                    для подписания на открытие счета
                    и кредитного договора</div>
            </div>
        </div>
    );
};

export default Step9;
