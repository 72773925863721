import React from 'react'
import AuthLayout from 'app/layouts/AuthLayout/AuthLayout'
import RecoveryPasswordDone from 'components/recovery-password/RecoveryPasswordDone/RecoveryPasswordDone'

export default function RecoveryPasswordDonePage() {
    return (
        <AuthLayout>
            <RecoveryPasswordDone />
        </AuthLayout>
    )
}