import React from 'react';

const EyeCloseIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.12106 4.70696C5.73054 4.31643 5.09737 4.31643 4.70685 4.70696C4.31632 5.09748 4.31632 5.73065 4.70685 6.12117L18.6056 20.0199C18.9961 20.4105 19.6293 20.4105 20.0198 20.0199C20.4104 19.6294 20.4104 18.9963 20.0198 18.6057L18.7756 17.3615C20.0208 16.4869 21.0899 15.3779 21.9194 14.0986C22.746 12.8237 22.746 11.1758 21.9193 9.90094C19.812 6.65116 16.1584 4.49985 11.9999 4.49985C10.2185 4.49985 8.52974 4.89462 7.01551 5.60141L6.12106 4.70696ZM9.24288 7.82878L16.171 14.7569C16.6948 13.9663 16.9999 13.0185 16.9999 11.9998C16.9999 9.23985 14.7599 6.99985 11.9999 6.99985C10.9812 6.99985 10.0334 7.30498 9.24288 7.82878Z'
        fill='#2F2F33'
      />
      <path
        d='M2.0805 14.0988C1.25382 12.8239 1.25374 11.176 2.0804 9.9011C2.62468 9.06168 3.27214 8.29555 4.00482 7.62084C4.04973 7.67842 4.09867 7.73388 4.15162 7.78684L7.14892 10.7841C7.05158 11.1734 6.99989 11.5806 6.99989 11.9998C6.99989 14.7598 9.23989 16.9998 11.9999 16.9998C12.4191 16.9998 12.8264 16.9482 13.2156 16.8508L15.3748 19.01C14.3056 19.3287 13.1728 19.4998 11.9999 19.4998C7.84134 19.4998 4.18776 17.3485 2.0805 14.0988Z'
        fill='#2F2F33'
      />
    </svg>
  );
};

export default EyeCloseIcon;
