import React from 'react'
import AuthLayout from 'app/layouts/AuthLayout/AuthLayout'
import { LoginForm } from 'components/Forms/LoginForm'

export default function LoginPage() {
    return (
        <AuthLayout>
            <LoginForm />
        </AuthLayout>
    )
}