import React from 'react';

const PhoneIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
    >
      <g clipPath='url(#clip0_689_2869)'>
        <path
          d='M29.0001 21.2133C28.9267 20.9992 28.8001 20.8074 28.6322 20.6558C28.4643 20.5041 28.2605 20.3978 28.0401 20.3466L20.0401 18.52C19.8229 18.4707 19.5969 18.4767 19.3826 18.5372C19.1684 18.5978 18.9727 18.711 18.8134 18.8666C18.6267 19.04 18.6134 19.0533 17.7467 20.7066C14.871 19.3815 12.5667 17.0678 11.2534 14.1866C12.9467 13.3333 12.9601 13.3333 13.1334 13.1333C13.289 12.974 13.4023 12.7783 13.4628 12.5641C13.5234 12.3498 13.5293 12.1238 13.4801 11.9066L11.6534 3.99996C11.6023 3.77955 11.4959 3.57579 11.3443 3.40784C11.1927 3.2399 11.0008 3.1133 10.7867 3.03996C10.4754 2.92875 10.1538 2.84836 9.82675 2.79996C9.48975 2.72182 9.14586 2.67716 8.80008 2.66663C7.17342 2.66663 5.61338 3.31281 4.46316 4.46304C3.31294 5.61326 2.66675 7.1733 2.66675 8.79996C2.6738 14.2436 4.8394 19.4622 8.68862 23.3114C12.5378 27.1606 17.7565 29.3262 23.2001 29.3333C24.0055 29.3333 24.8031 29.1746 25.5472 28.8664C26.2913 28.5582 26.9675 28.1064 27.537 27.5369C28.1065 26.9673 28.5583 26.2912 28.8665 25.5471C29.1748 24.803 29.3334 24.0054 29.3334 23.2C29.3338 22.8606 29.3071 22.5217 29.2534 22.1866C29.1974 21.8554 29.1126 21.5298 29.0001 21.2133Z'
          fill='#E2E2ED'
        />
      </g>
      <defs>
        <clipPath id='clip0_689_2869'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PhoneIcon;
