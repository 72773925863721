import React from 'react';

const PasswordIcon = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='secure_safety_password_protection_security_lock_padlock_icon_219355 2'>
        <g id='49. Lock'>
          <path
            id='Vector'
            d='M24 10.6667V8C24 5.87827 23.1571 3.84344 21.6569 2.34315C20.1566 0.842855 18.1217 0 16 0C13.8783 0 11.8434 0.842855 10.3431 2.34315C8.84285 3.84344 8 5.87827 8 8V10.6667C6.94011 10.6698 5.92453 11.0923 5.17507 11.8417C4.42561 12.5912 4.00317 13.6068 4 14.6667V24C4 26.1217 4.84285 28.1566 6.34315 29.6569C7.84344 31.1571 9.87827 32 12 32H20C22.1217 32 24.1566 31.1571 25.6569 29.6569C27.1571 28.1566 28 26.1217 28 24V14.6667C27.9968 13.6068 27.5744 12.5912 26.8249 11.8417C26.0755 11.0923 25.0599 10.6698 24 10.6667ZM10.6667 8C10.6667 6.58551 11.2286 5.22896 12.2288 4.22876C13.229 3.22857 14.5855 2.66667 16 2.66667C17.4145 2.66667 18.771 3.22857 19.7712 4.22876C20.7714 5.22896 21.3333 6.58551 21.3333 8V10.6667H10.6667V8ZM17.3333 24C17.3333 24.3536 17.1929 24.6928 16.9428 24.9428C16.6928 25.1929 16.3536 25.3333 16 25.3333C15.6464 25.3333 15.3072 25.1929 15.0572 24.9428C14.8071 24.6928 14.6667 24.3536 14.6667 24V18.6667C14.6667 18.313 14.8071 17.9739 15.0572 17.7239C15.3072 17.4738 15.6464 17.3333 16 17.3333C16.3536 17.3333 16.6928 17.4738 16.9428 17.7239C17.1929 17.9739 17.3333 18.313 17.3333 18.6667V24Z'
            fill='#E2E2ED'
          />
        </g>
      </g>
    </svg>
  );
};

export default PasswordIcon;
