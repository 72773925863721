import React from 'react';
import MainAppLayout from 'app/layouts/MainAppLayout/MainAppLayout';
import { ProfileForm } from 'components/Forms/ProfileForm';
import { useSelector } from 'react-redux';
import PartnerDetails from 'components/PartnerDetails/PartnerDetails';
import { RootState } from 'app/store';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';

import styles from './SettingsPage.module.scss';
import ChangePasswordForm from 'components/Forms/ChangePasswordForm/ui/ChangePasswordForm';

const SettingsPage = () => {
  const partnerId = useSelector((state: RootState) => state.login.user?.partnerId);

  return (
    <MainAppLayout>
      <TextUI variant="h1" className={styles.marginBottom}>Настройки</TextUI>
      <div className={styles.settingsPage}>
        <div className={styles.forms}>
          <ProfileForm />
          <ChangePasswordForm />
        </div>
        <div className={styles.partnerDetails}>
          {partnerId && <PartnerDetails partnerId={partnerId} />}
        </div>
      </div>
    </MainAppLayout>
  );
};

export default SettingsPage;
