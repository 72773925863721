import React from 'react';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';

import styles from './ButtonSteps.module.scss';
import ArrowLeft from 'assets/icons/ArrowLeft';
import ArrowRightWhite from 'assets/icons/ArrowRightWhite';

interface BottonStepsProps {
    step: number;
    onNext: () => void;
    onPrev: () => void;
    disableNext?: boolean; // Опционально, если нужно отключить кнопку "Продолжить"
}

const BottonSteps: React.FC<BottonStepsProps> = ({ step, onNext, onPrev, disableNext }) => {

    const buttonBlockClass = (step === 9 || step === 10)
        ? `${styles.buttonBlock} ${styles.oneButtonCenter}`
        : styles.buttonBlock;

    return (
        <div className={buttonBlockClass}>
            {step > 0 && step !== 9 && step !== 10 && (
                // <button className={styles.prevButton} onClick={onPrev}>
                //     Назад
                // </button>
                <ButtonUI label="Вернуться" variant="main2" onClick={onPrev} iconLeft={<ArrowLeft />} />
            )}
            <ButtonUI label="Продолжить" variant="secondary" onClick={onNext} iconRight={<ArrowRightWhite />}
                disabled={disableNext} />
        </div>
    );
};

export default BottonSteps;
