import SuccessIcon from 'assets/icons/SuccessIcon';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';

import styles from './RecoveryPasswordDone.module.scss'

// import React, { useEffect, useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { unwrapResult } from '@reduxjs/toolkit';
// import { AppDispatch } from 'app/store';
// import { getUserByEmail } from 'features/get-user-by-email/get-user-by-email-slice';

const RecoveryPasswordDone = () => {
  // const location = useLocation();
  // const navigate = useNavigate();
  // const dispatch = useDispatch<AppDispatch>();
  // const { email } = location.state || {};

  // const [user, setUser] = useState<any>('');

  // useEffect(() => {
  //   if (email) {
  //     dispatch(getUserByEmail(email))
  //       .then(unwrapResult)
  //       .then((result: any) => {
  //         setUser(result);
  //       });
  //   }
  // }, [navigate, email]);

  return (
    <div className={styles.recoveryContainer}>
      <SuccessIcon />
      <div className={styles.recoverySection}>
        <div className={styles.recoveryContent}>
          <TextUI variant="h1" align='center'>Пароль изменен</TextUI>
          <TextUI variant="bodyL" align='center'>Ваш пароль успешно изменен. Теперь вы можете использовать новый
            пароль для входа.</TextUI>
        </div>
        <ButtonUI label="Войти" variant="main" to='/login' fullWidth />
      </div>
    </div>
  );
};

export default RecoveryPasswordDone;
