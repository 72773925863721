import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Base64 } from 'js-base64';
import { AppDispatch } from 'app/store';
import { InputUI } from 'components/ui/InputUI/InputUI';
import { InputTypeEnum } from 'shared/models';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import LoadingOverlay from 'components/ui/LoadingOverlay/LoadingOverlay';
import { useUpdatePasswordMutation } from 'services/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import styles from './RecoveryPasswordForm.module.scss'

function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'status' in error &&
    'data' in error
  );
}

const RecoveryPasswordForm = () => {
  const navigate = useNavigate();
  const { recoverPasswordLink, encodedEmail, timestamp }: any = useParams();
  // console.log("🚀 ~ RecoveryPasswordForm ~ timestamp:", timestamp)
  // console.log("🚀 ~ RecoveryPasswordForm ~ useParams:", useParams())

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [noExistEmailError, setNoExistEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState('');
  // const [expirationError, setExpirationError] = useState('');

  const [updatePassword, { isLoading, error }] = useUpdatePasswordMutation();

  useEffect(() => {
    if (recoverPasswordLink && encodedEmail && timestamp) {
      try {
        const decodedEmail = Base64.decode(encodedEmail);
        setEmail(decodedEmail);

        // Проверка истечения срока действия
        const currentTime = Date.now();
        if (currentTime - Number(timestamp) > 900000) { // 900000 ms = 15 минут
          console.error('Срок действия ссылки истек');
          // navigate('/error', { state: { error: 'Срок действия ссылки истек' } });
        }
      } catch (error) {
        console.error('Invalid token');
        navigate('/error');
      }
    } else {
      navigate('/error');
    }
  }, [recoverPasswordLink, encodedEmail, timestamp, navigate]);


  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (password !== repeatPassword) {
      setPasswordError(true);
      return;
    }

    if (password !== repeatPassword) {
      setPasswordError(true);
      return;
    }

    setPasswordError(false);
    setNewPasswordError('');

    try {
      const result = await updatePassword({ email, newPassword: password, recoverPasswordLink, timestamp }).unwrap();

      if (result) {
        navigate('/recovery-password-done', { state: { email } });
      }
    } catch (err) {
      console.error("Error updating password:", err);
    }
  };

  const handleClick = () => {
    navigate('/');
  };

  return (
    <>
      <div className={styles.recoveryContainer}>
        {/* {isLoading && (
          <div className={'register-container__absolute-layer'}>
            <div>Spinner</div>
          </div>
        )} */}
        {isLoading && <LoadingOverlay />}
        <div className={styles.recoverySection}>
          <TextUI variant="h1" align='center'>Восстановление пароля</TextUI>

          <form onSubmit={handleSubmit} className={styles.recoveryContent}>
            {emailError && (
              <div>Error</div>
            )}
            {noExistEmailError && (
              <div>Error</div>
            )}
            <InputUI
              field={{
                value: password,
                onChange: (e) => setPassword(e.target.value),
                onBlur: () => { },
              }}
              id="new-password"
              type={InputTypeEnum.Password}
              name="new-password"
              placeholder="ВВЕДИТЕ ПАРОЛЬ"
              error={newPasswordError}
              onBlur={() => { }}
            />
            <InputUI
              field={{
                value: repeatPassword,
                onChange: (e) => setRepeatPassword(e.target.value),
                onBlur: () => { },
              }}
              id="confirm-password"
              type={InputTypeEnum.Password}
              name="confirm-password"
              placeholder="ПОДТВЕРДИТЕ ПАРОЛЬ"
              error={passwordError ? "Пароли не совпадают" : ''}
              onBlur={() => { }}
            />
            {/* {error && 'data' in error && Array.isArray((error.data as any).errors) && (
              <div className={styles.errorRecoveryPass}>{((error.data as { errors: { msg: string }[] }).errors[0]?.msg)}</div>
            )} */}
            {error && (
              <div className={styles.errorRecoveryPass}>
                {isFetchBaseQueryError(error) && error.data && typeof error.data === 'object' && 'errors' in error.data && Array.isArray(error.data.errors)
                  ? error.data?.errors[0]?.msg || ((error.data as unknown) as { message: string }).message
                  : 'Неизвестная ошибка'}
              </div>
            )}
            <ButtonUI type="submit" label="Готово" variant="main" fullWidth />
            {(error || newPasswordError || passwordError) && <ButtonUI label="Вернуться к авторизации" variant="main2" to='/login' fullWidth />
            }
          </form>
        </div>
      </div>
    </>
  );
};

export default RecoveryPasswordForm;
