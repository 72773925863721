import React, { useMemo, useState, useEffect } from 'react';
import MainAppLayout from 'app/layouts/MainAppLayout/MainAppLayout';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import Table from 'components/ui/Table/ui/Table';
import CustomCalendarSelect from 'components/ui/CustomCalendarSelect/ui/CustomCalendarSelect';
import { SingleSelectSearch } from 'components/ui/SingleSelectSearch/ui/SingleSelectSearch';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { OptionType } from 'components/Offers/model/types';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery'; // Хук для мобильной версии
import FiltersIcon from 'assets/icons/FiltersIcon'; // Иконка фильтров
import { MobileFilter } from 'components/ui/MobileFilter'; // Добавляем MobileFilter
import { useGetApplicationsQuery } from 'services/api';

import styles from './Applications.module.scss';

import Settingsicon from 'assets/icons/settings-icon.svg';

const mockApplications = [
  {
    id: 1,
    date: '2024-10-01T12:45:05',
    offer: { id: 1694, name: 'ВТБ', category: 'Дебетовая карта' },
    status: 'approved',
    paymentStatus: 'paid',
  },
  {
    id: 2,
    date: '2024-10-02T12:45:05',
    offer: { id: 1692, name: 'Альфа-Банк', category: 'Дебетовая карта' },
    status: 'inProgress',
    paymentStatus: 'pending',
  },
  {
    id: 3,
    date: '2024-10-01T12:45:05',
    offer: { id: 1693, name: 'ВТБ', category: 'Кредитная карта' },
    status: 'rejected',
    paymentStatus: 'unpaid',
  },
  {
    id: 4,
    date: '2024-10-01T12:45:05',
    offer: { id: 1690, name: 'Альфа-Банк', category: 'Кредитная карта' },
    status: 'inProgress',
    paymentStatus: 'paid',
  },
];

const translateStatus = (status: string) => {
  switch (status) {
    case 'approved':
      return 'Одобрено';
    case 'inProgress':
      return 'Не завершена';
    case 'rejected':
      return 'Отказано';
    default:
      return status;
  }
};

const translatePaymentStatus = (paymentStatus: string) => {
  switch (paymentStatus) {
    case 'paid':
      return 'Оплачено';
    case 'pending':
      return 'Ожидает оплаты';
    case 'unpaid':
      return 'Не оплачено';
    default:
      return paymentStatus;
  }
};

// Функция для получения диапазона дат
const getDefaultRange = (): [Date, Date] => {
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 150);
  return [sevenDaysAgo, today];
};

const ApplicationsPage = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultRange());
  const [selectedOffer, setSelectedOffer] = useState<number | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<
    string | null
  >(null);
  const [resetSelect, setResetSelect] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для мобильного модального окна
  const [resetDates, setResetDates] = useState(false);
  const isMobile = useMediaQuery(768); // Определяем мобильную версию
  const [visibleColumns, setVisibleColumns] = useState({
    status: true,
    paymentStatus: true,
    client: false,
  });

  const { data: applications = [], error, isLoading } = useGetApplicationsQuery({ page: 1 });

  // Моковые данные для фильтров
  // const applications = mockApplications;

  const offerOptions = useMemo(
    () => [
      { label: 'ВТБ', value: '1' },
      { label: 'Сбер', value: '2' },
    ],
    []
  );

  const statusOptions = [
    { label: 'Одобрено', value: 'approved' },
    { label: 'Не завершена', value: 'inProgress' },
    { label: 'Отказано', value: 'rejected' },
];

const paymentStatusOptions = [
    { label: 'Оплачено', value: 'paid' },
    { label: 'Ожидает оплаты', value: 'pending' },
    { label: 'Не оплачено', value: 'unpaid' },
];

const columns = useMemo(
  () => [
      { accessorKey: 'id', header: 'ID заявки' },
      {
          accessorKey: 'date',
          header: 'Дата заявки',
          cell: (info: any) => (
              <>
                  <div className={styles.date}>
                      {new Date(info.getValue()).toLocaleDateString()}
                  </div>
                  <div className={styles.time}>
                      {new Date(info.getValue()).toLocaleTimeString()}
                  </div>
              </>
          ),
      },
      {
          accessorKey: 'offer',
          header: 'Оффер',
          cell: (info: any) => (
              <>
                  <div className={styles.offerName}>{info.row.original.offer.name}</div>
                  <div className={styles.offerCategory}>{info.row.original.offer.category}</div>
              </>
          ),
      },
      visibleColumns.status && {
          accessorKey: 'status',
          header: 'Статус заявки',
          cell: (info: any) => translateStatus(info.getValue()),
      },
      visibleColumns.paymentStatus && {
          accessorKey: 'paymentStatus',
          header: 'Статус оплаты',
          cell: (info: any) => translatePaymentStatus(info.getValue()),
      },
      visibleColumns.client && {
          accessorKey: 'client',
          header: 'ФИО клиента',
      },
  ].filter(Boolean),
  [visibleColumns]
);


  const [filteredData, setFilteredData] = useState(applications);

  useEffect(() => {
    let filtered = applications;

    if (dateRange) {
      const [startDate, endDate] = dateRange;
      filtered = filtered.filter((item: any) => {
        const itemDate = new Date(item.date);
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    if (selectedOffer !== null) {
      filtered = filtered.filter(
        (item: any) => item.offer.id === selectedOffer
      );
    }

    if (selectedStatus) {
      filtered = filtered.filter((item: any) => item.status === selectedStatus);
    }

    if (selectedPaymentStatus) {
      filtered = filtered.filter(
        (item: any) => item.paymentStatus === selectedPaymentStatus
      );
    }

    setFilteredData(filtered);
  }, [
    dateRange,
    selectedOffer,
    selectedStatus,
    selectedPaymentStatus,
    applications,
  ]);

  const handleReset = () => {
    setSelectedOffer(null);
    setSelectedStatus(null);
    setSelectedPaymentStatus(null);
    setDateRange(getDefaultRange());
    setFilteredData(applications);
    setResetSelect(true);
    setResetDates(true);
    setTimeout(() => setResetDates(false), 100);
  };

  const handleDateChange = (range: [Date, Date]) => {
    setDateRange(range);
  };

  const handleOfferSelect = (selected: OptionType | null) => {
    setSelectedOffer(selected ? parseInt(selected.value.toString(), 10) : null);
    setResetSelect(false);
  };

  const handleStatusSelect = (selected: OptionType | null) => {
    setSelectedStatus(selected ? selected.value.toString() : null);
    setResetSelect(false);
  };

  const handlePaymentStatusSelect = (selected: OptionType | null) => {
    setSelectedPaymentStatus(selected ? selected.value.toString() : null);
    setResetSelect(false);
  };

  const handleColumnVisibilityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;
    setVisibleColumns((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setVisibleColumns({
      status: checked,
      paymentStatus: checked,
      client: checked,
    });
  };

  return (
    <MainAppLayout>
      <TextUI variant='h1' className={styles.marginBottom}>
        Заявки
      </TextUI>

      {isMobile ? (
        <>
          {/* Иконка для открытия фильтров на мобильной версии */}
          <div
            className={styles.filterMenu}
            onClick={() => setIsModalOpen(true)}
          >
            <FiltersIcon />
          </div>

          <div className={styles.moblieFilter}>
            <SingleSelectSearch
              options={offerOptions}
              onOptionSelect={handleOfferSelect}
              placeholder='Поиск по офферу'
              reset={resetSelect}
            />
          </div>

          {/* Мобильная версия фильтров */}
          {isModalOpen && (
            <MobileFilter
              title='Параметры'
              onClose={() => setIsModalOpen(false)}
              onShow={() => setIsModalOpen(false)}
              onReset={handleReset}
              filterBtnClassName={styles.offerBtnMobileFilter}
            >
              <CustomCalendarSelect
                onDateChange={handleDateChange}
                resetDates={resetDates}
                dateRange={dateRange}
                title='Дата заявки'
              />

              <SingleSelectSearch
                options={statusOptions}
                onOptionSelect={handleStatusSelect}
                placeholder='Все'
                title='Статус заявки'
                reset={resetSelect}
                simpleSelect
              />
              <SingleSelectSearch
                options={paymentStatusOptions}
                onOptionSelect={handlePaymentStatusSelect}
                placeholder='Все'
                title='Статус оплаты'
                reset={resetSelect}
                simpleSelect
              />
              <TextUI variant='bodyM'>Отображение колонок в таблице</TextUI>


              {/* Блок выбора видимости колонок */}
              <div className={styles.columnVisibility}>
                <label>
                  <input
                    type='checkbox'
                    name='selectAll'
                    checked={
                      visibleColumns.status &&
                      visibleColumns.paymentStatus &&
                      visibleColumns.client
                    }
                    onChange={handleSelectAll}
                  />
                  Все
                </label>
                <label>
                  <input
                    type='checkbox'
                    name='status'
                    checked={visibleColumns.status}
                    onChange={handleColumnVisibilityChange}
                  />
                  Статус заявки
                </label>
                <label>
                  <input
                    type='checkbox'
                    name='paymentStatus'
                    checked={visibleColumns.paymentStatus}
                    onChange={handleColumnVisibilityChange}
                  />
                  Статус оплаты
                </label>
                <label>
                  <input
                    type='checkbox'
                    name='client'
                    checked={visibleColumns.client}
                    onChange={handleColumnVisibilityChange}
                  />
                  ФИО клиента
                </label>
              </div>
            </MobileFilter>
          )}
        </>
      ) : (
        <div className={styles.filters}>
          {/* <div className={styles.twoFilters}> */}
          <SingleSelectSearch
            options={offerOptions}
            onOptionSelect={handleOfferSelect}
            placeholder='Поиск по офферу'
            reset={resetSelect}
          />
          <CustomCalendarSelect
            onDateChange={handleDateChange}
            resetDates={resetDates}
            dateRange={dateRange}
            title='Дата заявки'
          />
          <SingleSelectSearch
            options={statusOptions}
            onOptionSelect={handleStatusSelect}
            placeholder='Все'
            title='Статус заявки'
            reset={resetSelect}
            simpleSelect={true}
          />
          <SingleSelectSearch
            options={paymentStatusOptions}
            onOptionSelect={handlePaymentStatusSelect}
            placeholder='Все'
            title='Статус оплаты'
            reset={resetSelect}
          />
          {/* </div> */}
          <div className={styles.filterButtons}>
            <ButtonUI
              label='Сбросить фильтр'
              variant='main2'
              height='42px'
              onClick={handleReset}
            />
          </div>
          <div className={styles.settiungsBox}><img src={Settingsicon} /></div>
        </div>
      )}

      <div className={styles.statistics}>
        <Table columns={columns} data={filteredData} tablePageSize={Number(12)} />
      </div>
    </MainAppLayout>
  );
};

export default ApplicationsPage;
