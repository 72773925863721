import React from 'react'
import './not-found.scss'
import ButtonUI from 'components/ui/ButtonUI/ButtonUI'
import ERROR from '../../assets/icons/ERROR.svg'
import NotFounded from '../../assets/icons/notFounded.svg'
import BackIcon from '../../assets/icons/404_back.svg'
import { useNavigate } from 'react-router-dom'



const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/')
  }
  
  return (
    <div className='not-found'>
      <div className="logo">PROFI<span>BROKER</span></div>
      <img src={BackIcon} className='back-icon' />
      <img src={ERROR} className='error-found-img' />

      <ButtonUI label="На главную" variant="main2" onClick={handleNavigate} className='not-found-btn' />

    </div>
  )
}

export default NotFoundPage