import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { CustomInput } from 'components/CustomInput/CustomInput';
import { InputTypeEnum } from 'shared/models';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { RadioButton } from 'components/Forms/RadioButton';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { savePersonalData } from 'features/request/requestSlice';

import styles from './PersonalDataForm.module.scss';

interface PersonalDataFormProps {
  setIsFormValid: (isValid: boolean) => void;
  onFormSubmit: (data: any) => void;
  defaultValues: any;
}

export const PersonalDataForm: React.FC<PersonalDataFormProps> = ({
  setIsFormValid,
  onFormSubmit,
  defaultValues,
}) => {
  const dispatch = useDispatch();

  // Получаем персональные данные из Redux
  const personalData = useSelector(
    (state: any) => state.request.data.personalData || {}
  );

  // Инициализируем useForm с defaultValues из Redux store
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: defaultValues?.name || '',
      birthDate: defaultValues?.birthDate || '',
      gender: defaultValues?.gender || 'male',
      email: defaultValues?.email || '',
      phone: defaultValues?.phone || '',
      passportNumber: defaultValues?.passportNumber || '',
      issueDate: defaultValues?.issueDate || '',
      departmentCode: defaultValues?.departmentCode || '',
      issuedBy: defaultValues?.issuedBy || '',
      registrationType: defaultValues?.registrationType || 'permanent',
      addressMatches: defaultValues?.addressMatches || 'no',
      registrationAddress: defaultValues?.registrationAddress || '',
      actualAddress: defaultValues?.actualAddress || '',
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = methods;

  // Следим за значением addressMatches и управляем полем actualAddress
  const addressMatches = watch('addressMatches') === 'yes';

  useEffect(() => {
    const subscription = watch((value) => {
      // Сохраняем данные в Redux при каждом изменении
      dispatch(savePersonalData(value));
    });
    return () => subscription.unsubscribe();
  }, [watch, dispatch]);

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid, setIsFormValid]);

  useEffect(() => {
    const emailValue = methods.getValues('email');
    const phoneValue = methods.getValues('phone');

    if (emailValue) {
      trigger('email');
    }

    if (phoneValue) {
      trigger('phone');
    }
  }, [trigger, methods]);

  const onSubmit = (data: any) => {
    onFormSubmit(data); // Вызываем функцию родительского компонента при отправке формы
  };

  const handleAddressMatchesChange = (value: string) => {
    setValue('addressMatches', value);
    if (value === 'yes') {
      setValue('actualAddress', watch('registrationAddress'));
    }
  };

  return (
    <FormProvider {...methods}>
      <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.flexRow}>
          <CardWrapper title="Персональные данные">
            <CustomInput
              name="name"
              type={InputTypeEnum.FIO}
              placeholder="ФАМИЛИЯ ИМЯ ОТЧЕСТВО"
              description="Укажите точно как в паспорте"
              validate={(value) => /^[А-Яа-яЁёA-Za-z\s]+$/.test(value) || 'ФИО должно содержать только буквы и пробелы'}
              required
            />
            <CustomInput
              name="birthDate"
              type={InputTypeEnum.Text}
              placeholder="ДАТА РОЖДЕНИЯ"
              required
            />
            <TextUI color="secondary" variant="bodyS">
              ПОЛ
            </TextUI>

            <div className={styles.genderWrapper}>
              <RadioButton
                name="gender"
                label="Мужской"
                value="male"
                checked={watch('gender') === 'male'}
                onChange={() => setValue('gender', 'male')}
              />
              <RadioButton
                name="gender"
                label="Женский"
                value="female"
                checked={watch('gender') === 'female'}
                onChange={() => setValue('gender', 'female')}
              />
            </div>
            <CustomInput
              name="email"
              type={InputTypeEnum.Email}
              placeholder="ЭЛЕКТРОННАЯ ПОЧТА"
              required
            />
            <CustomInput
              name="phone"
              type={InputTypeEnum.Tel}
              placeholder="МОБИЛЬНЫЙ ТЕЛЕФОН"
              required
            />
          </CardWrapper>

          <div className={styles.column}>
            <TextUI variant="h2">Паспортные данные</TextUI>
            <CustomInput
              name="passportNumber"
              type={InputTypeEnum.Text}
              placeholder="СЕРИЯ И НОМЕР ПАСПОРТА"
              description="12 34 567890"
              required
            />
            <CustomInput
              name="issueDate"
              type={InputTypeEnum.Text}
              placeholder="ДАТА ВЫДАЧИ"
              required
            />
            <CustomInput
              name="departmentCode"
              type={InputTypeEnum.Text}
              placeholder="КОД ПОДРАЗДЕЛЕНИЯ"
              description="123-456"
              required
            />
            <CustomInput
              name="issuedBy"
              type={InputTypeEnum.Text}
              placeholder="КЕМ ВЫДАН"
              description="Укажите точно как в паспорте"
              required
            />
          </div>
        </div>

        <div className={styles.column}>
          <TextUI variant="h2">Адрес регистрации</TextUI>
          <CustomInput
            name="registrationAddress"
            type={InputTypeEnum.Text}
            placeholder="АДРЕС РЕГИСТРАЦИИ"
            description="Город, улица, дом, корпус и квартира"
            required
          />

          <TextUI color="secondary" variant="bodyS">
            РЕГИСТРАЦИЯ
          </TextUI>
          <div className={styles.radioGroup}>
            <RadioButton
              name="registrationType"
              label="Постоянная"
              value="permanent"
              checked={watch('registrationType') === 'permanent'}
              onChange={() => setValue('registrationType', 'permanent')}
            />
            <RadioButton
              name="registrationType"
              label="Временная"
              value="temporary"
              checked={watch('registrationType') === 'temporary'}
              onChange={() => setValue('registrationType', 'temporary')}
            />
          </div>

          <TextUI color="secondary" variant="bodyS">
            ФАКТИЧЕСКИЙ АДРЕС ПРОЖИВАНИЯ СОВПАДАЕТ С АДРЕСОМ РЕГИСТРАЦИИ?
          </TextUI>
          <div className={styles.radioGroup}>
            <RadioButton
              name="addressMatches"
              label="Да"
              value="yes"
              checked={addressMatches}
              onChange={() => handleAddressMatchesChange('yes')}
            />
            <RadioButton
              name="addressMatches"
              label="Нет"
              value="no"
              checked={!addressMatches}
              onChange={() => handleAddressMatchesChange('no')}
            />
          </div>

          {!addressMatches && (
            <CustomInput
              name="actualAddress"
              type={InputTypeEnum.Text}
              placeholder="ФАКТИЧЕСКИЙ АДРЕС ПРОЖИВАНИЯ"
              description="Город, улица, дом, корпус и квартира"
              required={!addressMatches}
            />
          )}
        </div>
      </form>
    </FormProvider>
  );
};
