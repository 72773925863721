import React from 'react';
import {
    BeatLoader
} from 'react-spinners';
import styles from './LoadingOverlay.module.scss';

const LoadingOverlay = () => {
    return (
        <div className={styles.overlay}>
            <BeatLoader size={10} color="#71BE81" />
        </div>
    );
};

export default LoadingOverlay;
