import React, { useCallback, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import { useUploadFilesMutation } from 'services/api';
import { useDropzone } from 'react-dropzone';
import styles from './PepConsentPhotoForm.module.scss';

interface PepConsentPhotoFormProps {
    setIsFormValid: (isValid: boolean) => void;
    onFormSubmit: (data: any) => void;
    requestId: string;
}

const PepConsentPhotoForm: React.FC<PepConsentPhotoFormProps> = ({ setIsFormValid, onFormSubmit, requestId }) => {
    console.log("🚀 ~ requestId; in PepConsentPhotoForm:", requestId)
    const methods = useForm();
    const { handleSubmit, formState: { isValid }, register } = methods;

    const [documentFiles, setDocumentFiles] = useState<File[]>([]);
    const [uploadFiles, { data, isLoading, error }] = useUploadFilesMutation();

    const documentPhotosInputRef = useRef<HTMLInputElement | null>(null);

    const onDropDocument = useCallback((acceptedFiles: File[]) => {
        setDocumentFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onDropDocument,
        noClick: true,
        noKeyboard: true,
    });

    const handleUploadDocumentClick = () => {
        if (documentPhotosInputRef.current) {
            documentPhotosInputRef.current.click();
        }
    };

    const handleDeleteDocumentFile = (index: number) => {
        setDocumentFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const onSubmit = async (formData: any) => {
        const filesData = new FormData();
        documentFiles.forEach((file) => filesData.append('documentPhotos', file));

        try {
            const response = await uploadFiles({ formData: filesData, id: requestId }).unwrap();
            onFormSubmit(response);
        } catch (err) {
            console.error('Ошибка загрузки:', err);
        }
    };


    return (
        <FormProvider {...methods}>
            <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
                <CardWrapper title="Фото согласия на ПЭП">
                    <label htmlFor="documentPhotos">Фото/сканы</label>
                    <div {...getRootProps()} className={`${styles.dropzone} ${isDragActive ? styles.dragActive : ''}`}>
                        <input
                            {...getInputProps()}
                            id="documentPhotos"
                            {...register('documentPhotos')}
                            ref={documentPhotosInputRef}
                            onChange={(e) => {
                                const files = e.target.files;
                                if (files) {
                                    setDocumentFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
                                }
                            }}
                        />
                        <button type="button" className={styles.photoButton} onClick={handleUploadDocumentClick}>
                            ЗАГРУЗИТЬ ИЗОБРАЖЕНИЯ
                        </button>
                        <p className={styles.addImageText}>
                            {isDragActive ? 'Отпустите файлы здесь' : 'Перетащите изображения сюда или нажмите для выбора'}
                        </p>
                    </div>

                    {/* Отображение загруженных файлов */}
                    <div className={styles.uploadedFiles}>
                        <h3>Загруженные файлы:</h3>
                        <ul>
                            {documentFiles.map((file, index) => (
                                <li key={index} className={styles.fileItem}>
                                    <span>{file.name}</span>
                                    <button
                                        type="button"
                                        className={styles.deleteButton}
                                        onClick={() => handleDeleteDocumentFile(index)}
                                    >
                                        УДАЛИТЬ
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </CardWrapper>
            </form>
        </FormProvider>
    );
};

export default PepConsentPhotoForm;