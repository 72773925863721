import React from 'react';

const ErrorIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.3203 21.896L15.6523 4.03203C14.8963 2.77203 13.0763 2.66003 12.2923 3.89203L1.71002 21.868C0.870019 23.184 1.82202 25.2018 3.38827 25.2018H24.6123C26.1523 25.2018 27.1043 23.212 26.3203 21.896ZM13.9443 22.176C13.1883 22.176 12.5723 21.5618 12.5723 20.804C12.5723 20.0463 13.1865 19.432 13.9443 19.432C14.7003 19.432 15.3163 20.0463 15.3163 20.804C15.3163 21.5618 14.702 22.176 13.9443 22.176ZM15.4003 17.136C15.4003 17.892 14.7563 18.508 14.0003 18.508C13.2443 18.508 12.6003 17.8938 12.6003 17.136V9.96628C12.6003 9.21028 13.2443 8.59428 14.0003 8.59428C14.7563 8.59428 15.4003 9.20853 15.4003 9.96628V17.136Z" fill="#C15B5B" />
    </svg>

  );
};

export default ErrorIcon;
