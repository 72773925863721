import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { SMSCodeForm } from 'components/SmsCodeForm/SmsCodeForm';
// import { CustomInput } from 'components/CustomInput/CustomInput'; // Предполагаем, что CustomInput уже реализован
// import { InputTypeEnum } from 'shared/models';
// import { CardWrapper } from 'components/CardWrapper/CardWrapper';
// import { PhotoForm } from 'components/PhotoForm/PhotoForm';

// import styles from './Step4.module.scss';

interface Step4Props {
    setIsFormValid: (isValid: boolean) => void;
    handleFormSubmit: (isValid: boolean) => void;
}

const Step5: React.FC<Step4Props> = ({ setIsFormValid, handleFormSubmit }) => {
    const methods = useForm({
        mode: 'onChange', // Валидация при изменении
        defaultValues: {
            maritalStatus: '',
            childrenUnder18: 0,
            educationType: '',
            incomeType: '',
            monthlyIncome: '',
            organizationName: '',
            organizationINN: '',
        },
    });

    const { formState: { isValid } } = methods;

    // Следим за изменениями валидности всей формы
    useEffect(() => {
        setIsFormValid(isValid);
    }, [isValid, setIsFormValid]);

    return (
        <SMSCodeForm onSubmit={function (code: string): void {
            throw new Error('Function not implemented.');
        }} />
    );
};

export default Step5;
