import React from 'react'
import AuthLayout from 'app/layouts/AuthLayout/AuthLayout'
import RecoveryPasswordCheck from 'components/recovery-password/RecoveryPasswordCheck/RecoveryPasswordCheck'

export default function RecoveryPasswordCheckPage() {
    return (
        <AuthLayout>
            <RecoveryPasswordCheck />
        </AuthLayout>
    )
}