import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UserData } from 'shared/models';

interface ConfirmationCodeData {
    confirmationCode: string;
}

const baseUrl = process.env.REACT_APP_API_URL

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl + '/api' }),
    endpoints: (builder) => ({
        fetchOffers: builder.query({
            query: () => '/user/offers',
        }),
        registerUser: builder.mutation({
            query: (userData: UserData) => ({
                url: '/user/registration',
                method: 'POST',
                body: userData,
            }),
        }),
        loginUser: builder.mutation({
            query: (userData: any) => ({
                url: '/user/login',
                method: 'POST',
                body: userData,
            }),
        }),
        approveRegistrationCode: builder.mutation({
            query: (data: ConfirmationCodeData) => ({
                url: '/user/verify-code',
                method: 'POST',
                body: data,
            }),
        }),
        fetchPartner: builder.query({
            query: (pid: string) => `/user/partners?affiseId=${pid}`,
        }),
        repeatConfirmCode: builder.mutation({
            query: (email: string) => ({
                url: '/user/repeat-confirm-code',
                method: 'POST',
                body: { email },
            }),
        }),
        checkAuth: builder.query({
            query: () => '/user/refresh',
        }),
        updateUser: builder.mutation({
            query: (userData: Partial<UserData>) => ({
                url: `/user/update-user`, // Это ваш эндпоинт для обновления пользователя
                method: 'PUT',
                body: userData,
            }),
        }),
        updatePassword: builder.mutation({
            query: (data: { email: string; currentPassword?: string; newPassword: string; recoverPasswordLink?: string, timestamp?: string }) => ({
                url: `/user/updatePassword`,
                method: 'POST',
                body: data,
            }),
        }),
        fetchDailyStatistic: builder.query({
            query: () => '/user/daily-statistic',
        }),
        getOfferStatistics: builder.query({
            query: () => '/user/offer-statistic',
        }),
        uploadFiles: builder.mutation<{ documentPhotosUrl: string; clientPhotoUrl: string }, { formData: FormData; id: string }>({
            query: ({ formData, id }) => ({
                url: `/files/upload/${id}`,  // Динамически добавляем ID в URL
                method: 'POST',
                body: formData,
            }),
        }),
        getApplications: builder.query({
            query: () => '/user/applications', // Убедитесь, что этот эндпоинт существует на сервере
        }),
        // Новый эндпоинт для создания новой заявки
        createCardRequest: builder.mutation({
            query: (data) => ({
                url: `/card-request/create`,
                method: 'POST',
                body: data,
            }),
        }),
        // Новый эндпоинт для создания или обновления данных заявки по шагам
        updateRequestStep: builder.mutation({
            query: ({ id, step, data }) => ({
                url: `/card-request/${id}/step/${step}`,
                method: 'POST',
                body: data,
            }),
        }),
        // Новый эндпоинт для получения заявки по ID
        getCardRequestById: builder.query({
            query: (id) => `/card-request/${id}`,
        }),
        // Новый эндпоинт для обновления статуса заявки
        updateRequestStatus: builder.mutation({
            query: ({ id, status }) => ({
                url: `/card-request/${id}/status`,
                method: 'PATCH',
                body: { status },
            }),
        }),
        getPhotoData: builder.query({
            query: (id: string) => `/card-request/${id}/photos`,
        }),
        // Удаление фото документа
        deleteDocumentPhoto: builder.mutation({
            query: ({ id, photoPath }) => ({
                url: `/card-request/${id}/document`,
                method: 'DELETE',
                body: { photoPath }, // передаем путь к фото в теле запроса
            }),
        }),
        // Удаление фото клиента
        deleteClientPhoto: builder.mutation({
            query: ({ id, photoPath }) => ({
                url: `/card-request/${id}/client`,
                method: 'DELETE',
                body: { photoPath }, // передаем путь к фото в теле запроса
            }),
        }),
    }),
});

export const {
    useFetchOffersQuery,
    useRegisterUserMutation,
    useLoginUserMutation,
    useApproveRegistrationCodeMutation,
    useFetchPartnerQuery,
    useRepeatConfirmCodeMutation,
    useCheckAuthQuery,
    useUpdateUserMutation,
    useUpdatePasswordMutation,
    useFetchDailyStatisticQuery,
    useGetOfferStatisticsQuery,
    useUploadFilesMutation,
    useGetApplicationsQuery,
    useCreateCardRequestMutation, // Новый хук для создания или обновления шага заявки
    useUpdateRequestStepMutation, // Новый хук для создания или обновления шага заявки
    useGetCardRequestByIdQuery, // Новый хук для получения заявки по ID
    useUpdateRequestStatusMutation,
    useGetPhotoDataQuery,
    useDeleteDocumentPhotoMutation,
    useDeleteClientPhotoMutation,
} = apiSlice;