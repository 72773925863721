import React from 'react'
import SuccessIcon from 'assets/icons/SuccessIcon'
import ButtonUI from 'components/ui/ButtonUI/ButtonUI'
import { TextUI } from 'components/ui/TextUI/ui/TextUI'

import styles from './RegistrationDone.module.scss'

const RegistrationDone = () => {
  return (
    <div className={styles.registrationWrapper}>
      <SuccessIcon />
      <TextUI variant="h1" align='center'>Регистрация завершена</TextUI>
      <TextUI variant="bodyL" align='center'>Регистрация прошла успешно! Теперь Вы можете войти в систему, используя свой логин и пароль.</TextUI>

      <ButtonUI label="Войти" variant="main" to='/login' fullWidth />
    </div>
  )
}

export default RegistrationDone