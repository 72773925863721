import React from 'react';
import { PepConsentPhotoForm } from 'components/PepConsentPhotoForm';
// import { useDispatch } from 'react-redux';

import styles from './Step7.module.scss';

// interface Step7Props {
//     setIsFormValid: (isValid: boolean) => void; // Проп для управления состоянием валидности формы
//     onFormSubmit: (data: any) => void; // Проп для обработки отправки данных формы
// }

const Step8: React.FC = ({ }) => {
    // const dispatch = useDispatch();

    const handleFormSubmit = (data: any) => {
        console.log("🚀 ~ handleFormSubmit ~ data:", data);
    };

    const handleFormValidity = (data: any) => {
        console.log("🚀 ~ handleFormValidity ~ data:", data);
    };

    return (
        <div className={styles.step7}>
            <PepConsentPhotoForm requestId={'1'}
                setIsFormValid={handleFormValidity}  // Функция для управления валидностью формы
                onFormSubmit={handleFormSubmit} />
        </div>
    );
};

export default Step8;
