import React from 'react';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';

import styles from './CardWrapper.module.scss';

interface CardWrapperProps {
    title: string;
    children: React.ReactNode;
}

export const CardWrapper: React.FC<CardWrapperProps> = ({ title, children }) => {
    return (
        <div className={styles.card}>
            <TextUI variant="h2">{title}</TextUI>
            {children}
        </div>
    );
};
