import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styles from './SMSCodeForm.module.scss';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';

interface SMSCodeFormProps {
    onSubmit: (code: string) => void; // Передаем функцию для обработки кода
}

export const SMSCodeForm: React.FC<SMSCodeFormProps> = ({ onSubmit }) => {
    const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    const [code, setCode] = useState(['', '', '', '', '', '']); // Хранение кода

    // Переключение фокуса на следующее поле
    const handleInputChange = (value: string, index: number) => {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (value && index < 5) {
            const nextInput = document.getElementById(`code-${index + 1}`);
            if (nextInput) nextInput.focus();
        }

        if (value.length === 0 && index > 0) {
            const prevInput = document.getElementById(`code-${index - 1}`);
            if (prevInput) prevInput.focus();
        }
    };

    // Обработка отправки кода
    const handleFormSubmit = () => {
        const enteredCode = code.join('');
        if (enteredCode.length === 6) {
            onSubmit(enteredCode); // Отправка кода родительскому компоненту
        }
    };

    return (
        <form className={styles.smsCodeForm} onSubmit={handleSubmit(handleFormSubmit)}>
            {/* <p>Клиенту отправили анкету и согласие на ОПД с кодом СМС.</p> */}
            <TextUI variant="bodyL" align="left" color='secondary' >Клиенту отправили анкету и согласие на ОПД с кодом СМС.</TextUI>
            <div className={styles.codeInputContainer}>
                <label>Введите код из СМС</label>
                <div className={styles.codeInputWrapper}>
                    {code.map((_, index) => (
                        <Controller
                            key={index}
                            name={`code-${index}`}
                            control={control}
                            rules={{
                                required: 'Обязательное поле',
                                maxLength: 1,
                                minLength: 1,
                                pattern: {
                                    value: /^[0-9]$/,
                                    message: 'Только цифры',
                                },
                            }}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    id={`code-${index}`}
                                    type="text"
                                    maxLength={1}
                                    value={code[index]}
                                    onChange={(e) => handleInputChange(e.target.value, index)}
                                    className={styles.codeInput}
                                />
                            )}
                        />
                    ))}
                </div>

                {/* {errors[`code-${0}`] && <span className={styles.errorMessage}>{errors[`code-${0}`]?.message}</span>} */}
                <button type="submit" className={styles.submitButton} disabled={code.join('').length < 6}>
                    ПОДТВЕРДИТЬ
                </button>
            </div>
        </form>
    );
};
