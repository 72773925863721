import { useEffect, RefObject } from 'react';

// Define the hook function with TypeScript type annotations
function useOnClickOutside(
    ref: RefObject<HTMLElement>,
    handler: (event: MouseEvent | TouchEvent) => void
): void {
    useEffect(() => {
        // Event listener that calls handler if clicked outside of ref
        const listener = (event: MouseEvent | TouchEvent): void => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target as Node)) {
                return;
            }

            handler(event);
        };

        // Set up the event listeners
        document.addEventListener('mousedown', listener as EventListener);
        document.addEventListener('touchstart', listener as EventListener);

        // Clean up the event listeners on component unmount
        return () => {
            document.removeEventListener('mousedown', listener as EventListener);
            document.removeEventListener('touchstart', listener as EventListener);
        };
    }, [ref, handler]); // Only re-run if ref or handler changes
}

export default useOnClickOutside;
