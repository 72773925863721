import React, { useEffect } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { CustomInput } from 'components/CustomInput/CustomInput';
import { InputTypeEnum } from 'shared/models';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { saveAdditionalData } from 'features/request/requestSlice';
import styles from './Step3.module.scss';
import { Counter } from 'components/Counter';
import { useGetCardRequestByIdQuery } from 'services/api';

interface Step3Props {
  requestId: string | null;
  setIsFormValid: (isValid: boolean) => void;
  onFormSubmit: (data: any) => void;
}

const Step3: React.FC<Step3Props> = ({ requestId, setIsFormValid, onFormSubmit }) => {
  const dispatch = useDispatch();

  const { data: requestData } = useGetCardRequestByIdQuery(requestId, { skip: !requestId });

  // Получаем сохраненные данные из Redux
  const additionalData = useSelector(
    (state: any) => state.request.data.additionalData || {}
  );

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange', // Перевалидация по изменению
    defaultValues: {
      maritalStatus: additionalData.maritalStatus || '',
      childrenUnder18: additionalData.childrenUnder18 || 0,
      educationType: additionalData.educationType || '',
      incomeType: additionalData.incomeType || '',
      monthlyIncome: additionalData.monthlyIncome || '',
      organizationName: additionalData.organizationName || '',
      organizationINN: additionalData.organizationINN || '',
    },
  });

  const {
    formState: { isValid },
    handleSubmit,
    watch,
    control,
    reset,
  } = methods;
  // console.log("🚀 ~ isValid:", isValid)

  // Заполняем форму данными с сервера, если они есть
  useEffect(() => {
    if (requestData && requestData.additionalData) {
      reset(requestData.additionalData);
      dispatch(saveAdditionalData(requestData.additionalData));
    }
  }, [requestData, reset, dispatch]);

  // Следим за изменениями валидности формы
  useEffect(() => {
    setIsFormValid(isValid); // Передаем состояние валидности
  }, [isValid, setIsFormValid]);

  // Сохраняем данные формы в Redux при изменении полей
  useEffect(() => {
    const subscription = watch((data) => {
      dispatch(saveAdditionalData(data));
    });

    return () => subscription.unsubscribe();
  }, [watch, dispatch]);

  const onSubmit = (data: any) => {
    dispatch(saveAdditionalData(data));
    onFormSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.step3}>
        <CardWrapper title='Семья'>
          <CustomInput
            name='maritalStatus'
            type={InputTypeEnum.Text}
            placeholder='СЕМЕЙНОЕ ПОЛОЖЕНИЕ'
            required
          />
          <Controller
            name="childrenUnder18"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Counter
                initialValue={value}
                min={0}
                max={10}
                onChange={(newValue) => onChange(newValue)}
              />
            )}
          />
        </CardWrapper>
        <div className={styles.columnWrapper}>
          <CardWrapper title='Образование'>
            <CustomInput
              name='educationType'
              type={InputTypeEnum.Text}
              placeholder='ТИП ОБРАЗОВАНИЯ'
              required
            />
          </CardWrapper>
          <CardWrapper title='Доход'>
            <CustomInput
              name='incomeType'
              type={InputTypeEnum.Text}
              placeholder='ТИП ДОХОДА'
              required
            />
            <CustomInput
              name='monthlyIncome'
              type={InputTypeEnum.Text}
              placeholder='ЕЖЕМЕСЯЧНЫЙ ДОХОД'
              description='Укажите среднюю з/п за последние 3 месяца с учетом вычета налога'
              required
            />
            <CustomInput
              name='organizationName'
              type={InputTypeEnum.Text}
              placeholder='НАЗВАНИЕ ОРГАНИЗАЦИИ'
              required
            />
            <CustomInput
              name='organizationINN'
              type={InputTypeEnum.Text}
              placeholder='ИНН'
              required
            />
          </CardWrapper>
        </div>
      </form>
    </FormProvider>
  );
};

export default Step3;
