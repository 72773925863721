import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { setCreditAmount } from 'features/request/requestSlice';
// import { CreditSlider } from 'components/CreditSlider';
import styles from './Step6.module.scss';

const Step6: React.FC = () => {
    // const dispatch = useDispatch();
    const creditAmount = useSelector((state: any) => state.request.data.creditAmount);

    const handleApproval = () => {
        // Логика обработки одобрения карты
    };

    return (
        <div className={styles.card}>
            <h3>Кредитный лимит: {creditAmount.toLocaleString('ru-RU')} ₽</h3>
            <div>Решение по выдаче карты:</div>
            <div className={styles.approveWrapper}>
                <span style={{ color: 'green' }}>✔</span>
                <span className={styles.approveText}>Одобрено</span>
            </div>
        </div>
    );
};

export default Step6;

