import React, { useState, useRef, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styles from './CustomCalendar.module.scss';  // Ваши кастомные стили
import CalendarIcon from 'assets/icons/CalendarIcon';
import useOnClickOutside from 'shared/hooks/useOnClickOutside';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const getDefaultRange = (): Value => {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 150);  // Отнимаем 7 дней от текущей даты
    return [sevenDaysAgo, today];
};

interface CustomCalendarSelectProps {
    onDateChange: (range: [Date, Date]) => void;  // Пропс для передачи выбранного диапазона
    dateRange: [Date, Date]; // Пропс для получения диапазона из родительского компонента
    title?: string;
    resetDates?: boolean;  // Пропс для сброса дат
}

const CustomCalendarSelect: React.FC<CustomCalendarSelectProps> = ({ onDateChange, dateRange, title = 'Дата заявки', resetDates = false }) => {
    const [showCalendar, setShowCalendar] = useState(false); // Локальное состояние для отображения календаря
    const calendarRef = useRef<HTMLDivElement>(null);  // Ссылка на контейнер с календарем

    // Используем кастомный хук для закрытия календаря по клику вне его
    useOnClickOutside(calendarRef, () => setShowCalendar(false));

    // Сброс дат при необходимости
    useEffect(() => {
        if (resetDates) {
            onDateChange(getDefaultRange() as [Date, Date]);  // Вызываем onDateChange для сброса диапазона в родительский компонент
        }
    }, [resetDates, onDateChange]);

    const handleChange = (value: Value) => {
        if (Array.isArray(value)) {
            onDateChange(value as [Date, Date]);  // Передаем новое значение родителю
        } else if (value) {
            onDateChange([value, value]); // Обрабатываем случай одиночного значения
        }
    };

    const handleConfirm = () => {
        setShowCalendar(false);  // Скрываем календарь по нажатию "OK"
    };

    const toggleCalendar = () => setShowCalendar(!showCalendar);

    const formatDate = (date: Date | null) => {
        if (!date || isNaN(new Date(date).getTime())) {
            return ''; // Если дата некорректная или отсутствует, возвращаем пустую строку
        }

        const validDate = new Date(date); // Преобразуем в объект Date

        return validDate.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    return (
        <div className={styles.calendarSelectContainer} ref={calendarRef}>
            <div className={styles.titleWrapper}>
                {title && <div className={styles.title}>{title}</div>}

                <div className={styles.datePicker} onClick={toggleCalendar}>
                    <span>
                       <span className={styles.highlightedStartDate}>{formatDate(dateRange[0])}</span>  - {formatDate(dateRange[1])}
                    </span>
                    <span className={styles.icon}><CalendarIcon /></span>
                </div>
            </div>

            {/* Календарь, который появляется по клику */}
            {showCalendar && (
                <div className={styles.calendarDropdown}>
                    <Calendar
                        onChange={handleChange}
                        selectRange={true}
                        value={dateRange}
                        locale="ru-RU"
                    />
                    {/* Кнопки внизу */}
                    <div className={styles.calendarActions}>
                        <button className={styles.resetButton} onClick={() => onDateChange(getDefaultRange() as [Date, Date])}>СБРОСИТЬ</button>
                        <button className={styles.confirmButton} onClick={handleConfirm}>OK</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomCalendarSelect;
