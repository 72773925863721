import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import { useDeleteClientPhotoMutation, useDeleteDocumentPhotoMutation, useUploadFilesMutation } from 'services/api';
import { useDropzone } from 'react-dropzone';
import ImgIcon from 'assets/icons/ImgIcon';
import { Icon } from '@iconify/react';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';

import styles from './PhotoForm.module.scss';

interface PhotoFormProps {
    setIsFormValid: (isValid: boolean) => void;
    onFormSubmit: (data: any) => void;
    serverData?: { documentFiles?: string[]; clientFiles?: string[] }; // данные с сервера
    requestId?: any;
}

const formatFileSize = (size: any) => {
    if (size < 1024) return `${size} B`;
    if (size < 1048576) return `${(size / 1024).toFixed(1)} KB`;
    return `${(size / 1048576).toFixed(1)} MB`;
};

export const PhotoForm: React.FC<PhotoFormProps> = ({ setIsFormValid, onFormSubmit, serverData, requestId }) => {
    // console.log("🚀 ~ onFormSubmit:", onFormSubmit)
    const methods = useForm();
    const { handleSubmit, formState: { isValid } } = methods;

    const [documentFileUrls, setDocumentFileUrls] = useState<string[]>([]);
    const [clientFileUrls, setClientFileUrls] = useState<string[]>([]);
    const [documentFiles, setDocumentFiles] = useState<File[]>([]);
    const [clientFiles, setClientFiles] = useState<File[]>([]);

    console.log("🚀 ~ documentFileUrls:", documentFileUrls)
    console.log("🚀 ~ clientFileUrls:", clientFileUrls)
    console.log("🚀 ~ documentFiles:", documentFiles)
    console.log("🚀 ~ clientFiles:", clientFiles)

    const [isDocumentUploadSuccess, setDocumentUploadSuccess] = useState(false);
    const [isClientUploadSuccess, setClientUploadSuccess] = useState(false);

    const [uploadFiles, { isLoading }] = useUploadFilesMutation();

    const documentPhotosInputRef = useRef<HTMLInputElement | null>(null);
    const clientPhotoInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (serverData) {
            setDocumentFileUrls(serverData.documentFiles || []);
            setClientFileUrls(serverData.clientFiles || []);
        }
    }, [serverData]);

    // useEffect(() => {
    //     if (isClientUploadSuccess) {
    //         setClientFileUrls((prevClientFiles) => {
    //             const updatedFiles = [...prevClientFiles, ...clientFiles.map((file) => file.name)];
    //             return updatedFiles;
    //         });
    //         console.log("clientFileUrls обновлены после загрузки:", clientFileUrls);
    //     }
    // }, [isClientUploadSuccess]);

    useEffect(() => {
        if (isClientUploadSuccess) {
            setClientFileUrls((prevClientFiles) => {
                const updatedFiles = [...prevClientFiles, ...clientFiles.map((file) => file.name)];
                return updatedFiles;
            });
            console.log("clientFileUrls обновлены после загрузки:", clientFileUrls);
        }

        if (isDocumentUploadSuccess) {
            setDocumentFileUrls((prevDocumentFiles) => {
                const updatedFiles = [...prevDocumentFiles, ...documentFiles.map((file) => file.name)];
                return updatedFiles;
            });
            console.log("documentFileUrls обновлены после загрузки:", documentFileUrls);
        }
    }, [isClientUploadSuccess, isDocumentUploadSuccess]);

    const [deleteDocumentPhoto] = useDeleteDocumentPhotoMutation();
    const [deleteClientPhoto] = useDeleteClientPhotoMutation();

    const onDropDocument = useCallback((acceptedFiles: File[]) => {
        setDocumentFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    }, []);

    const onDropClient = useCallback((acceptedFiles: File[]) => {
        setClientFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    }, []);

    const { getRootProps: getDocumentRootProps, getInputProps: getDocumentInputProps, isDragActive: isDocumentDragActive } = useDropzone({
        onDrop: onDropDocument,
        noClick: true,
        noKeyboard: true,
    });

    const { getRootProps: getClientRootProps, getInputProps: getClientInputProps, isDragActive: isClientDragActive } = useDropzone({
        onDrop: onDropClient,
        noClick: true,
        noKeyboard: true,
        multiple: true,
    });

    const handleUploadDocumentClick = () => {
        if (documentPhotosInputRef.current) {
            documentPhotosInputRef.current.click();
        }
    };

    const handleUploadClientPhotoClick = () => {
        if (clientPhotoInputRef.current) {
            clientPhotoInputRef.current.click();
        }
    };

    const normalizePath = (path: any) => path.replace(/\\/g, '/');

    const handleDeleteDocumentFile = async (index: number) => {
        if (index < documentFileUrls.length) {
            const fileUrl = documentFileUrls[index];

            try {
                // Нормализуем путь перед передачей на сервер
                await deleteDocumentPhoto({ id: requestId, photoPath: normalizePath(fileUrl) }).unwrap();
                setDocumentFileUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
            } catch (error) {
                console.error('Ошибка при удалении фото документа с сервера:', error);
            }
        } else {
            setDocumentFiles((prevFiles) => prevFiles.filter((_, i) => i !== index - documentFileUrls.length));
        }
    };

    const handleDeleteClientFile = async (index: number) => {
        if (index < clientFileUrls.length) {
            const fileUrl = clientFileUrls[index];

            try {
                // Нормализуем путь перед передачей на сервер
                await deleteClientPhoto({ id: requestId, photoPath: normalizePath(fileUrl) }).unwrap();
                setClientFileUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
            } catch (error) {
                console.error('Ошибка при удалении фото клиента с сервера:', error);
            }
        } else {
            setClientFiles((prevFiles) => prevFiles.filter((_, i) => i !== index - clientFileUrls.length));
        }
    };

    const onSubmit = async (formData: any) => {
        const documentData = new FormData();
        const clientData = new FormData();

        // Инициализируем переменные для ответов
        let documentResponse: any = null;
        let clientResponse: any = null;

        // Проверяем, чтобы общее количество файлов документов было ровно два
        if (documentFiles.length + documentFileUrls.length !== 2) {
            alert('Пожалуйста, загрузите ровно два файла документов.');
            return;
        }

        // Добавляем только новые файлы документов, если они не имеют совпадений с файлами на сервере
        documentFiles.forEach((file) => {
            const isFileAlreadyUploaded = documentFileUrls.some((url) => url.split(/[\\/]/).pop() === file.name);
            if (!isFileAlreadyUploaded) {
                documentData.append('files', file);
            }
        });
        documentData.append('fileType', 'document');

        // Добавляем только новые клиентские файлы, если они не имеют совпадений с файлами на сервере
        clientFiles.forEach((file) => {
            const isFileAlreadyUploaded = clientFileUrls.some((url) => url.split(/[\\/]/).pop() === file.name);
            if (!isFileAlreadyUploaded) {
                clientData.append('files', file);
            }
        });
        clientData.append('fileType', 'client');

        try {
            // Загрузка файлов документа, если есть новые файлы
            if (documentData.getAll('files').length > 0) {
                documentResponse = await uploadFiles({ formData: documentData, id: requestId }).unwrap();
                setDocumentUploadSuccess(true);
                // console.log('Документы загружены:', documentResponse);

                // Преобразуем documentPhotosUrl в массив, если это строка, и отфильтруем undefined значения
                const newDocumentUrls = Array.isArray(documentResponse.documentPhotosUrl)
                    ? documentResponse.documentPhotosUrl.filter(Boolean)
                    : [documentResponse.documentPhotosUrl].filter(Boolean);

                // Обновляем documentFileUrls, добавляя только новые уникальные URL
                setDocumentFileUrls((prevUrls) => [
                    ...prevUrls,
                    ...newDocumentUrls.filter((url: any) => !prevUrls.includes(url)),
                ]);
            }

            // Загрузка клиентских файлов, если есть новые файлы
            if (clientData.getAll('files').length > 0) {
                clientResponse = await uploadFiles({ formData: clientData, id: requestId }).unwrap();
                setClientUploadSuccess(true);
                // console.log('Фото клиента загружено:', clientResponse);

                // Преобразуем clientPhotoUrl в массив, если это строка, и отфильтруем undefined значения
                const newClientUrls = Array.isArray(clientResponse.clientPhotoUrl)
                    ? clientResponse.clientPhotoUrl.filter(Boolean)
                    : [clientResponse.clientPhotoUrl].filter(Boolean);

                // Обновляем clientFileUrls, добавляя только новые уникальные URL
                setClientFileUrls((prevUrls) => [
                    ...prevUrls,
                    ...newClientUrls.filter((url: any) => !prevUrls.includes(url)),
                ]);
            }

            // Передаем ответы в onFormSubmit
            // console.log("🚀 ~ onSubmit ~ { documentResponse, clientResponse }:", { documentResponse, clientResponse })
            // onFormSubmit({ documentResponse, clientResponse });
        } catch (err) {
            console.error('Ошибка загрузки:', err);
        }
    };

    return (
        <FormProvider {...methods}>
            <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.flexRow}>
                    <CardWrapper title="Фото документов">
                        {(isDocumentUploadSuccess || documentFileUrls.length > 1) ? (
                            <div className={styles.successBlock}>
                                <Icon icon="mdi:check-circle" color="#4CAF50" width="24px" />
                                <span>Фото успешно загружены</span>
                            </div>
                        ) : (
                            <>
                                <div className={styles.photoSubtitleWrapper}>
                                    <div>Загрузите фото или сканы паспорта:</div>
                                    <ul className={styles.ulList} >
                                        <li>Разворот 2-3 страницы</li>
                                        <li>Страница актуальной прописки </li>
                                    </ul>
                                </div>
                                <div {...getDocumentRootProps()} className={`${styles.dropzone} ${isDocumentDragActive ? styles.dragActive : ''}`}>
                                    <input
                                        {...getDocumentInputProps()}
                                        id="documentPhotos"
                                        ref={documentPhotosInputRef}
                                        onChange={(e) => {
                                            const files = e.target.files;
                                            if (files) {
                                                setDocumentFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
                                            }
                                        }}
                                    />
                                    <button type="button" className={styles.photoButton} onClick={handleUploadDocumentClick}>
                                        ЗАГРУЗИТЬ ИЗОБРАЖЕНИЯ
                                    </button>
                                    <p className={styles.addImageText}>
                                        {isDocumentDragActive ? 'Отпустите файлы здесь' : 'Или перетащите изображения сюда'}
                                    </p>
                                </div>
                                <div className={styles.uploadedFiles}>
                                    <ul>
                                        {documentFileUrls.map((url, index) => (
                                            <li key={index} className={styles.fileItem}>
                                                <ImgIcon />
                                                <span>{url ? url.replace(/^uploads[\\/]\d{13}-/, '') : 'Неизвестное имя файла'}</span>
                                                <button
                                                    type="button"
                                                    className={styles.deleteButton}
                                                    onClick={() => handleDeleteDocumentFile(index)}
                                                >
                                                    УДАЛИТЬ
                                                </button>
                                                {/* <Icon
                                            className='custom-select__icon'
                                            icon='streamline:delete-1-solid'
                                            width='24px'
                                            color='#6C6EA0'
                                        /> */}
                                            </li>
                                        ))}
                                        {documentFiles.map((file, index) => (
                                            <li key={documentFileUrls.length + index} className={styles.fileItem}>
                                                <div className={styles.rowFileWrapper}>
                                                    {/* <ImgIcon /> */}
                                                    <Icon
                                                        icon={file.type === 'image/png' ? 'ph:file-png-duotone' : 'ph:file-jpg-duotone'}
                                                        width='32px'
                                                        color='#2F2F33'
                                                    />
                                                    <div className={styles.fileRow}>
                                                        <span>{file.name}</span>
                                                        <span>{formatFileSize(file.size)}</span>
                                                    </div>
                                                </div>
                                                <Icon
                                                    className={styles.deleteButton}
                                                    onClick={() => handleDeleteDocumentFile(documentFileUrls.length + index)}
                                                    icon='streamline:delete-1-solid'
                                                    width='12px'
                                                    color='#AAAABB'
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                    </CardWrapper>

                    <CardWrapper title="Фото клиента">
                        {(isClientUploadSuccess || clientFileUrls.length > 0) ? (
                            <div className={styles.successBlock}>
                                <Icon icon="mdi:check-circle" color="#4CAF50" width="24px" />
                                <span>Фото успешно загружены</span>
                            </div>
                        ) : (
                            <>
                                <div className={styles.photoSubtitleWrapper}>
                                    <div>Загрузите фото клиента:</div>
                                    <ul className={styles.ulList} >
                                        <li>Лицо клиента должно быть открыто, без посторонних предметов</li>
                                        <li>Выражение лица — нейтральное</li>
                                    </ul>
                                </div>
                                <div {...getClientRootProps()} className={`${styles.dropzone} ${isClientDragActive ? styles.dragActive : ''}`}>
                                    <input
                                        {...getClientInputProps()}
                                        id="clientPhoto"
                                        ref={clientPhotoInputRef}
                                        onChange={(e) => {
                                            const files = e.target.files;
                                            if (files) {
                                                setClientFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
                                            }
                                        }}
                                    />
                                    <button type="button" className={styles.photoButton} onClick={handleUploadClientPhotoClick}>
                                        ЗАГРУЗИТЬ ИЗОБРАЖЕНИЯ
                                    </button>
                                    <p className={styles.addImageText}>
                                        {isClientDragActive ? 'Отпустите файлы здесь' : 'Или перетащите изображения сюда'}
                                    </p>
                                </div>
                                <div className={styles.uploadedFiles}>
                                    <ul>
                                        {clientFileUrls.map((url, index) => (
                                            <li key={index} className={styles.fileItem}>
                                                <ImgIcon />
                                                <span>{url ? url.replace(/^uploads[\\/]\d{13}-/, '') : 'Неизвестное имя файла'}</span>
                                                <button
                                                    type="button"
                                                    className={styles.deleteButton}
                                                    onClick={() => handleDeleteClientFile(index)}
                                                >
                                                    УДАЛИТЬ
                                                </button>
                                            </li>
                                        ))}
                                        {clientFiles.map((file, index) => (
                                            <li key={clientFileUrls.length + index} className={styles.fileItem} >
                                                <div className={styles.rowFileWrapper}>
                                                    <Icon
                                                        icon={file.type === 'image/png' ? 'ph:file-png-duotone' : 'ph:file-jpg-duotone'}
                                                        width='32px'
                                                        color='#2F2F33'
                                                    />
                                                    <div className={styles.fileRow}>
                                                        <span>{file.name}</span>
                                                        <span>{formatFileSize(file.size)}</span>
                                                    </div>
                                                </div>
                                                <Icon
                                                    className={styles.deleteButton}
                                                    onClick={() => handleDeleteClientFile(clientFileUrls.length + index)}
                                                    icon='streamline:delete-1-solid'
                                                    width='12px'
                                                    color='#AAAABB'
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                    </CardWrapper>
                </div>

                {/* <div>{(!((documentFileUrls.length > 1) && (clientFileUrls.length > 0))).toString()}</div> */}
                {(!((documentFileUrls.length > 1) && (clientFileUrls.length > 0))) && (
                    <div className={styles.submitButtonContainer}>
                        <ButtonUI
                            type="submit"
                            fullWidth
                            label={isLoading ? 'Загрузка...' : 'Отправить'}
                            variant="secondary"
                            disabled={isLoading}
                        />
                    </div>
                )}
            </form>
        </FormProvider>
    );
};
