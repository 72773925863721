import React from 'react'
import MainContainer from 'components/ui/MainContainer/MainContainer'
import MainHeader from 'components/ui/MainHeader/MainHeader'
// import RouterComponent from 'components/ui/Router/Router'
import FooterComponent from 'components/ui/Footer/Footer'
// import styles from './RequestCardLayout.module.scss';

const RequestCardLayout = ({ children }: any) => {
  return (
    <>
      <MainHeader />
      {/* <RouterComponent /> */}
      <MainContainer>
        {children}
      </MainContainer>
      {/* <FooterComponent /> */}
    </>
  )
}

export default RequestCardLayout