import React from 'react'
import AuthLayout from 'app/layouts/AuthLayout/AuthLayout'
import ApproveRegistrationCode from 'components/ApproveRegistrationCode/ApproveRegistrationCode'

export default function ApproveRegistrationCodePage() {
    return (
        <AuthLayout>
            <ApproveRegistrationCode />
        </AuthLayout>
    )
}