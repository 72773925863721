import React from 'react';

const TextIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
    >
      <path
        d='M28.379 6.94774L25.3904 3.96321C25.0818 3.65463 24.6761 3.5 24.271 3.5C23.866 3.5 23.4609 3.65395 23.1524 3.96253L20.7617 6.34632L25.9884 11.5641L28.379 9.18101C28.9969 8.56385 28.9969 7.56285 28.379 6.94774Z'
        fill='#E2E2ED'
      />
      <path
        d='M5.24219 21.8362L10.4696 27.0546L24.8688 12.6821L19.6414 7.46362L5.24219 21.8362Z'
        fill='#E2E2ED'
      />
      <path
        d='M4.11595 22.9529L3 29.2832L9.34332 28.1707L4.11595 22.9529Z'
        fill='#E2E2ED'
      />
      <rect
        x='11.2109'
        y='28.1316'
        width='16.4211'
        height='1.36842'
        rx='0.684211'
        fill='#E2E2ED'
      />
    </svg>
  );
};

export default TextIcon;
