import React from 'react';

const GreenEmailIcon = () => {
  return (
    <svg width="80" height="56" viewBox="0 0 80 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.1479 8.14792C8 11.2958 8 16.3623 8 26.4953V29.5047C8 39.6377 8 44.7042 11.1479 47.8521C14.2958 51 19.3623 51 29.4953 51H50.9907C61.1236 51 66.1901 51 69.3381 47.8521C72.486 44.7042 72.486 39.6377 72.486 29.5047V26.4953C72.486 16.3623 72.486 11.2958 69.3381 8.14792C66.1901 5 61.1236 5 50.9907 5H29.4953C19.3623 5 14.2958 5 11.1479 8.14792ZM16.509 13.006C15.4696 12.3429 14.0896 12.648 13.4266 13.6873C12.7635 14.7267 13.0686 16.1067 14.1079 16.7697L31.1031 27.6115C32.9711 28.8032 34.5191 29.7908 35.8739 30.4659C37.2999 31.1765 38.6851 31.6335 40.2447 31.6304C41.8043 31.6273 43.1876 31.1649 44.6109 30.4487C45.963 29.7683 47.507 28.7747 49.3704 27.5756L66.1706 16.765C67.2073 16.0979 67.5069 14.7167 66.8398 13.6799C66.1727 12.6432 64.7915 12.3436 63.7548 13.0107L47.0531 23.7579C45.0663 25.0363 43.7202 25.8991 42.604 26.4608C41.5353 26.9986 40.8555 27.1648 40.2359 27.166C39.6164 27.1672 38.9358 27.0037 37.865 26.4701C36.7467 25.9128 35.3972 25.0553 33.4054 23.7847L16.509 13.006Z" fill="#71BE81" />
    </svg>
  );
};

export default GreenEmailIcon;
