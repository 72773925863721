import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CustomInput } from 'components/CustomInput/CustomInput';
import { InputTypeEnum } from 'shared/models';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { updateUser } from 'features/login/loginSlice';
import { useUpdateUserMutation } from 'services/api';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import PencilSettings from '../../../../assets/icons/pencil-settings.svg';

import styles from './ProfileForm.module.scss';

interface ProfileData {
    name: string;
    messenger: string;
    phone: string;
    email: string;
}

const ProfileForm = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.login?.user);
    const [updateUserApi, { isLoading, error }] = useUpdateUserMutation();

    const [isUpdated, setIsUpdated] = useState(false);
    // console.log("🚀 ~ ProfileForm ~ isUpdated:", isUpdated)

    const methods = useForm<ProfileData>({
        defaultValues: {
            name: user?.name || '',
            messenger: user?.messenger || '',
            phone: user?.phone || '',
            email: user?.email || '',
        },
    });

    const { handleSubmit, watch } = methods;

    // Слежение за изменением значений полей ввода
    const name = watch('name');
    const messenger = watch('messenger');
    const phone = watch('phone');
    const email = watch('email');

    useEffect(() => {
        setIsUpdated(false); // Сбрасываем флаг при изменении значений в инпутах
    }, [name, messenger, phone, email]);

    const onSubmit = async (data: ProfileData) => {
        try {
            const result = await updateUserApi(data).unwrap();
            dispatch(updateUser(result));
            setIsUpdated(true); // Устанавливаем флаг при успешном обновлении
        } catch (err) {
            console.error('Failed to update profile:', err);
            setIsUpdated(false); // Сбрасываем флаг при ошибке
        }
    };

    return (
        <FormProvider {...methods}>
            <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
                <TextUI variant="h2">Профиль</TextUI>

                <CustomInput
                    name="name"
                    type={InputTypeEnum.FIO}
                    placeholder="Введите ФИО"
                />
                <CustomInput
                    name="messenger"
                    type={InputTypeEnum.Messenger}
                    placeholder="Введите Telegram/Skype/WhatsApp"
                />
                <CustomInput
                    name="phone"
                    type={InputTypeEnum.Tel}
                    placeholder="Введите номер телефона"
                />
                <CustomInput
                    name="email"
                    type={InputTypeEnum.Email}
                    placeholder="Введите email"
                />
                <div className={styles.buttonWidth}>
                    <ButtonUI type="submit" label="Сохранить" variant="main" disabled={isLoading} className={styles.profileSaveBtn} />
                    {isUpdated && !error && (
                        <div className={styles.settingsSaveBox}>
                            <img src={PencilSettings} className="PencilSettings" alt="Icon" />
                            <p>Ваши изменения были сохранены</p>
                        </div>
                    )}
                </div>
                {error && <p className={styles.error}>Ошибка!</p>}
            </form>
        </FormProvider>
    );
};

export default ProfileForm;
