import React from 'react';

const TelegramIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52.8575 10.9529L45.3121 46.537C44.7424 49.048 43.2583 49.6731 41.1491 48.4908L29.6515 40.0185L24.1045 45.3548C23.4901 45.9691 22.9775 46.4817 21.7935 46.4817L22.6204 34.7733L43.928 15.5194C44.8549 14.6944 43.7262 14.2354 42.4885 15.0622L16.1465 31.6497L4.80597 28.0993C2.33964 27.3296 2.29499 25.633 5.32031 24.449L49.6768 7.35964C51.7306 6.58992 53.5272 7.81505 52.8575 10.9529Z" fill="#71BE81" />
    </svg>
  );
};

export default TelegramIcon;
