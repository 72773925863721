import React from 'react';

const SearchIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.207 12.293L11.6685 9.7545C12.193 8.964 12.5 8.0175 12.5 7C12.5 4.243 10.257 2 7.5 2C4.743 2 2.5 4.243 2.5 7C2.5 9.757 4.743 12 7.5 12C8.5175 12 9.464 11.693 10.2545 11.1685L12.793 13.707C13.183 14.0975 13.817 14.0975 14.207 13.707C14.5975 13.3165 14.5975 12.6835 14.207 12.293ZM4 7C4 5.07 5.57 3.5 7.5 3.5C9.43 3.5 11 5.07 11 7C11 8.93 9.43 10.5 7.5 10.5C5.57 10.5 4 8.93 4 7Z" fill="#2F2F33" />
    </svg>

  );
};

export default SearchIcon;
