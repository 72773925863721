import React from 'react';

const MessengerIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.9585 2.5H15.9659C23.6641 2.5 29.9244 8.78073 29.9244 16.5C29.9244 24.2192 23.6642 30.5 15.9659 30.5C13.1261 30.5 10.4925 29.653 8.285 28.1864L2.91812 29.9064L4.65793 24.7059C2.98419 22.4013 2 19.5625 2 16.5C2 8.77886 8.26019 2.5 15.9585 2.5ZM21.3473 24.2457C22.4135 24.0143 23.7503 23.2236 24.0873 22.2695C24.4241 21.3142 24.4241 20.5003 24.3264 20.3274C24.2476 20.19 24.0611 20.1 23.783 19.9657C23.7102 19.9306 23.6312 19.8924 23.5461 19.8496C23.1359 19.6449 21.1411 18.656 20.7642 18.5247C20.3941 18.3848 20.0416 18.4339 19.7625 18.8295C19.711 18.9017 19.6597 18.974 19.6087 19.046C19.2701 19.5234 18.943 19.9847 18.6714 20.2783C18.4256 20.5407 18.0242 20.5742 17.6874 20.4341C17.6507 20.4187 17.6086 20.4017 17.5614 20.3826C17.028 20.1665 15.8453 19.6874 14.413 18.4092C13.2054 17.3311 12.3853 15.9892 12.148 15.5867C11.9149 15.1829 12.1159 14.946 12.3015 14.7273C12.305 14.7232 12.3085 14.7191 12.3119 14.715C12.4312 14.567 12.5471 14.4437 12.6636 14.3197C12.748 14.2298 12.8328 14.1396 12.9194 14.0395C12.9318 14.0251 12.944 14.0111 12.9558 13.9975C13.1393 13.786 13.2483 13.6605 13.3714 13.3973C13.5109 13.1261 13.4117 12.8463 13.3122 12.6398C13.2433 12.4945 12.8176 11.4586 12.4538 10.5733C12.3 10.1992 12.1574 9.85206 12.0573 9.61032C11.7866 8.96102 11.5808 8.93674 11.1707 8.91907C11.1613 8.91859 11.1517 8.9181 11.1421 8.91761C11.0097 8.91087 10.8639 8.90344 10.7028 8.90344C10.1687 8.90344 9.61186 9.06062 9.27534 9.40569C9.26385 9.41743 9.25189 9.42962 9.23949 9.44225C8.80886 9.88089 7.84766 10.86 7.84766 12.8127C7.84766 14.7695 9.23292 16.6626 9.48775 17.0108C9.49459 17.0202 9.50061 17.0284 9.50577 17.0355C9.52109 17.0557 9.54993 17.0976 9.59188 17.1585C10.1132 17.9158 12.6594 21.6146 16.4572 23.192C19.6647 24.5257 20.6174 24.4011 21.3473 24.2457Z'
        fill='#E2E2ED'
      />
    </svg>
  );
};

export default MessengerIcon;
