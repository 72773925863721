import React from 'react'
import AuthLayout from 'app/layouts/AuthLayout/AuthLayout'
import RecoveryPasswordForm from 'components/recovery-password/RecoveryPasswordForm/RecoveryPasswordForm'

export default function RecoveryPasswordFormPage() {
    return (
        <AuthLayout>
            <RecoveryPasswordForm />
        </AuthLayout>
    )
}