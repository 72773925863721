import React from 'react';
import SuccessIcon from 'assets/icons/SuccessIcon';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';

import styles from './RegistrationWithContactAgent.module.scss'

const RegistrationWithContactAgent = () => {
    return (
        <div className={styles.registrationWrapper}>
            <SuccessIcon />
            <TextUI variant="h1" align='center'>Регистрация завершена</TextUI>
            <TextUI variant="bodyL" align='center'>Для продолжения работы, пожалуйста, свяжитесь с вашим агентом.</TextUI>
            <ButtonUI label="Выход" variant="main2" to='/login' fullWidth />
        </div>
    );
};

export default RegistrationWithContactAgent;
