import React from 'react';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';

import styles from './UIComponentsPage.module.scss';

const UIComponentsPage: React.FC = () => {
    return (
        <div className={styles.pageWrapper}>
            <h1 className={styles.sectionTitle}>Buttons</h1>
            <div className={styles.section}>
                <ButtonUI label="Main" variant="main" />
                <ButtonUI label="Main 2" variant="main2" height='42px' />
                <ButtonUI label="main Green" variant="mainGreen" />
                <ButtonUI label="Secondary" variant="secondary" />
                <ButtonUI label="Secondary 2" variant="secondary2" />
                <ButtonUI label="Link" variant="link" />
                <ButtonUI label="Small" variant="small" />
                <ButtonUI label="Small 2" variant="small2" />
            </div>

            <h1 className={styles.sectionTitle}>Text Components</h1>
            <div className={styles.section}>
                <TextUI variant="h1" color="general" align="center">Heading 1 - General</TextUI>
                <TextUI variant="h2" color="secondary" align="center">Heading 2 - Secondary</TextUI>
                <TextUI variant="bodyL" color="accent" align="center">Body Large - Accent</TextUI>
                <TextUI variant="bodyMsemiB" color="general" align="center">Body Medium Semi-Bold - General</TextUI>
                <TextUI variant="bodySsemiB" color="error" align="center">Body Small Semi-Bold - Error</TextUI>
                <TextUI variant="bodyXS" color="general2" align="center">Body Extra Small - General2</TextUI>
            </div>
        </div>
    );
};

export default UIComponentsPage;
