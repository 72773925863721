import React from 'react';
import './PrivacyPolicy.scss';
import { Container } from 'components/ui/Container';
import MainAppLayout from 'app/layouts/MainAppLayout/MainAppLayout';

const PrivacyPolicy = () => {
  return (
    <MainAppLayout>
      <Container>
        <div className='user-agreement-box'>
          <div className='user-agreement-box__section'>
            <p className='user-agreement-box__title'>
              Политика безопасности и конфиденциальности
            </p>

            <div className='user-agreement-box__content'>
              <div className='user-agreement-box__intro'>
                <p>
                  Настоящая Политика конфиденциальности используется
                  Администратором сервиса «Ad.kitchen» (далее – «Оператор»),
                  устанавливает условия обработки Персональных и иных данных
                  Пользователей.
                </p>{' '}
                {/* <br /> */}
                <p>
                  Политика конфиденциальности доступна по адресу{' '}
                  <span>
                    <a href='https://ad.kitchen/privacy-policy' target='blank'>
                      https://ad.kitchen/privacy-policy.
                    </a>
                  </span>{' '}
                </p>
                {/* <br /> */}
                <p>
                  В случае отказа лица от обработки данных в соответствии с
                  настоящей Политикой конфиденциальности, такому лицу будет
                  отказано в предоставлении сервиса «Ad.kitchen».{' '}
                </p>
                {/* <br /> */}
                <p>
                  Использование Пользователем сервиса «Ad.kitchen» подтверждает
                  согласие Пользователя с настоящей Политикой конфиденциальности.{' '}
                </p>
              </div>
              <div className='user-agreement-box__rules'>
                <ol>
                  <li className='user-agreement-box__rule user-agreement-box__rule--1'>
                    <span>
                      Персональные и иные пользовательские данные обрабатываются в
                      целях:
                    </span>
                    <br /> <br />
                    <ul>
                      <li>
                        оказания и совершенствования предоставляемых Пользователю
                        услуг в рамках сервиса «Ad.kitchen»;
                      </li>
                      <li>
                        для обратной связи и взаимодействия с Пользователем;
                      </li>
                      <li>
                        для анализа объема оказанных услуг в рамках сервиса
                        «Ad.kitchen»;
                      </li>
                      <li>
                        для оценки качества оказываемых услуг в рамках сервиса
                        «Ad.kitchen»;
                      </li>
                      <li>
                        уведомления Пользователя о рекламных акциях,
                        предоставления иной информации в целях рекламы.
                      </li>
                    </ul>
                    <br />
                  </li>
                  <li className='user-agreement-box__rule user-agreement-box__rule--2'>
                    <span>
                      Условия сбора и использования Персональных и иных
                      пользовательских данных:
                    </span>
                    <br /> <br />
                    <ul>
                      <li>
                        Оператор при оказании услуг предоставляет Пользователю
                        доступ к его Персональным и иным пользовательским данным
                        путем предоставления логина и пароля;
                      </li>
                      <li>
                        Оператор обрабатывает Персональные и иные пользовательские
                        данные, только с согласия Пользователя;
                      </li>
                      <li>
                        Оператор обеспечивает законность целей и способов
                        обработки Персональных и иных пользовательских данных,
                        соблюдает добросовестность совершаемых им действии по
                        обработке;
                      </li>
                      <li>
                        Персональные и иные пользовательские данные обрабатываться
                        без принуждения Пользователя и согласно его воли;
                      </li>
                      <li>
                        Оператор обрабатывает только те Персональные и иные
                        пользовательские данные, которые соответствует цели
                        обработки;
                      </li>
                      <li>
                        Оператор не объединяет Персональные и иные
                        пользовательские данные, с другими данными Оператора, в
                        том числе с данными других операторов;
                      </li>
                      <li>
                        Оператор обрабатывает Персональные и иные пользовательские
                        данные автоматизировано;
                      </li>
                      <li>
                        Оператор принимает все необходимые меры для защиты
                        Персональных и иных данных Пользователей. Оператор
                        предоставляет субъектам данных рекомендации по защите
                        персональных данных на оборудовании, принадлежащем
                        Пользователю.
                      </li>
                    </ul>
                    <br />
                  </li>
                  <li className='user-agreement-box__rule user-agreement-box__rule--3'>
                    <span>
                      Для оказания услуг Ad.kitchen Оператор обрабатывает
                      следующие Персональные и иные данные Пользователей:
                    </span>
                    <br /> <br />
                    <ul>
                      <li>имя;</li>
                      <li>электронная почта;</li>
                      <li>IP адрес;</li>
                      <li>Интернет браузер;</li>
                      <li>Реферальная ссылка;</li>
                      <li>
                        Иные пользовательские данные (включая данные пользователя
                        содержащиеся в Гугл, иные пользовательские данные, которые
                        предоставлены Пользователем при использовании сервиса
                        «Ad.kitchen»).
                      </li>
                    </ul>
                    <br />
                  </li>
                  <li className='user-agreement-box__rule user-agreement-box__rule--4'>
                    <span>
                      Хранение Персональных и иных пользовательских данных
                    </span>
                    <br /> <br />
                    <ul>
                      <li>
                        Оператор хранит Персональные и иные данные Пользователя до
                        момента удаления учетной записи Пользователя. Оператор
                        хранит некоторые Персональные и иные данные Пользователя
                        после удаления учетной записи Пользователя в течение более
                        длительного периода времени, необходимого для налоговых,
                        бухгалтерских и иных целей в соответствии с применимым
                        законодательством.
                      </li>
                      <li>
                        Оператор удаляет Персональные и иные данные Пользователя
                        только с разрешения Пользователя. Оператор вправе удалить
                        Персональные и иные данные Пользователя без согласия
                        Пользователя в соответствии с действующим
                        законодательством.
                      </li>
                    </ul>
                    <br />
                  </li>
                  <li className='user-agreement-box__rule user-agreement-box__rule--5'>
                    <span>
                      Раскрытие и передача Персональных и иных данных
                      Пользователей
                    </span>
                    <br /> <br />
                    <ul>
                      <li>
                        Оператор не вправе разглашать Персональные и иные данные
                        Пользователей третьим лицам без согласия субъекта данных,
                        если иное не предусмотрено Политикой конфиденциальности.
                      </li>
                      <li>
                        Персональные и иные данные Пользователей могут
                        обрабатываться работниками Оператора, иными доверенными
                        лицами или лицами на основании указаний Оператора, с
                        согласия и с соблюдением Политики конфиденциальности и
                        иных соответствующих мер конфиденциальности и
                        безопасности.
                      </li>
                      <li>
                        Оператор передает Персональные и иные данные
                        Пользователей, если это необходимо по запросу суда,
                        государства или предусмотрено действующим
                        законодательством.
                      </li>
                    </ul>
                    <br />
                  </li>
                  <li className='user-agreement-box__rule user-agreement-box__rule--6'>
                    <span>Права и обязанности Оператора:</span>
                    <br /> <br />
                    <ul>
                      <li>
                        Оператор гарантирует добросовестное отношение и
                        прозрачность использования Персональных и иных данных
                        Пользователя;
                      </li>
                      <li>
                        если Пользователь запрашивает доступ к своим персональным
                        и другим пользовательским данным, такой доступ должен быть
                        предоставлен Пользователю в течение разумного периода
                        времени;
                      </li>
                      <li>
                        Оператор гарантирует добросовестное отношение к
                        Пользователю и прозрачность использования Персональных и
                        иных данных Пользователя в разумный период времени;
                      </li>
                      <li>
                        если Пользователь обнаружит ошибку в своих данных, он
                        должен немедленно связаться с Оператором. Оператор
                        устраняет ошибку в кратчайшие сроки;
                      </li>
                      <li>
                        по запросу Пользователя Оператор может удалить некоторые
                        персональные данные без ущерба для предоставления услуг
                        Пользователю. В противном случае Оператор вправе отказать
                        в удалении части Персональных и иных данных Пользователя.
                        В этом случае Пользователь может отказаться от оказания
                        услуг;
                      </li>
                      <li>
                        Оператор вправе запросить у Пользователя уточнение
                        персональных данных. Оператор должен указать цель
                        исправления;
                      </li>
                      <li>
                        Оператор заявляет, что настоящая Политика
                        конфиденциальности никоим образом не ущемляет права
                        Пользователя на защиту своих прав в соответствии с
                        действующим законодательством о защите данных страны его
                        проживания.
                      </li>
                    </ul>
                    <br />
                  </li>
                  <li className='user-agreement-box__rule user-agreement-box__rule--7'>
                    <span>
                      В целях, указанных в настоящей Политике конфиденциальности
                      доверенным третьим лицом, косвенно участвующим в обработке
                      Персональных и иных пользовательских данных является:
                    </span>
                    <br /> <br />
                    <ul>
                      <li>
                        в целях коммуникации c Пользователями Оператор может
                        использовать сервис Google G Suit и делиться частью
                        данных. Оператор полностью убежден в достаточности защиты
                        пользовательских данных, передаваемых с использованием
                        сервиса Google G Suit
                        <a
                          href='https://policies.google.com/privacy?hl=ru'
                          target='blank'
                        >
                          (https://policies.google.com/privacy?hl=ru).
                        </a>
                      </li>
                      <li>
                        Оператор для целей совершенствования сервиса Ad.kitchen и
                        предоставления в личном кабинете Пользователя статистики и
                        данных, собранных в отношении сайтов Пользователя, с
                        согласия Пользователя вправе получить доступ к его данным
                        в Google Analytics и обрабатывать их. Данные Google
                        Analytics, получаемые сервисом Ad.kitchen, включают в себя
                        данные электронной почты Пользователя
                        (auth/userinfo.emai), а также иные данные из профиля,
                        сделанные Пользователем доступными
                        (auth/userinfo.profile). Если Пользователь не даст
                        согласие на передачу данных из сервиса Google Analytics,
                        то Пользователю будет недоступна статистика в кабинете
                        сервиса Ad.kitchen и ряд его функций. Других
                        неблагоприятных последствий для Пользователя не будет.
                        Оператор полностью убежден в достаточности защиты
                        пользовательских данных при обработке их Google Analytics.
                        Пользователь вправе ознакомиться с правилами обработки
                        своих данных сервисом Google Analytics здесь{' '}
                        <span>
                          <a
                            href='https://policies.google.com/privacy?hl=ru'
                            target='blank'
                          >
                            https://policies.google.com/privacy?hl=ru
                          </a>
                          .
                        </span>
                      </li>
                    </ul>
                    <br />
                  </li>
                  <li className='user-agreement-box__rule user-agreement-box__rule--8'>
                    <span>Прочие положения</span>
                    <br /> <br />
                    <ul>
                      <li>
                        Оператор как контролер Данных Пользователя несет
                        ответственность за их сохранность, использование и
                        удаление в соответствии с законодательством.
                      </li>
                      <li>
                        Оператор оставляет за собой право время от времени
                        изменять условия настоящей Политики конфиденциальности,
                        связанные с улучшением защиты персональных данных.
                      </li>
                      <li>
                        настоящая Политика конфиденциальности отражает
                        обязательство Оператора по соблюдению законодательства и
                        защите Пользователей от любого несанкционированного
                        вмешательства в их личную информацию при использовании
                        сервиса Оператора, в том числе намерение Пользователя
                        стать клиентом Оператора и удаление, обслуживание и
                        систематизацию персональных и иных данных Пользователя.
                      </li>
                      <li>
                        Настоящая Политика конфиденциальности вступает в силу с
                        даты ее опубликования на сайте{' '}
                        <span>
                          <a
                            href='https://ad.kitchen/privacy-policy'
                            target='blank'
                          >
                            https://ad.kitchen/privacy-policy — ad.kitchen
                          </a>
                        </span>{' '}
                      </li>
                      <li>
                        Пользователь имеет право связываться с Оператором по
                        вопросам обработки его персональных данных по адресу
                        электронной почты:{' '}
                        <a href='mailto:info@ad.kitchen'>info@ad.kitchen</a>.
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </MainAppLayout>
  );
};

export default PrivacyPolicy;
