import React from 'react'
import AuthLayout from 'app/layouts/AuthLayout/AuthLayout'
import RecoveryPassword from 'components/recovery-password/RecoveryPassword/RecoveryPassword'

export default function RecoveryPasswordPage() {
    return (
        <AuthLayout>
            <RecoveryPassword />
        </AuthLayout>
    )
}