import React from 'react';
import { useForm } from 'react-hook-form';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import styles from './EanCardCheckForm.module.scss';

interface EanCardCheckFormProps {
    onSubmit: (data: any) => void;
}

const EanCardCheckForm: React.FC<EanCardCheckFormProps> = ({ onSubmit }) => {
    const { register, handleSubmit } = useForm();

    const handleFormSubmit = (data: any) => {
        onSubmit(data);
    };

    return (
        <CardWrapper title="Проверка EAN карты">
            <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.form}>
                <div className={styles.inputWrapper}>
                    <input
                        type="text"
                        placeholder="EAN КАРТЫ"
                        {...register('ean', { required: true, maxLength: 13 })}
                        className={styles.input}
                    />
                </div>
                <TextUI variant="bodyS" className={styles.description}>
                    Укажите 13-значный код с карты, расположенный под основным номером
                </TextUI>
                <button type="submit" className={styles.submitButton}>
                    ОТПРАВИТЬ В БАНК
                </button>
            </form>
        </CardWrapper>
    );
};

export default EanCardCheckForm;
