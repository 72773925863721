import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { PhotoForm } from 'components/PhotoForm/PhotoForm';
import { useCreateCardRequestMutation, useGetCardRequestByIdQuery, useGetPhotoDataQuery, useUpdateRequestStepMutation } from 'services/api'; // запрос данных с сервера

// import styles from './Step4.module.scss';

interface Step4Props {
    setIsFormValid: (isValid: boolean) => void;
    onFormSubmit: (data: any) => void;
    requestId: string | null;
    setRequestId: (requestId: any) => void;
}

const Step4: React.FC<Step4Props> = ({ setIsFormValid, onFormSubmit, requestId, setRequestId }) => {
    const { data: cardRequestData, refetch } = useGetCardRequestByIdQuery(requestId);

    const [createCardRequest] = useCreateCardRequestMutation();
    const [updateRequestStep] = useUpdateRequestStepMutation();

    const methods = useForm({
        mode: 'onChange',
        defaultValues: cardRequestData || {}, // предзаполнение данными сервера, если они есть
    });

    const { formState: { isValid }, reset } = methods;

    // Сбрасываем значения формы, если `cardRequestData` обновляется
    useEffect(() => {
        if (cardRequestData) {
            reset(cardRequestData);
        }
    }, [cardRequestData, reset]);

    useEffect(() => {
        setIsFormValid(isValid);
    }, [isValid, setIsFormValid]);

    // Вызываем refetch, когда данные потенциально изменяются (например, после загрузк�� файлов)
    useEffect(() => {
        refetch();
    }, [refetch]);

    const handleFormSubmit = async (data: any) => {
        try {
            if (!requestId) {
                // Создание новой заявки, если requestId отсутствует
                const response = await createCardRequest(data).unwrap();
                setRequestId(response._id);
                localStorage.setItem('requestId', response._id); // Сохраняем в localStorage
            } else {
                // Обновление существующей заявки на шаге 4
                await updateRequestStep({ id: requestId, step: 4, data }).unwrap();
                refetch();
            }
            console.log('Data updated successfully');
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const onSubmit = (data: any) => {
        handleFormSubmit(data);
    };

    return (
        <PhotoForm
            setIsFormValid={setIsFormValid}
            onFormSubmit={onSubmit}
            serverData={{
                documentFiles: cardRequestData?.documentPhotos || [],
                clientFiles: cardRequestData?.clientPhoto ? [cardRequestData.clientPhoto] : [],
            }}
            requestId={requestId}
        />
    );
};

export default Step4;
