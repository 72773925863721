import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { CustomInput } from 'components/CustomInput/CustomInput';
import { InputTypeEnum } from 'shared/models';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { useUpdatePasswordMutation } from 'services/api'; // Импортируем хук RTK Query
import { useSelector } from 'react-redux';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import PencilSettings from '../../../../assets/icons/pencil-settings.svg';

import styles from './ChangePasswordForm.module.scss';

interface PasswordData {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const ChangePasswordForm = () => {
    const methods = useForm<PasswordData>();
    const { handleSubmit, watch } = methods;

    const [updatePassword, { isLoading, error }] = useUpdatePasswordMutation(); // Используем хук RTK Query

    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [isPasswordUpdated, setIsPasswordUpdated] = useState(false); // Состояние для отслеживания успешного обновления

    const userEmail = useSelector((state: any) => state.login?.user?.email);

    const currentPassword = watch('currentPassword');
    const newPassword = watch('newPassword');
    const confirmPassword = watch('confirmPassword');

    useEffect(() => {
        setIsPasswordUpdated(false);
    }, [currentPassword, newPassword, confirmPassword]);

    const onSubmit = async (data: PasswordData) => {
        if (newPassword !== confirmPassword) {
            setPasswordError('Пароли не совпадают');
            return;
        }

        try {
            const result = await updatePassword({
                email: userEmail,
                currentPassword: data.currentPassword,
                newPassword: data.newPassword,
            }).unwrap();

            console.log('Password updated:', result);
            setIsPasswordUpdated(true); // Устанавливаем флаг при успешном обновлении
        } catch (err) {
            console.error('Failed to update password:', err);
            setIsPasswordUpdated(false); // Сбрасываем флаг при ошибке
        }
    };

    return (
        <FormProvider {...methods}>
            <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
                <TextUI variant="h2">Изменить пароль</TextUI>

                <CustomInput
                    name="currentPassword"
                    type={InputTypeEnum.Password}
                    placeholder="Текущий пароль"
                />
                <CustomInput
                    name="newPassword"
                    type={InputTypeEnum.Password}
                    placeholder="Новый пароль"
                />
                <CustomInput
                    name="confirmPassword"
                    type={InputTypeEnum.Password}
                    placeholder="Подтвердите пароль"
                />

                {passwordError && <p className={styles.error}>{passwordError}</p>}
                {error && <p className={styles.error}>Ошибка при обновлении пароля</p>}
                <div className={styles.buttonWidth}>
                    <ButtonUI type="submit" label="Сохранить" variant="main" disabled={isLoading} />
                    {isPasswordUpdated && !error && (
                        <div className={styles.settingsSaveBox}>
                            <img src={PencilSettings} className="PencilSettings" alt="Icon" />
                            <p>Ваши изменения были сохранены</p>
                        </div>
                    )}
                </div>
            </form>
        </FormProvider>
    );
};

export default ChangePasswordForm;
