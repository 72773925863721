import React from 'react';

const SuccessIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_572_5263)">
        <path d="M28 0C12.537 0 0 12.537 0 28C0 43.463 12.537 56 28 56C43.463 56 56 43.463 56 28C56 12.537 43.463 0 28 0ZM36.6612 18.1016L24.2882 30.4745L19.3388 25.5255C17.9716 24.1583 15.7535 24.1583 14.3894 25.5255C13.0222 26.8927 13.0222 29.1108 14.3894 30.4749L21.8133 37.8989C23.177 39.2661 25.3956 39.2661 26.7628 37.8989L41.6106 23.051C42.9778 21.6838 42.9778 19.4657 41.6106 18.1016C40.2465 16.7344 38.0284 16.7344 36.6612 18.1016Z" fill="#71BE81" />
        <path d="M24.2885 30.4745L36.6615 18.1016C38.0286 16.7344 40.2468 16.7344 41.6109 18.1016C42.9781 19.4657 42.9781 21.6838 41.6109 23.051L26.763 37.8989C25.3958 39.2661 23.1773 39.2661 21.8136 37.8989L14.3896 30.4749C13.0225 29.1108 13.0225 26.8927 14.3896 25.5255C15.7538 24.1583 17.9719 24.1583 19.3391 25.5255L24.2885 30.4745Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_572_5263">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default SuccessIcon;
