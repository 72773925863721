import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { InputTypeEnum, UserData } from 'shared/models';
import { useRegisterUserMutation } from 'services/api';
import { Link, useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import InputCheckboxUI from 'components/ui/InputCheckboxUI/InputCheckboxUI';
import Cookies from 'js-cookie';
import styles from './RegistrationForm.module.scss';
import LoadingOverlay from 'components/ui/LoadingOverlay/LoadingOverlay';
import { Modal } from 'components/ui/Modal';
import { CustomInput } from 'components/CustomInput/CustomInput';
import { useSelector } from 'react-redux';

const RegistrationForm = () => {
  const methods = useForm<UserData>();
  const { watch, handleSubmit, formState } = methods;

  const password = watch('password');
  const navigate = useNavigate();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [searchParams] = useSearchParams();
  const [registerUser, { isLoading: isRegisterLoading }] = useRegisterUserMutation();
  const pid = searchParams.get('pid');

  // Состояние для отображения модального окна
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isUserAuth = useSelector((state: any) => state.login?.user?.isAuth);

  useEffect(() => {
    if (isUserAuth) {
      navigate('/settings')
    }
  }, [navigate, isUserAuth])

  // Fetch партнерский ID и сохраняем его в Cookies
  useEffect(() => {
    if (pid) {
      Cookies.set('pid', pid, { expires: 365 });
    }
  }, [pid]);

  const onSubmit = async (data: UserData) => {
    const isValid = await methods.trigger();

    if (isValid) {
      if (pid) {
        data.partnerId = pid;
      }

      try {
        const result = await registerUser(data).unwrap();
        console.log('result', result);

        if (result) {
          navigate('/approve-registration-code');
        }
      } catch (error) {
        console.log('Registration failed:', error);

        // Устанавливаем сообщение об ошибке с сервера
        // const serverMessage = error?.data?.message || 'Произошла ошибка при регистрации.';
        const serverMessage = (error as { data?: { message?: string } })?.data?.message || 'Произошла ошибка при регистрации.';
        setErrorMessage(serverMessage);
        setIsModalOpen(true); // Открываем модальное окно при ошибке
      }
    } else {
      console.log('Errors:', formState.errors);
      setErrorMessage('Некоторые поля не заполнены или заполнены неверно');
      setIsModalOpen(true); // Открываем модальное окно, если форма невалидна
    }
  };

  const closeModal = () => setIsModalOpen(false); // Функция для закрытия модального окна

  return (
    <FormProvider {...methods}>
      {isRegisterLoading && <LoadingOverlay />}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        message={errorMessage || "Некоторые поля не заполнены или заполнены неверно"}
      />
      <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <TextUI variant="h1" align='center'>Регистрация</TextUI>

        <CustomInput
          name='name'
          type={InputTypeEnum.Text}
          placeholder='ВВЕДИТЕ ФИО'
          validate={(value) => {
            const isValid = /^[А-Яа-яЁёA-Za-z\s]+$/.test(value);
            return isValid || 'ФИО должно содержать только буквы и пробелы';
          }}
        />

        <CustomInput
          name='email'
          type={InputTypeEnum.Email}
        />
        <CustomInput
          name='messenger'
          type={InputTypeEnum.Text}
          placeholder='ВВЕДИТЕ TELEGRAM/SKYPE/WHATSAPP'
          validate={(value) => {
            if (value.length > 50) {
              return 'Сообщение не должно превышать 50 символов';
            }
            if (value.length < 3) {
              return 'Сообщение не должно быть меньше 3 символов';
            }
            return true; // Valid if length is within the limit
          }}
        />
        <CustomInput
          name='phone'
          type={InputTypeEnum.Tel}
          placeholder='ВВЕДИТЕ НОМЕР ТЕЛЕФОНА'
          validate={(value) => {
            const cleanedValue = value.replace(/\D/g, ''); // Remove non-numeric characters
            if (!/^\+79\d{9}$/.test(value)) {
              return 'Телефонный номер должен начинаться с +79 и содержать 11 цифр';
            }
            return true;
          }}
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/\D/g, ''); // Allow only numeric input
          }}
        />
        <CustomInput
          name='password'
          type={InputTypeEnum.Password}
          validate={(value: string) => {
            // Проверяем, что пароль не состоит только из прочерков или пробелов
            if (/^-+$/.test(value) || /^\s+$/.test(value)) {
              return 'Пароль не должен состоять только из прочерков или пробелов';
            }
            return true; // Пароль валиден
          }}
        />
        <CustomInput
          name='repeatPassword'
          type={InputTypeEnum.Password}
          placeholder='ПОДТВЕРДИТЕ ПАРОЛЬ'
          validate={(value: any) =>
            value === password || 'Пароли не совпадают'
          }
        />
        <div className={styles.checkboxWrapper}>
          <InputCheckboxUI
            className='register-checkbox'
            id='checkbox'
            onChange={(e: any) => setIsCheckboxChecked(e.target.checked)}
          />
          <div>
            Соглашаюсь с условиями{' '}
            <Link to='/privacy-policy' target='_blank'>
              обработки персональных данных
            </Link>{' '}
            и{' '}
            <Link to='/user-agreement' target='_blank'>
              оферты
            </Link>
          </div>
        </div>

        <ButtonUI disabled={!isCheckboxChecked} type='submit' label="Регистрация" variant="main" fullWidth={true} />
        <TextUI variant="bodyS" color="secondary" align='center'>Есть аккаунт?</TextUI>
        <Link to='/login'>
          <ButtonUI label={'Войти'} variant={'link'} />
        </Link>
      </form>
    </FormProvider>
  );
};

export default RegistrationForm;
