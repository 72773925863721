import React from 'react';
import './offert-law.scss';
import '../PrivacyPolicy/PrivacyPolicy.scss';
import { Container } from 'components/ui/Container';
import MainAppLayout from 'app/layouts/MainAppLayout/MainAppLayout';

const OffertLaw = () => {
  return (
    <MainAppLayout>
      <Container>
        <div className='recovery-container recovery-container--full-height user-agreement-box offer-contract'>
          <div className='user-agreement-box__section offer-contract-section'>
            <div className='offer-contract-box'>
              <div className='user-agreement-box__title offer-contract-box__title'>
                <p>Договор-оферта</p>
              </div>

              <div className='offer-contract-box__content'>
                <ol className='user-agreement-box__content offer-contract-box__list list'>
                  <li className='offer-contract-box__item list__item list__item-1'>
                    <span className='list__item--title'>Общие положения</span>

                    <ol className='list__sublist'>
                      <li className='list__subitem list__subitem-1.1'>
                        Настоящее соглашение (далее также – Соглашение) регулирует
                        отношения по пользованию ресурсом Ad.kitchen, размещенном
                        на сайте в сети Интернет по адресу{' '}
                        <a href='https://ad.kitchen/' target='blank'>
                          https://ad.kitchen/
                        </a>
                        .
                        отношения по пользованию ресурсом Ad.kitchen, размещенном
                        на сайте в сети Интернет по адресу{' '}
                        <a href='https://ad.kitchen/' target='blank'>
                          https://ad.kitchen/
                        </a>
                        .
                      </li>
                      <li className='list__subitem list__subitem-1.2'>
                        Данным ресурсом могут использоваться как юридические лица,
                        в лице их уполномоченных представителей, так и физические
                        в лице их уполномоченных представителей, так и физические
                        лица, выступая от своего имени, либо по поручению других
                        лиц.
                      </li>
                      <li className='list__subitem list__subitem-1.3'>
                        Термины и определения, используемые в рамках настоящего
                        Соглашения: Сайт -{' '}
                        <a href='https://ad.kitchen/' target='blank'>
                          https://ad.kitchen/
                        </a>
                        Соглашения: Сайт -{' '}
                        <a href='https://ad.kitchen/' target='blank'>
                          https://ad.kitchen/
                        </a>
                        <p>
                          Ad.kitchen (далее – Сервис) – ресурс по предоставлению
                          доступов к рекламным площадкам и иным сервисам,
                          размещенный на Сайте.
                          доступов к рекламным площадкам и иным сервисам,
                          размещенный на Сайте.
                        </p>
                        <p>
                          Площадки (рекламные площадки) – рекламные сервисы в сети
                          Интернет, доступ к которым возможен посредствам Сервиса.
                          Интернет, доступ к которым возможен посредствам Сервиса.
                        </p>
                        <p>
                          Пользователь – лицо, пришедшее на Сайт, действующее от
                          своего имени, либо в интересах третьего лица.
                        </p>
                        <p>
                          Личный кабинет (учетная запись пользователя) – в рамках
                          настоящего Соглашения, личный кабинет пользователя на
                          Сайте, доступный ему после регистрации на Сайте
                          настоящего Соглашения, личный кабинет пользователя на
                          Сайте, доступный ему после регистрации на Сайте
                        </p>
                      </li>

                      <li className='list__subitem list__subitem-1.4'>
                        Регистрируясь на Сайте Пользователь подтверждает, что
                        условия настоящего Соглашения ему ясны и не вызывают
                        вопросов.
                        условия настоящего Соглашения ему ясны и не вызывают
                        вопросов.
                      </li>

                      <li className='list__subitem list__subitem-1.5'>
                        Пользователь, действующий в интересах третьего лица,
                        настоящим подтверждает, что обладает достаточными
                        полномочиями для заключения настоящего соглашения,
                        принятия его условий, и использования Сервиса в
                        соответствии с его назначением.
                      </li>

                      <li className='list__subitem list__subitem-1.6'>
                        Пользователь, регистрируясь на Сайте, подтверждает свое
                        согласие с условиями настоящего Соглашения.
                      </li>

                      <li className='list__subitem list__subitem-1.7'>
                        Пользователь, регистрируясь на Сайте, подтверждает, что он
                        прочел и полностью принимает политику Сервиса в отношении
                        обработки персональных данных, размещенную на Сайте по
                        адресу:{' '}
                        <a
                          href='https://stage.ad.kitchen/user-argeement'
                          target='blank'
                        >
                          https://stage.ad.kitchen/user-agreement
                        </a>
                      </li>

                      <li className='list__subitem list__subitem-1.8'>
                        Данное Соглашение для каждого Пользователя вступает в
                        действие после прохождения процедуры регистрации на Сайте,
                        действие после прохождения процедуры регистрации на Сайте,
                        а именно после нажатия кнопки «Зарегистрироваться» на
                        странице регистрации.
                      </li>

                      <li className='list__subitem list__subitem-1.9'>
                        Любые изменения настоящего соглашения вступают в силу с
                        момента опубликования новой версии соглашения по адресу
                        https://ad.kitchen/.
                      </li>
                    </ol>
                  </li>

                  <li className='offer-contract-box__item list__item list__item-2'>
                    <span className='list__item--title'>Предмет соглашения</span>

                    <ol className='list__sublist'>
                      <li className='list__subitem list__subitem-2.1'>
                        На основании настоящего Соглашения Пользователю
                        предоставляется доступ к сервису ZaleyCash на условиях,
                        изложенных в настоящем Соглашения.
                      </li>
                    </ol>
                  </li>

                  <li className='offer-contract-box__item list__item list__item-3'>
                    <span className='list__item--title'>Контакты сторон</span>

                    <ol className='list__sublist'>
                      <li className='list__subitem list__subitem-3.1'>
                        Правовым основанием обработки персональных данных является
                        совокупность нормативных правовых актов, во исполнение
                        совокупность нормативных правовых актов, во исполнение
                        которых и в соответствии с которыми Оператор осуществляет
                        обработку персональных данных, в том числе:
                        <ul>
                          <li>Конституция Российской Федерации;</li>
                          <li>Гражданский кодекс Российской Федерации;</li>
                          <li>Трудовой кодекс Российской Федерации;</li>
                          <li>Налоговый кодекс Российской Федерации;</li>
                          <li>
                            Федеральный закон от 08.02.1998 N 14-ФЗ "Об обществах
                            с ограниченной ответственностью";
                          </li>

                          <li>
                            Федеральный закон от 06.12.2011 N 402-ФЗ "О
                            бухгалтерском учете";
                          </li>
                          <li>
                            Федеральный закон от 15.12.2001 N 167-ФЗ "Об
                            обязательном пенсионном страховании в Российской
                            Федерации";
                          </li>
                          <li>
                            иные нормативные правовые акты, регулирующие
                            отношения, связанные с деятельностью Оператора.
                          </li>
                        </ul>
                      </li>
                      <li className='list__subitem list__subitem-3.2'>
                        Правовым основанием обработки персональных данных также
                        являются:
                        <ul>
                          <li>устав;</li>
                          <li>
                            договоры, заключаемые между Оператором и субъектами
                            персональных данных;
                          </li>
                          <li>
                            согласие субъектов персональных данных на обработку их
                            персональных данных.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>

                  <li className='offer-contract-box__item list__item list__item-4'>
                    <span className='list__item--title'>
                      Объем и категории обрабатываемых персональных данных,
                      категории субъектов персональных данных
                    </span>

                    <ol className='list__sublist'>
                      <li className='list__subitem list__subitem-4.1'>
                        Содержание и объем обрабатываемых персональных данных
                        должны соответствовать заявленным целям обработки,
                        предусмотренным в разд. 2 настоящей Политики.
                        Обрабатываемые персональные данные не должны быть
                        избыточными по отношению к заявленным целям их обработки.
                      </li>

                      <li className='list__subitem list__subitem-4.2'>
                        Оператор может обрабатывать персональные данные следующих
                        категорий субъектов персональных данных.
                        <div className='list__subitem-4.2.1'>
                          <div>
                            4.2.1. Кандидаты для приема на работу к Оператору:
                          </div>
                          <ul>
                            <li>фамилия, имя, отчество;</li>
                            <li>пол;</li>
                            <li>гражданство;</li>
                            <li>дата и место рождения;</li>
                            <li>контактные данные;</li>
                            <li>
                              сведения об образовании, опыте работы, квалификации;
                              иные персональные данные, сообщаемые кандидатами в
                              резюме и сопроводительных письмах.
                            </li>
                          </ul>
                        </div>
                        <div className='list__subitem-4.2.2'>
                          <div>
                            4.2.2. Работники и бывшие работники Оператора:
                          </div>

                          <ul>
                            <li>фамилия, имя, отчество;</li>
                            <li>пол;</li>
                            <li>гражданство;</li>
                            <li>дата и место рождения;</li>
                            <li>изображение (фотография);</li>
                            <li>паспортные данные;</li>
                            <li>адрес регистрации по месту жительства;</li>
                            <li>адрес фактического проживания;</li>
                            <li>контактные данные;</li>
                            <li>индивидуальный номер налогоплательщика;</li>
                            <li>
                              страховой номер индивидуального лицевого счета
                              (СНИЛС);
                            </li>
                            <li>
                              сведения об образовании, квалификации,
                              профессиональной подготовке и повышении
                              квалификации;
                            </li>
                            <li>
                              семейное положение, наличие детей, родственные
                              связи;
                            </li>
                            <li>
                              сведения о трудовой деятельности, в том числе
                              наличие поощрений, награждений и (или)
                              дисциплинарных взысканий;
                            </li>
                            <li>данные о регистрации брака;</li>
                            <li>сведения о воинском учете;</li>
                            <li>сведения об инвалидности;</li>
                            <li>сведения об удержании алиментов;</li>
                            <li>сведения о доходе с предыдущего места работы;</li>
                            <li>
                              иные персональные данные, предоставляемые
                              работниками в соответствии с требованиями трудового
                              законодательства.
                            </li>
                          </ul>
                        </div>
                        <div className='list__subitem-4.2.3'>
                          <div>4.2.3. Члены семьи работников Оператора:</div>

                          <ul>
                            <li>фамилия, имя, отчество;</li>
                            <li>степень родства;</li>
                            <li>год рождения;</li>
                            <li>
                              иные персональные данные, предоставляемые
                              работниками в соответствии с требованиями трудового
                              законодательства.
                            </li>
                          </ul>
                        </div>
                        <div className='list__subitem-4.2.4'>
                          <div>
                            4.2.4. Клиенты и контрагенты Оператора (физические
                            лица):
                          </div>

                          <div>
                            фамилия, имя, отчество; дата и место рождения;
                            паспортные данные; адрес регистрации по месту
                            жительства; контактные данные; замещаемая должность;
                            индивидуальный номер налогоплательщика; номер
                            расчетного счета; иные персональные данные,
                            предоставляемые клиентами и контрагентами (физическими
                            лицами), необходимые для заключения и исполнения
                            договоров.
                          </div>
                        </div>
                        <div className='list__subitem-4.2.5'>
                          <p>
                            4.2.5. Представители (работники) клиентов и
                            контрагентов Оператора (юридических лиц):
                          </p>

                          <ul>
                            <li>фамилия, имя, отчество;</li>
                            <li>паспортные данные;</li>
                            <li>контактные данные;</li>
                            <li>замещаемая должность;</li>
                            <li>
                              иные персональные данные, предоставляемые
                              представителями (работниками) клиентов и
                              контрагентов, необходимые для заключения и
                              исполнения договоров.
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li className='list__subitem list__subitem-4.3'>
                        Обработка Оператором биометрических персональных данных
                        (сведений, которые характеризуют физиологические и
                        биологические особенности человека, на основании которых
                        можно установить его личность) осуществляется в
                        соответствии с законодательством Российской Федерации.
                      </li>

                      <li className='list__subitem list__subitem-4.4'>
                        Оператором не осуществляется обработка специальных
                        категорий персональных данных, касающихся расовой,
                        национальной принадлежности, политических взглядов,
                        религиозных или философских убеждений, состояния здоровья,
                        интимной жизни, за исключением случаев, предусмотренных
                        законодательством РФ.
                      </li>
                    </ol>
                  </li>

                  <li className='offer-contract-box__item list__item list__item-5'>
                    <span className='list__item--title'>
                      Порядок и условия обработки персональных данных
                    </span>

                    <ol className='list__sublist'>
                      <li className='list__subitem list__subitem-5.1'>
                        <span>
                          Обработка персональных данных осуществляется Оператором
                          в соответствии с требованиями законодательства
                          Российской Федерации.
                        </span>
                      </li>
                      <li className='list__subitem list__subitem-5.2'>
                        <span>
                          Обработка персональных данных осуществляется с согласия
                          субъектов персональных данных на обработку их
                          персональных данных, а также без такового в случаях,
                          предусмотренных законодательством Российской Федерации.
                        </span>
                      </li>
                      <li className='list__subitem list__subitem-5.3'>
                        <span>
                          Оператор осуществляет как автоматизированную, так и
                          неавтоматизированную обработку персональных данных.
                        </span>
                      </li>
                      <li className='list__subitem list__subitem-5.4'>
                        <span>
                          К обработке персональных данных допускаются работники
                          Оператора, в должностные обязанности которых входит
                          обработка персональных данных.
                        </span>
                      </li>
                      <li className='list__subitem list__subitem-5.5'>
                        <span>
                          Обработка персональных данных осуществляется путем:
                        </span>
                        <ul>
                          <li>
                            получения персональных данных в устной и письменной
                            форме непосредственно от субъектов персональных
                            данных;
                          </li>
                          <li>
                            получения персональных данных из общедоступных
                            источников;
                          </li>
                          <li>
                            внесения персональных данных в журналы, реестры и
                            информационные системы оператора;
                          </li>
                          <li>
                            использования иных способов обработки персональных
                            данных.
                          </li>
                        </ul>
                      </li>
                      <li className='list__subitem list__subitem-5.6'>
                        <span>
                          Не допускается раскрытие третьим лицам и распространение
                          персональных данных без согласия субъекта персональных
                          данных, если иное не предусмотрено федеральным законом.
                        </span>
                      </li>
                      <li className='list__subitem list__subitem-5.7'>
                        <span>
                          Передача персональных данных органам дознания и
                          следствия, в Федеральную налоговую службу, Пенсионный
                          фонд Российской Федерации, Фонд социального страхования
                          и другие уполномоченные органы исполнительной власти и
                          организации осуществляется в соответствии с требованиями
                          законодательства Российской Федерации.
                        </span>
                      </li>
                      <li className='list__subitem list__subitem-5.8'>
                        <span>
                          Оператор принимает необходимые правовые, организационные
                          и технические меры для защиты персональных данных от
                          неправомерного или случайного доступа к ним,
                          уничтожения, изменения, блокирования, распространения и
                          других несанкционированных действий, в том числе:
                        </span>
                        <ul>
                          <li className='list__subitem-5.8.1'>
                            определяет угрозы безопасности персональных данных при
                            их обработке;
                          </li>
                          <li className='list__subitem-5.8.2'>
                            принимает локальные нормативные акты и иные документы,
                            регулирующие отношения в сфере обработки и защиты
                            персональных данных;
                          </li>
                          <li className='list__subitem-5.8.3'>
                            назначает лиц, ответственных за обеспечение
                            безопасности персональных данных в структурных
                            подразделениях и информационных системах Оператора;
                          </li>
                          <li className='list__subitem-5.8.4'>
                            создает необходимые условия для работы с персональными
                            данными;
                          </li>
                          <li className='list__subitem-5.8.5'>
                            организует учет документов, содержащих персональные
                            данные;
                          </li>
                          <li className='list__subitem-5.8.6'>
                            организует работу с информационными системами, в
                            которых обрабатываются персональные данные;
                          </li>
                          <li className='list__subitem-5.8.7'>
                            хранит персональные данные в условиях, при которых
                            обеспечивается их сохранность и исключается
                            неправомерный доступ к ним;
                          </li>
                          <li className='list__subitem-5.8.8'>
                            организует обучение работников Оператора,
                            осуществляющих обработку персональных данных.
                          </li>
                        </ul>
                      </li>
                      <li className='list__subitem list__subitem-5.9'>
                        <span>
                          Оператор осуществляет хранение персональных данных в
                          форме, позволяющей определить субъекта персональных
                          данных, не дольше, чем этого требуют цели обработки
                          персональных данных, если срок хранения персональных
                          данных не установлен федеральным законом, договором.
                        </span>
                      </li>
                      <li className='list__subitem list__subitem-5.10'>
                        <span>
                          При сборе персональных данных, в том числе посредством
                          информационно-телекоммуникационной сети Интернет,
                          Оператор обеспечивает запись, систематизацию,
                          накопление, хранение, уточнение (обновление, изменение),
                          извлечение персональных данных граждан Российской
                          Федерации с использованием баз данных, находящихся на
                          территории Российской Федерации, за исключением случаев,
                          указанных в Законе о персональных данных.
                        </span>
                      </li>
                    </ol>
                  </li>

                  <li className='offer-contract-box__item list__item list__item-6'>
                    <span className='list__item--title'>
                      Актуализация, исправление, удаление и уничтожение
                      персональных данных, ответы на запросы субъектов на доступ к
                      персональным данным
                    </span>

                    <ol className='list__sublist'>
                      <li className='list__subitem list__subitem-6.1'>
                        <span>
                          Подтверждение факта обработки персональных данных
                          Оператором, правовые основания и цели обработки
                          персональных данных, а также иные сведения, указанные в
                          ч. 7 ст. 14 Закона о персональных данных,
                          предоставляются Оператором субъекту персональных данных
                          или его представителю при обращении либо при получении
                          запроса субъекта персональных данных или его
                          представителя.
                        </span>

                        <div>
                          <p>
                            В предоставляемые сведения не включаются персональные
                            данные, относящиеся к другим субъектам персональных
                            данных, за исключением случаев, когда имеются законные
                            основания для раскрытия таких персональных данных.
                            Запрос должен содержать:
                          </p>

                          <ul>
                            <li>
                              номер основного документа, удостоверяющего личность
                              субъекта персональных данных или его представителя,
                              сведения о дате выдачи указанного документа и
                              выдавшем его органе;
                            </li>
                            <li>
                              сведения, подтверждающие участие субъекта
                              персональных данных в отношениях с Оператором (номер
                              договора, дата заключения договора, условное
                              словесное обозначение и (или) иные сведения), либо
                              сведения, иным образом подтверждающие факт обработки
                              персональных данных Оператором;
                            </li>
                            <li>
                              подпись субъекта персональных данных или его
                              представителя.
                            </li>
                          </ul>

                          <div>
                            <p>
                              Запрос может быть направлен в форме электронного
                              документа и подписан электронной подписью в
                              соответствии с законодательством Российской
                              Федерации.
                            </p>
                            <p>
                              Если в обращении (запросе) субъекта персональных
                              данных не отражены в соответствии с требованиями
                              Закона о персональных данных все необходимые
                              сведения или субъект не обладает правами доступа к
                              запрашиваемой информации, то ему направляется
                              мотивированный отказ.
                            </p>
                            <p>
                              Право субъекта персональных данных на доступ к его
                              персональным данным может быть ограничено в
                              соответствии с ч. 8 ст. 14 Закона о персональных
                              данных, в том числе если доступ субъекта
                              персональных данных к его персональным данным
                              нарушает права и законные интересы третьих лиц.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className='list__subitem list__subitem-6.2'>
                        <span>
                          В случае выявления неточных персональных данных при
                          обращении субъекта персональных данных или его
                          представителя либо по их запросу или по запросу
                          Роскомнадзора Оператор осуществляет блокирование
                          персональных данных, относящихся к этому субъекту
                          персональных данных, с момента такого обращения или
                          получения указанного запроса на период проверки, если
                          блокирование персональных данных не нарушает права и
                          законные интересы субъекта персональных данных или
                          третьих лиц.
                        </span>
                        <p>
                          В случае подтверждения факта неточности персональных
                          данных Оператор на основании сведений, представленных
                          субъектом персональных данных или его представителем
                          либо Роскомнадзором, или иных необходимых документов
                          уточняет персональные данные в течение семи рабочих дней
                          со дня представления таких сведений и снимает
                          блокирование персональных данных.
                        </p>
                      </li>
                      <li className='list__subitem list__subitem-6.3'>
                        <span>
                          В случае выявления неправомерной обработки персональных
                          данных при обращении (запросе) субъекта персональных
                          данных или его представителя либо Роскомнадзора Оператор
                          осуществляет блокирование неправомерно обрабатываемых
                          персональных данных, относящихся к этому субъекту
                          персональных данных, с момента такого обращения или
                          получения запроса.
                        </span>
                      </li>
                      <li className='list__subitem list__subitem-6.4'>
                        <span>
                          При достижении целей обработки персональных данных, а
                          также в случае отзыва субъектом персональных данных
                          согласия на их обработку персональные данные подлежат
                          уничтожению, если:
                        </span>

                        <ul>
                          <li>
                            иное не предусмотрено договором, стороной которого,
                            выгодоприобретателем или поручителем по которому
                            является субъект персональных данных;
                          </li>
                          <li>
                            оператор не вправе осуществлять обработку без согласия
                            субъекта персональных данных на основаниях,
                            предусмотренных Законом о персональных данных или
                            иными федеральными законами;
                          </li>
                          <li>
                            иное не предусмотрено другим соглашением между
                            Оператором и субъектом персональных данных.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </MainAppLayout>
  );
};

export default OffertLaw;
