import React from 'react';
import { ProductCard } from 'components/ProductCard';
import styles from './Step0.module.scss';

interface Step0Props {
    selectedBank: string;
    selectedProduct: string;
    onBankChange: (bank: string) => void;
    onProductChange: (product: string) => void;
}

const Step0: React.FC<Step0Props> = ({ selectedBank, selectedProduct, onBankChange, onProductChange }) => {
    // console.log("🚀 ~ selectedProduct:", selectedProduct)
    // console.log("🚀 ~ selectedBank:", selectedBank)
    return (
        <>
            <p className={styles.description}>
                Выберите банк и его продукт, с которым будет оформлена заявка.
            </p>

            <div className={styles.section}>
                <h2>Банк</h2>
                <div className={styles.banks}>
                    <ProductCard
                        label="Альфа-Банк"
                        value="Альфа-Банк"
                        selectedValue={selectedBank}
                        onChange={onBankChange}
                        img="/static/logos/alfabank.svg"
                        name="bank"
                    />
                    <ProductCard
                        label="ВТБ Банк"
                        value="ВТБ"
                        selectedValue={selectedBank}
                        onChange={onBankChange}
                        img="/static/logos/vtbbank.svg"
                        name="bank"
                    />
                </div>
            </div>

            <div className={styles.section}>
                <h2>Продукт</h2>
                <div className={styles.products}>
                    <ProductCard
                        label="Кредитная карта"
                        value="Кредитная карта"
                        selectedValue={selectedProduct}
                        onChange={onProductChange}
                        name="product"
                    />
                    <ProductCard
                        label="Дебетовая карта"
                        value="Дебетовая карта"
                        selectedValue={selectedProduct}
                        onChange={onProductChange}
                        name="product"
                    />
                </div>
            </div>
        </>
    );
};

export default Step0;
