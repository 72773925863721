import React from 'react';

const BurgerMenuIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.625 8.16667C2.625 7.52233 3.01675 7 3.5 7H24.5C24.9832 7 25.375 7.52233 25.375 8.16667C25.375 8.811 24.9832 9.33333 24.5 9.33333H3.5C3.01675 9.33333 2.625 8.811 2.625 8.16667Z" fill="#2F2F33" />
      <path d="M2.625 14C2.625 13.3557 3.01675 12.8333 3.5 12.8333H24.5C24.9832 12.8333 25.375 13.3557 25.375 14C25.375 14.6443 24.9832 15.1667 24.5 15.1667H3.5C3.01675 15.1667 2.625 14.6443 2.625 14Z" fill="#2F2F33" />
      <path d="M3.5 18.6667C3.01675 18.6667 2.625 19.189 2.625 19.8333C2.625 20.4777 3.01675 21 3.5 21H24.5C24.9832 21 25.375 20.4777 25.375 19.8333C25.375 19.189 24.9832 18.6667 24.5 18.6667H3.5Z" fill="#2F2F33" />
    </svg>
  );
};

export default BurgerMenuIcon;
