import React from 'react'
// import HomeIcon from '../../../assets/icons/icon_home.svg'
import MainContainer from '../MainContainer/MainContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import HomeIcon from 'assets/icons/HomeIcon';

import styles from './Router.module.scss';

const RouterComponent = () => {
  const location = useLocation();
  // console.log('location', location)
  const navigate = useNavigate();

  const handleMain = () => {
    navigate('/main')
  }

  const handleOffer = () => {
    navigate('/offers')
  }


  const handleDailyStatistic = () => {
    navigate('/daily-statistic')
  }

  const handleStatisticByOffers = () => {
    navigate('/statistic-by-offers')
  }


  const handleApplications = () => {
    navigate('/applications')
  }

  return (
    <div className={styles.routerContainer}>
      <MainContainer>
        <div className={styles.fullWidth}>
          <div onClick={handleMain} className={styles.routerLeftBox}>
            <HomeIcon active={location?.pathname === "/main" ? true : false} />
            <p className={location?.pathname === "/main" ? styles.activeRoute : ''}>Главная</p>
          </div>
          <div className={styles.routerRightBox}>
            <p onClick={handleOffer} className={location?.pathname === "/offers" ? styles.activeRoute : ''}>офферы</p>
            <p onClick={handleDailyStatistic} className={location?.pathname === "/daily-statistic" ? styles.activeRoute : ''}>Статистика по дням</p>
            <p onClick={handleStatisticByOffers} className={location?.pathname === "/statistic-by-offers" ? styles.activeRoute : ''}>Статистика по офферам</p>
            <p onClick={handleApplications} className={location?.pathname === "/applications" ? styles.activeRoute : ''}>заявки</p>
          </div>
        </div>
      </MainContainer>
    </div>
  )
}

export default RouterComponent