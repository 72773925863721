import React from 'react'
import Copyright from '../../../assets/icons/copyright icon.svg'
import FooterLogo from '../../../assets/icons/footerLogo.svg'
import MainContainer from '../MainContainer/MainContainer';

import styles from './Footer.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

const FooterComponent = () => {

  const location = useLocation(); 
  const navigate = useNavigate();
  const handlePrivacyPolicy = () => {
    navigate('/privacy-policy')
  }

  const handleOfferts = () => {
    navigate('/user-agreement')
  }
  return (
    <footer className={styles.footerBox}>
      <MainContainer>
        <div className={styles.fullWidth}>
          <div className={styles.footerLeftSideBox}>
            <img src={FooterLogo} />
            <div className={styles.footerLogoBox}>
              <img src={Copyright} className={styles.yearFooter}/>
              <p className={styles.yearFooter}>2024 profibroker</p>
            </div>
          </div>
          <div className={styles.footerRightBox}>
            <p onClick={handleOfferts} className={location?.pathname === "/user-agreement" ? styles.activeRoute: ''}>Оферта</p>
            <p onClick={handlePrivacyPolicy} className={location?.pathname === "/privacy-policy" ? styles.activeRoute: ''}>Политика конфиденциальности</p>
          </div>
        </div>
      </MainContainer>
    </footer>
  )
}

export default FooterComponent