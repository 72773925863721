import React, { useEffect, useState } from 'react';
import styles from './Counter.module.scss'; // Добавь свои стили

interface CounterProps {
    initialValue?: number;
    min?: number;
    max?: number;
    onChange?: (value: number) => void; // Проп для изменения значения
}

export const Counter: React.FC<CounterProps> = ({ initialValue = 0, min = 0, max = 10, onChange }) => {
    const [count, setCount] = useState(initialValue);

    // Обновляем count при изменении initialValue
    useEffect(() => {
        setCount(initialValue);
    }, [initialValue]);

    const handleIncrement = () => {
        if (count < max) {
            const newValue = count + 1;
            setCount(newValue);
            if (onChange) onChange(newValue); // Вызываем onChange при изменении
        }
    };

    const handleDecrement = () => {
        if (count > min) {
            const newValue = count - 1;
            setCount(newValue);
            if (onChange) onChange(newValue); // Вызываем onChange при изменении
        }
    };

    return (
        <div className={styles.counterContainer}>
            <button className={styles.decrementButton} onClick={handleDecrement} disabled={count === min}>
                -
            </button>
            <span className={styles.counterValue}>{count}</span>
            <button className={styles.incrementButton} onClick={handleIncrement} disabled={count === max}>
                +
            </button>
        </div>
    );
};

export default Counter;
