import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app/App';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import { logoutUser, setUser } from 'features/login/loginSlice';

// Подключаем событие `storage` для синхронизации состояния между вкладками
// window.addEventListener('storage', (event) => {
//   if (event.key === 'persist:root') {
//     // Получаем новое состояние из localStorage
//     const newPersistedState = JSON.parse(event.newValue || '{}');

//     // Обновляем состояние login в store (или любое другое)
//     store.dispatch({ type: 'LOGIN/SET_STATE', payload: newPersistedState.login });
//   }
// });

window.addEventListener('storage', (event) => {
  if (event.key === 'user') {
    const user = localStorage.getItem('user');
    if (user) {
      store.dispatch(setUser(JSON.parse(user))); // Обновляем состояние пользователя
    } else {
      store.dispatch(logoutUser()); // Выходим из системы, если данных нет
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
