import React from 'react'
import OffersList from 'components/Offers/ui/OffersList'
import MainAppLayout from 'app/layouts/MainAppLayout/MainAppLayout'

export default function OffersPage() {
    return (
        <MainAppLayout>
            <OffersList />
        </MainAppLayout>
    )
}
