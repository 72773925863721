import React, { useMemo, useState } from 'react';
import { useGetOfferStatisticsQuery } from 'services/api';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import LoadingOverlay from 'components/ui/LoadingOverlay/LoadingOverlay';
import { SingleSelectSearch } from 'components/ui/SingleSelectSearch/ui/SingleSelectSearch';
import { OptionType } from 'components/Offers/model/types';
import Table from 'components/ui/Table/ui/Table';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';
import FiltersIcon from 'assets/icons/FiltersIcon';
import { MobileFilter } from 'components/ui/MobileFilter';

import styles from './OffersMainList.module.scss';
import CustomCalendarSelect from 'components/ui/CustomCalendarSelect/ui/CustomCalendarSelect';

const OffersMainList = () => {
    const { data: offerStatistics = [], error, isLoading } = useGetOfferStatisticsQuery({ page: 1 });
    const isMobile = useMediaQuery(768);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOffer, setSelectedOffer] = useState<OptionType | null>(null);
    const [resetSelect, setResetSelect] = useState(false);
    // Функция для получения диапазона дат
    const getDefaultRange = (): [Date, Date] => {
        const today = new Date();
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(today.getDate() - 150);
        return [sevenDaysAgo, today];
    };

    const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultRange());
    const [resetDates, setResetDates] = useState(false);
    // Определяем колонки для таблицы
    const columns = useMemo(
        () => [
            {
                accessorKey: 'offerId',
                header: 'Оффер ID',
                cell: ({ row }: any) => <span>{row.original.offerId}</span>,
            },
            {
                accessorKey: 'offerName',
                header: 'Оффер',
                cell: ({ row }: any) => (
                    <div className={styles.offerCell}>
                        <div>
                            <div>{row.original.offerName}</div>
                            <div className={styles.subTitle}>{row.original.category}</div>
                        </div>
                    </div>
                ),
            },
            {
                accessorKey: 'totalApplications',
                header: 'Всего заявок',
                cell: ({ row }) => <span>{row.original.totalApplications}</span>,
            },
            {
                accessorKey: 'confirmedApplications',
                header: 'Подтвержденные заявки',
                cell: ({ row }) => <span>{row.original.confirmedApplications}</span>,
            },
        ],
        []
    );

    // Фильтрация данных
    const filteredOffers = useMemo(() => {
        let filtered = offerStatistics;

        if (searchTerm) {
            filtered = filtered.filter(
                (offer: any) =>
                    offer.offerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    offer.offerId.toString().includes(searchTerm)
            );
        }

        if (selectedOffer) {
            filtered = filtered.filter(
                (offer: any) => offer.offerId === selectedOffer?.value
            );
        }

        return filtered;
    }, [searchTerm, selectedOffer, offerStatistics]);

    const handleReset = () => {
        setSearchTerm('');
        setSelectedOffer(null);
        setResetSelect(true);
    };

    const handleOfferSelect = (offer: any) => {
        setSelectedOffer(offer);
        setResetSelect(false);
    };

    const handleDateChange = (range: [Date, Date]) => {
        setDateRange(range);
    };

    const renderCardView = () => (
        <div className={styles.cardsContainer}>
            {filteredOffers.map((offer: any) => (
                <div key={offer.offerId} className={styles.card}>
                    <div className={styles.cardRow}>
                        <div className={styles.cardContent}>
                            <TextUI variant='h2'>{offer.offerName}</TextUI>
                            <span>{offer.category}</span>
                        </div>
                    </div>
                    <ButtonUI
                        label='Подробнее'
                        variant='main2'
                        height='42px'
                        width='120px'
                    />
                </div>
            ))}
        </div>
    );

    if (isLoading) return <LoadingOverlay />;
    if (error) return <div>Ошибка при загрузке статистики по офферам</div>;

    return (
        <div className={styles.offersList}>
            <TextUI variant='h1' className={styles.marginBottom}>
                Топ офферов
            </TextUI>

            {isMobile && (
                <div className={styles.filterMenu} onClick={() => setIsModalOpen(true)}>
                    <FiltersIcon />
                </div>
            )}

            {isMobile && isModalOpen && (
                <MobileFilter
                    title='Параметры'
                    onClose={() => setIsModalOpen(false)}
                    onShow={() => setIsModalOpen(false)}
                    onReset={handleReset}
                >
                    MobileFilter
                </MobileFilter>
            )}

            {!isMobile && (
                <div className={styles.filters}>
                    <div className={styles.twoFilters}>
                        <CustomCalendarSelect
                            onDateChange={handleDateChange}
                            resetDates={resetDates}
                            dateRange={dateRange}
                            title='Дата заявки'
                        />
                        <SingleSelectSearch
                            options={offerStatistics.map((offer: any) => ({
                                label: offer.offerName,
                                value: offer.offerId,
                            }))}
                            onOptionSelect={handleOfferSelect}
                            placeholder='Все'
                            reset={resetSelect}
                            title='Оффер'
                        />
                    </div>

                    <div className={styles.filterButtons}>
                        <ButtonUI
                            label='Сбросить фильтр'
                            variant='main2'
                            height='42px'
                            onClick={handleReset}
                        />
                    </div>
                </div>
            )}

            {/* Отображаем таблицу */}
            {isMobile ? (
                renderCardView()
            ) : (
                <Table data={filteredOffers} columns={columns} />
            )}
        </div>
    );
};

export default OffersMainList;
