import { useState, useCallback } from 'react';

// Кастомный хук для отслеживания hover
const useHover = () => {
    const [isHovered, setIsHovered] = useState(false);

    const onMouseEnter = useCallback(() => setIsHovered(true), []);
    const onMouseLeave = useCallback(() => setIsHovered(false), []);

    return {
        isHovered,
        onMouseEnter,
        onMouseLeave,
    };
};

export default useHover;
