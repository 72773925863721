import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RequestCardLayout from 'app/layouts/RequestCardLayout/RequestCardLayout';
import { BottonSteps } from 'components/BottonSteps';
// import { useForm, FormProvider, UseFormReturn } from 'react-hook-form';
import ProgressSteps from 'components/ProgressSteps/ProgressSteps';
import {
    setCurrentStep,
    setSelectedBank,
    setSelectedProduct,
    savePersonalData,
    saveAdditionalData,
    setStatus
} from 'features/request/requestSlice';
import { useDispatch, useSelector } from 'react-redux';

import Step0 from 'components/RequestCard/Step0';
import Step1 from 'components/RequestCard/Step1';
import Step2 from 'components/RequestCard/Step2';
import Step3 from 'components/RequestCard/Step3';
import Step4 from 'components/RequestCard/Step4';
import Step5 from 'components/RequestCard/Step5';
import Step6 from 'components/RequestCard/Step6';
import Step7 from 'components/RequestCard/Step7';
import Step8 from 'components/RequestCard/Step8';
import Step9 from 'components/RequestCard/Step9';
import Step10 from 'components/RequestCard/Step10';

import styles from './RequestCardPage.module.scss';
import { useCreateCardRequestMutation, useUpdateRequestStepMutation, useGetCardRequestByIdQuery, useUpdateRequestStatusMutation } from 'services/api';


const RequestCardPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { data: { selectedBank = '', selectedProduct = '', personalData = {}, additionalData = {} } = {}, currentStep } = useSelector((state: any) => state.request);
    const creditAmount = useSelector((state: any) => state.request.data.creditAmount);

    const [step, setStep] = useState(0);
    const [requestId, setRequestId] = useState<string | null>(null);
    console.log("🚀 ~ requestId:", requestId)
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [isStep1Valid, setIsStep1Valid] = useState(false);
    const [isStep3Valid, setIsStep3Valid] = useState(false);
    const [isStep4Valid, setIsStep4Valid] = useState(false); // Для шага 4
    const [isStep5Valid, setIsStep5Valid] = useState(false); // Для шага 5
    const [photoFormSubmit, setPhotoFormSubmit] = useState<(() => void) | null>(null);

    const TOTAL_STEPS = 10; // Устанавливаем общее количество шагов
    const disableNextStepOff = false;

    const [createCardRequest] = useCreateCardRequestMutation();
    const [updateRequestStep] = useUpdateRequestStepMutation();

    // Подтягиваем данные с сервера при наличии requestId и изменении шага
    const { data: requestData, refetch } = useGetCardRequestByIdQuery(requestId, { skip: !requestId });
    // console.log("🚀 ~ requestData:", requestData)

    // Загружаем значение из localStorage при первой загрузке компонента
    useEffect(() => {
        const storedCheckboxState = localStorage.getItem('isCheckboxChecked');
        if (storedCheckboxState !== null) {
            setIsCheckboxChecked(JSON.parse(storedCheckboxState));
        }
    }, []);

    // Сохраняем значение в localStorage при каждом изменении isCheckboxChecked
    useEffect(() => {
        localStorage.setItem('isCheckboxChecked', JSON.stringify(isCheckboxChecked));
    }, [isCheckboxChecked]);

    // Когда данные с сервера приходят, обновляем Redux
    useEffect(() => {
        if (requestData) {
            dispatch(setSelectedBank(requestData.selectedBank));
            dispatch(setSelectedProduct(requestData.selectedProduct));
            dispatch(savePersonalData(requestData.personalData));
            dispatch(saveAdditionalData(requestData.additionalData));
        }
    }, [requestData, dispatch]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const urlStep = searchParams.get('step');
        if (urlStep) {
            const parsedStep = parseInt(urlStep, 10);
            if (!isNaN(parsedStep) && parsedStep >= 0 && parsedStep <= TOTAL_STEPS) {
                setStep(parsedStep);
            }
        }
    }, [location.search]);

    useEffect(() => {
        dispatch(setCurrentStep(step)); // Сохраняем текущий шаг в Redux
    }, [step, dispatch]);

    useEffect(() => {
        const savedRequestId = localStorage.getItem('requestId');
        if (savedRequestId) {
            setRequestId(savedRequestId);
        }
    }, []);

    // const handleResetRequest = () => {
    //     localStorage.removeItem('requestId');
    //     setRequestId(null);
    //     // Дополнительные действия по сбросу состояния заявки
    // };

    const updateUrl = (step: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('step', step.toString());
        navigate(`?${searchParams.toString()}`, { replace: true });
    };

    // const handleBankChange = (bank: string) => {
    //     dispatch(setSelectedBank(bank));
    // };

    // const handleProductChange = (product: string) => {
    //     dispatch(setSelectedProduct(product));
    // };

    const handleNextStep = async (formData?: any) => {
        // Пропускаем отправку данных на сервер для шага 4
        if (step === 4) {
            if (!requestId) {
                try {
                    // Создание новой заявки, если requestId отсутствует
                    const response = await createCardRequest(formData).unwrap();
                    setRequestId(response._id);
                    localStorage.setItem('requestId', response._id); // Сохраняем в localStorage
                } catch (error) {
                    console.error('Ошибка при создании заявки:', error);
                    return;
                }
            } else {
                try {
                    // Обновление существующей заявки на шаге 4
                    await updateRequestStep({ id: requestId, step, data: formData }).unwrap();
                    refetch();
                } catch (error) {
                    console.error('Ошибка при обновлении заявки на шаге 4:', error);
                    return;
                }
            }

            setStep((prevStep) => prevStep + 1);
            updateUrl(step + 1);
            return;
        }

        try {
            let stepData;

            switch (step) {
                case 0:
                    stepData = { selectedBank, selectedProduct };
                    break;
                case 1:
                    stepData = personalData;
                    break;
                case 2:
                    stepData = { creditAmount };
                    break;
                case 3:
                    stepData = additionalData;
                    break;
                default:
                    stepData = {};
                    break;
            }

            if (!requestId) {
                // Создание новой заявки, если requestId отсутствует
                const response = await createCardRequest(stepData).unwrap();
                setRequestId(response._id);
                localStorage.setItem('requestId', response._id); // Сохраняем в localStorage
            } else {
                // Обновление существующей заявки на определённом шаге
                await updateRequestStep({ id: requestId, step, data: stepData }).unwrap();
                refetch();
            }

            // Переход к следующему шагу
            setStep((prevStep) => prevStep + 1);
            updateUrl(step + 1);
        } catch (error) {
            console.error('Ошибка при сохранении шага:', error);
        }
    };

    const handlePrevStep = () => {
        if (step > 0) {
            setStep(step - 1);
            updateUrl(step - 1);
        }
    };

    return (
        <RequestCardLayout>
            <ProgressSteps
                currentStep={step}
                totalSteps={TOTAL_STEPS}
                onStepClick={(newStep) => {
                    setStep(newStep);
                    updateUrl(newStep);
                }}
            />

            <div className={styles.requestCard}>
                {step === 0 ? (
                    <Step0
                        selectedBank={selectedBank}
                        selectedProduct={selectedProduct}
                        onBankChange={(bank) => dispatch(setSelectedBank(bank))}
                        onProductChange={(product) => dispatch(setSelectedProduct(product))}
                    />
                ) : step === 1 ? (
                    <Step1
                        requestId={requestId} // Передаем requestId в Step1
                        setIsCheckboxChecked={setIsCheckboxChecked}
                        setIsFormValid={setIsStep1Valid}
                        onFormSubmit={() => { }}
                        defaultValues={requestData ? requestData.personalData : {}}
                    />
                ) : step === 2 ? (
                    <Step2 requestId={requestId} />
                ) : step === 3 ? (
                    <Step3 requestId={requestId} setIsFormValid={setIsStep3Valid} onFormSubmit={() => { }} />
                ) : step === 4 ? (
                    <Step4 requestId={requestId} setRequestId={setRequestId} setIsFormValid={setIsStep4Valid} onFormSubmit={() => { }} />
                ) : step === 5 ? (
                    <Step5 setIsFormValid={setIsStep4Valid} handleFormSubmit={() => { }} />
                ) : step === 6 ? (
                    <Step6 />
                ) : step === 7 ? (
                    <Step7 />
                ) : step === 8 ? (
                    <Step8 />
                ) : step === 9 ? (
                    <Step9 />
                ) : step === 10 ? (
                    <Step10 />
                ) : null}
            </div>

            <BottonSteps
                step={step}
                onNext={handleNextStep}
                onPrev={handlePrevStep}
                disableNext={
                    disableNextStepOff
                        ? false
                        : (step === 0 && (!selectedBank || !selectedProduct)) ||
                        (step === 1 && (!isStep1Valid || !isCheckboxChecked)) ||
                        (step === 3 && !isStep3Valid) ||
                        (step === 4 && !isStep4Valid) ||
                        (step === 5 && !isStep5Valid)
                }
            // disableNext={false}
            />
        </RequestCardLayout>
    );
};

export default RequestCardPage;