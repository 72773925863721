import React from 'react';
import classNames from 'classnames'; // Импортируем classnames
import styles from './TextUI.module.scss';
import { TextUIProps } from '../types/TextUI.types';

export const TextUI: React.FC<TextUIProps> = ({
    variant = 'bodyM',
    color = 'general',
    align = 'left',
    className = '', // Добавляем возможность передать кастомный класс
    children,
}) => {
    const textClass = classNames(
        styles[variant],
        styles[color],
        styles[align],
        styles.fullWidth,
        className // Объединяем кастомный класс с существующими
    );

    return <p className={textClass}>{children}</p>;
};
