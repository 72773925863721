import React from 'react'
import AuthLayout from 'app/layouts/AuthLayout/AuthLayout'
import RegistrationWithContactAgent from 'components/RegistrationWithContactAgent/RegistrationWithContactAgent'

export default function RegistrationWithContactAgentPage() {
    return (
        <AuthLayout>
            <RegistrationWithContactAgent />
        </AuthLayout>
    )
}

