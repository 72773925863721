import React from 'react';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';

const LogoIcon = () => {
  const isMobile = useMediaQuery(768)

  return (
    <svg width={isMobile ? '144' : '206'} height="40" viewBox="0 0 206 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0878906 31.2329V9.41475H8.26971C9.94585 9.41475 11.3521 9.72725 12.4885 10.3523C13.6319 10.9773 14.4949 11.8366 15.0772 12.9304C15.6667 14.017 15.9615 15.2528 15.9615 16.6378C15.9615 18.0369 15.6667 19.2798 15.0772 20.3665C14.4877 21.4531 13.6177 22.3089 12.4672 22.9339C11.3166 23.5518 9.89968 23.8608 8.21644 23.8608H2.79386V20.6115H7.68377C8.66389 20.6115 9.46644 20.441 10.0914 20.1001C10.7164 19.7592 11.1781 19.2905 11.4764 18.6939C11.7818 18.0973 11.9345 17.4119 11.9345 16.6378C11.9345 15.8636 11.7818 15.1818 11.4764 14.5923C11.1781 14.0028 10.7129 13.5447 10.0808 13.218C9.45579 12.8842 8.64968 12.7173 7.66246 12.7173H4.04031V31.2329H0.0878906Z" fill="#71BE81" />
      <path d="M19.3945 31.2329V9.41475H27.5763C29.2525 9.41475 30.6587 9.70594 31.7951 10.2883C32.9386 10.8707 33.8015 11.6875 34.3839 12.7386C34.9734 13.7826 35.2681 15.0007 35.2681 16.3927C35.2681 17.7919 34.9698 19.0064 34.3732 20.0362C33.7837 21.0589 32.9137 21.8508 31.7631 22.4119C30.6126 22.9659 29.1992 23.2429 27.5231 23.2429H21.6957V19.9616H26.9904C27.9705 19.9616 28.7731 19.8267 29.3981 19.5568C30.0231 19.2798 30.4847 18.8785 30.783 18.353C31.0884 17.8203 31.2411 17.1669 31.2411 16.3927C31.2411 15.6186 31.0884 14.9581 30.783 14.4112C30.4776 13.8572 30.0124 13.4382 29.3874 13.1541C28.7624 12.8629 27.9563 12.7173 26.9691 12.7173H23.3469V31.2329H19.3945ZM30.6658 21.3466L36.0671 31.2329H31.6566L26.3512 21.3466H30.6658Z" fill="#71BE81" />
      <path d="M58.402 20.3238C58.402 22.6747 57.9617 24.6882 57.081 26.3643C56.2074 28.0334 55.0142 29.3118 53.5014 30.1996C51.9957 31.0873 50.2876 31.5312 48.3771 31.5312C46.4666 31.5312 44.755 31.0873 43.2422 30.1996C41.7365 29.3047 40.5433 28.0227 39.6626 26.3537C38.7891 24.6775 38.3523 22.6676 38.3523 20.3238C38.3523 17.973 38.7891 15.963 39.6626 14.294C40.5433 12.6179 41.7365 11.3359 43.2422 10.4481C44.755 9.56035 46.4666 9.11646 48.3771 9.11646C50.2876 9.11646 51.9957 9.56035 53.5014 10.4481C55.0142 11.3359 56.2074 12.6179 57.081 14.294C57.9617 15.963 58.402 17.973 58.402 20.3238ZM54.4283 20.3238C54.4283 18.669 54.169 17.2734 53.6506 16.1371C53.1392 14.9936 52.429 14.1307 51.5199 13.5483C50.6108 12.9588 49.5632 12.664 48.3771 12.664C47.1911 12.664 46.1435 12.9588 45.2344 13.5483C44.3253 14.1307 43.6115 14.9936 43.093 16.1371C42.5817 17.2734 42.326 18.669 42.326 20.3238C42.326 21.9787 42.5817 23.3778 43.093 24.5213C43.6115 25.6576 44.3253 26.5206 45.2344 27.1101C46.1435 27.6924 47.1911 27.9836 48.3771 27.9836C49.5632 27.9836 50.6108 27.6924 51.5199 27.1101C52.429 26.5206 53.1392 25.6576 53.6506 24.5213C54.169 23.3778 54.4283 21.9787 54.4283 20.3238Z" fill="#71BE81" />
      <path d="M62.1387 31.2329V9.41475H76.1159V12.728H66.0911V18.6513H75.1571V21.9645H66.0911V31.2329H62.1387Z" fill="#71BE81" />
      <path d="M83.6692 9.41475V31.2329H79.7168V9.41475H83.6692Z" fill="#71BE81" />
      <path d="M87.9492 31.2329V9.41475H96.3015C97.8782 9.41475 99.1886 9.66333 100.233 10.1605C101.284 10.6505 102.069 11.3217 102.587 12.174C103.113 13.0263 103.375 13.9922 103.375 15.0717C103.375 15.9595 103.205 16.7194 102.864 17.3515C102.523 17.9765 102.065 18.4844 101.49 18.875C100.914 19.2656 100.272 19.5461 99.5614 19.7166V19.9297C100.336 19.9723 101.078 20.2102 101.788 20.6434C102.505 21.0696 103.091 21.6733 103.546 22.4545C104 23.2358 104.228 24.1804 104.228 25.2883C104.228 26.4176 103.954 27.4332 103.407 28.3352C102.86 29.2301 102.037 29.9368 100.936 30.4552C99.8349 30.9737 98.4499 31.2329 96.7809 31.2329H87.9492ZM91.9016 27.9304H96.1523C97.587 27.9304 98.6204 27.6569 99.2525 27.1101C99.8917 26.5561 100.211 25.8459 100.211 24.9794C100.211 24.3331 100.051 23.7507 99.7319 23.2322C99.4123 22.7067 98.9577 22.2947 98.3683 21.9964C97.7788 21.691 97.0756 21.5383 96.2589 21.5383H91.9016V27.9304ZM91.9016 18.6939H95.8114C96.4932 18.6939 97.1076 18.5696 97.6545 18.321C98.2013 18.0653 98.631 17.7067 98.9435 17.245C99.2631 16.7763 99.4229 16.2223 99.4229 15.5831C99.4229 14.7379 99.1246 14.0419 98.5281 13.495C97.9386 12.9481 97.0614 12.6747 95.8967 12.6747H91.9016V18.6939Z" fill="#2F2F33" />
      <path d="M107.666 31.2329V9.41475H115.848C117.524 9.41475 118.93 9.70594 120.067 10.2883C121.21 10.8707 122.073 11.6875 122.655 12.7386C123.245 13.7826 123.54 15.0007 123.54 16.3927C123.54 17.7919 123.241 19.0064 122.645 20.0362C122.055 21.0589 121.185 21.8508 120.035 22.4119C118.884 22.9659 117.471 23.2429 115.795 23.2429H109.967V19.9616H115.262C116.242 19.9616 117.045 19.8267 117.67 19.5568C118.295 19.2798 118.756 18.8785 119.055 18.353C119.36 17.8203 119.513 17.1669 119.513 16.3927C119.513 15.6186 119.36 14.9581 119.055 14.4112C118.749 13.8572 118.284 13.4382 117.659 13.1541C117.034 12.8629 116.228 12.7173 115.241 12.7173H111.618V31.2329H107.666ZM118.937 21.3466L124.339 31.2329H119.928L114.623 21.3466H118.937Z" fill="#2F2F33" />
      <path d="M146.673 20.3238C146.673 22.6747 146.233 24.6882 145.352 26.3643C144.479 28.0334 143.286 29.3118 141.773 30.1996C140.267 31.0873 138.559 31.5312 136.649 31.5312C134.738 31.5312 133.026 31.0873 131.514 30.1996C130.008 29.3047 128.815 28.0227 127.934 26.3537C127.061 24.6775 126.624 22.6676 126.624 20.3238C126.624 17.973 127.061 15.963 127.934 14.294C128.815 12.6179 130.008 11.3359 131.514 10.4481C133.026 9.56035 134.738 9.11646 136.649 9.11646C138.559 9.11646 140.267 9.56035 141.773 10.4481C143.286 11.3359 144.479 12.6179 145.352 14.294C146.233 15.963 146.673 17.973 146.673 20.3238ZM142.7 20.3238C142.7 18.669 142.441 17.2734 141.922 16.1371C141.411 14.9936 140.7 14.1307 139.791 13.5483C138.882 12.9588 137.835 12.664 136.649 12.664C135.463 12.664 134.415 12.9588 133.506 13.5483C132.597 14.1307 131.883 14.9936 131.365 16.1371C130.853 17.2734 130.597 18.669 130.597 20.3238C130.597 21.9787 130.853 23.3778 131.365 24.5213C131.883 25.6576 132.597 26.5206 133.506 27.1101C134.415 27.6924 135.463 27.9836 136.649 27.9836C137.835 27.9836 138.882 27.6924 139.791 27.1101C140.7 26.5206 141.411 25.6576 141.922 24.5213C142.441 23.3778 142.7 21.9787 142.7 20.3238Z" fill="#2F2F33" />
      <path d="M150.41 31.2329V9.41475H154.363V19.4396H154.629L163.141 9.41475H167.967L159.529 19.2052L168.042 31.2329H163.29L156.781 21.8792L154.363 24.7344V31.2329H150.41Z" fill="#2F2F33" />
      <path d="M170.713 31.2329V9.41475H184.903V12.728H174.665V18.6513H184.168V21.9645H174.665V27.9197H184.988V31.2329H170.713Z" fill="#2F2F33" />
      <path d="M188.936 31.2329V9.41475H197.117C198.794 9.41475 200.2 9.70594 201.336 10.2883C202.48 10.8707 203.343 11.6875 203.925 12.7386C204.514 13.7826 204.809 15.0007 204.809 16.3927C204.809 17.7919 204.511 19.0064 203.914 20.0362C203.325 21.0589 202.455 21.8508 201.304 22.4119C200.154 22.9659 198.74 23.2429 197.064 23.2429H191.237V19.9616H196.531C197.512 19.9616 198.314 19.8267 198.939 19.5568C199.564 19.2798 200.026 18.8785 200.324 18.353C200.629 17.8203 200.782 17.1669 200.782 16.3927C200.782 15.6186 200.629 14.9581 200.324 14.4112C200.019 13.8572 199.553 13.4382 198.928 13.1541C198.303 12.8629 197.497 12.7173 196.51 12.7173H192.888V31.2329H188.936ZM200.207 21.3466L205.608 31.2329H201.198L195.892 21.3466H200.207Z" fill="#2F2F33" />
    </svg>
  );
};

export default LogoIcon;
