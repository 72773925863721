import React, { useMemo, useState, useEffect } from 'react';
import MainAppLayout from 'app/layouts/MainAppLayout/MainAppLayout';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import Table from 'components/ui/Table/ui/Table';
import CustomCalendarSelect from 'components/ui/CustomCalendarSelect/ui/CustomCalendarSelect';
import { SingleSelectSearch } from 'components/ui/SingleSelectSearch/ui/SingleSelectSearch';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { OptionType } from 'components/Offers/model/types';
import { Offer, StatisticItem } from 'shared/models';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery'; // Для определения мобильной версии
import FiltersIcon from 'assets/icons/FiltersIcon'; // Иконка фильтров
import { MobileFilter } from 'components/ui/MobileFilter';

import styles from './DailyStatistic.module.scss';
import { useFetchDailyStatisticQuery, useFetchOffersQuery } from 'services/api';

interface Category {
  id: string;
  title: string;
}

const getDefaultRange = (): [Date, Date] => {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1); 
  return [startOfYear, today];
};

const DailyStatistic = () => {
  // const [filteredData, setFilteredData] = useState<StatisticItem[]>(data);
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultRange()); // Устанавливаем начальный диапазон
  const [selectedOffer, setSelectedOffer] = useState<number | null>(null);
  const [resetSelect, setResetSelect] = useState<boolean>(false);
  const [resetDates, setResetDates] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для модального окна
  const isMobile = useMediaQuery(768); // Определяем, используется ли мобильная версия

  const { data: statistics = [], error, isLoading } = useFetchDailyStatisticQuery({ page: 1 });
  const { data: offers = [], error: offersError, isLoading: isOffersLoading } = useFetchOffersQuery({ page: 1 });

  const [filteredData, setFilteredData] = useState<StatisticItem[]>(statistics);

  // Фильтруем только нужные офферы (например, по категориям "Кредитная карта" и "Дебетовая карта")
  const filteredOffers = useMemo(() => {
    return offers.filter((offer: Offer) =>  // Явно указываем тип для 'offer'
      offer.full_categories.some((category: Category) =>
        ['Кредитная карта', 'Дебетовая карта'].includes(category.title)
      )
    );
  }, [offers]);

  const offerOptions = useMemo(() => {
    // Используем Map для фильтрации уникальных офферов по ID
    const uniqueOffersMap = new Map<number, Offer>();

    filteredOffers.forEach((offer: Offer) => {
      if (!uniqueOffersMap.has(offer.id)) {
        uniqueOffersMap.set(offer.id, offer);
      }
    });

    const uniqueOffers = Array.from(uniqueOffersMap.values());

    return uniqueOffers.map(offer => ({
      label: offer.title ?? 'Неизвестный оффер', // Убедитесь, что 'label' всегда строка
      value: offer.id,
    }));
  }, [filteredOffers]);

  useEffect(() => {
    let filtered = statistics;

    if (dateRange) {
      const [startDate, endDate] = dateRange;
      filtered = filtered.filter((item: StatisticItem) => {
        const itemDate = new Date(item.date);
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    if (selectedOffer !== null) {
      filtered = filtered.filter((item: StatisticItem) =>
        item.offers.some(offer => offer.id === selectedOffer)
      );
    }

    setFilteredData(filtered);
  }, [dateRange, selectedOffer, statistics]);

  const columns = useMemo(() => [
    {
      accessorKey: 'date',
      header: 'Дата',
    },
    {
      accessorKey: 'all',
      header: 'Все',
    },
    {
      accessorKey: 'approved',
      header: 'Принято',
    },
    {
      accessorKey: 'inProgress',
      header: 'В работе',
    },
    {
      accessorKey: 'rejected',
      header: 'Отклонено',
    },
  ], []);

  const handleReset = () => {
    setSelectedOffer(null);
    setResetSelect(true);
    setDateRange(getDefaultRange());
    setFilteredData(statistics);

    setTimeout(() => setResetDates(false), 100);
  };

  // const handleConfirm = () => {
  //   if (dateRange) {
  //     console.log("🚀 ~ handleConfirm ~ ")
  //     setFilteredData(prevData => {
  //       let filtered = data;

  //       const [startDate, endDate] = dateRange;
  //       filtered = filtered.filter(item => {
  //         const itemDate = new Date(item.date);
  //         return itemDate >= startDate && itemDate <= endDate;
  //       });

  //       return filtered;
  //     });
  //   }
  // };

  useEffect(() => {
    const [startDate, endDate] = getDefaultRange();
    setDateRange([startDate, endDate]);
  }, []);

  const handleDateChange = (range: [Date, Date]) => {
    setDateRange(range);
  };

  const handleOfferSelect = (selected: OptionType | null) => {
    if (selected) {
      setSelectedOffer(typeof selected.value === 'string' ? parseInt(selected.value, 10) : selected.value);
    } else {
      setSelectedOffer(null);
    }
    setResetSelect(false);
  };

  return (
    <MainAppLayout>
      <TextUI variant="h1" className={styles.marginBottom}>Cтатистика по дням</TextUI>

      {isMobile ? (
        <>
          {/* Иконка для открытия фильтров на мобильной версии */}
          <div className={styles.filterMenu} onClick={() => setIsModalOpen(true)}>
            <FiltersIcon />
          </div>

          <div className={styles.selectWrapper}>
            <SingleSelectSearch
              options={offerOptions}
              onOptionSelect={handleOfferSelect}
              placeholder="Поиск по офферу"
              reset={resetSelect}
              title="Поиск"
            />
          </div>

          {/* Модалка для фильтров на мобильной версии */}
          {isModalOpen && (
            <MobileFilter
              title="Параметры"
              onClose={() => setIsModalOpen(false)}
              onShow={() => setIsModalOpen(false)}
              onReset={handleReset}
              className={styles.offerMobileFilter}
              filterBtnClassName={styles.offerBtnMobileFilter}
            >
              <CustomCalendarSelect onDateChange={handleDateChange} resetDates={resetDates} dateRange={dateRange} />
            </MobileFilter>
          )}
        </>
      ) : (
        <div className={styles.filters}>
          <div className={styles.twoFilters}>
            <SingleSelectSearch
              options={offerOptions}
              onOptionSelect={handleOfferSelect}
              placeholder="Поиск по офферу"
              reset={resetSelect}
            />
            <CustomCalendarSelect onDateChange={handleDateChange} resetDates={resetDates} dateRange={dateRange} />
          </div>

          <div className={styles.filterButtonShow}>
            {/* <ButtonUI label="Показать" variant="main" height='42px' onClick={handleConfirm} /> */}
            <ButtonUI label="Показать" variant="main2" width='108px' height='42px' />
          </div>

          <div className={styles.filterButtons}>
            {/* <ButtonUI label="Показать" variant="main" height='42px' onClick={handleConfirm} /> */}
            <ButtonUI label="Сбросить фильтр" variant="main2" height='42px' onClick={handleReset} />
          </div>
        </div>
      )}

      <div className={styles.statistics}>
        <Table columns={columns} data={filteredData} showTitleRow tablePageSize={Number(12)} />
      </div>
    </MainAppLayout>
  );
};

export default DailyStatistic;
