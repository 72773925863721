import React, { ReactNode, useMemo } from 'react';
import { Controller, useFormContext, Validate } from 'react-hook-form';
import { InputTypeEnum } from '../../shared/models';
import { InputUI } from '../ui/InputUI/InputUI';
import styles from './CustomInput.module.scss';

type InputProps = {
  name: string;
  type?: InputTypeEnum;
  placeholder?: string;
  iconright?: ReactNode;
  leftJoin?: boolean;
  rightJoin?: boolean;
  onBlur?: () => void;
  validate?: Validate<string, Record<string, any>>;
  serverError?: string | null;
  hideIcon?: boolean;
  description?: string;
  required?: boolean; // Добавим сюда для всех обязательных полей
  onInput?: any; // Add onInput prop
};

export const CustomInput: React.FC<InputProps> = ({
  name,
  type = InputTypeEnum.Text,
  placeholder,
  validate,
  serverError,
  hideIcon = false,
  description,
  required = false, // Добавляем значение по умолчанию
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const getErrorMessage = (error: any) => {
    if (error && typeof error.message === 'string') {
      return error.message;
    }
    return null;
  };

  const typeParam = useMemo(() => {
    let rules: any = {};

    if (required) {
      rules.required = 'Обязательное поле';
    }

    if (type === InputTypeEnum.Email) {
      rules = {
        ...rules,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: 'Неверный формат',
        },
        required: 'Email обязательное поле',
      };
    }

    if (type === InputTypeEnum.Password) {
      rules = {
        ...rules,
        minLength: {
          value: 8,
          message: 'Пароль должен содержать не менее 8 символов.',
        },
      };
    }

    if (type === InputTypeEnum.Tel) {
      rules = {
        ...rules,
        pattern: {
          value: /^\+79\d{9}$/,
          message: 'Телефонный номер должен начинаться с +79 и содержать 11 цифр',
        },
        validate: (value: string) => {
          const cleanedValue = value.replace(/\s+/g, '');
          return /^\+79\d{9}$/.test(cleanedValue) || 'Телефонный номер должен начинаться с +79 и содержать 11 цифр';
        },
      };
    }

    if (type === InputTypeEnum.Number) {
      rules = {
        ...rules,
        pattern: {
          value: /^[0-9]+$/i,
          message: 'Значение должно быть числом',
        },
      };
    }

    if (validate) {
      rules.validate = validate;
    }

    return { rules };
  }, [type, name, validate, required]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={typeParam.rules}
      render={({ field }) => (
        <div className={styles.inputWrapper}>
          <InputUI
            id={name}
            name={name}
            field={{
              ...field,
              value: type === InputTypeEnum.Tel
                ? field.value.replace(/\s+/g, '')
                : type === InputTypeEnum.Number
                  ? Number(field.value)
                  : field.value,
            }}
            type={type}
            error={getErrorMessage(errors[name]) || getErrorMessage(errors.server) || serverError}
            placeholder={placeholder ? placeholder : ''}
            onBlur={field.onBlur}
            hideIcon={hideIcon}
          />
          {description && <p className={styles.inputDescription}>{description}</p>}
        </div>
      )}
    />
  );
};
