import React from 'react';

const EyeOpenIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M11.9998 4.5C7.8413 4.5 4.18772 6.65132 2.08046 9.9011C1.25378 11.176 1.25378 12.824 2.08046 14.0989C4.18772 17.3487 7.8413 19.5 11.9998 19.5C16.1584 19.5 19.812 17.3487 21.9192 14.0989C22.7459 12.824 22.7459 11.176 21.9192 9.9011C19.812 6.65132 16.1584 4.5 11.9998 4.5ZM11.9998 17C9.23985 17 6.99985 14.76 6.99985 12C6.99985 9.24 9.23985 7 11.9998 7C14.7598 7 16.9998 9.24 16.9998 12C16.9998 14.76 14.7598 17 11.9998 17ZM11.9998 9C10.3398 9 8.99985 10.34 8.99985 12C8.99985 13.66 10.3398 15 11.9998 15C13.6598 15 14.9998 13.66 14.9998 12C14.9998 10.34 13.6598 9 11.9998 9Z'
        fill='#2F2F33'
      />
    </svg>
  );
};

export default EyeOpenIcon;
