// src/shared/layouts/AuthLayout/AuthLayout.tsx
import React, { ReactNode } from 'react';
import styles from './AuthLayout.module.scss';
import LogoIcon from 'assets/icons/LogoIcon';

interface AuthLayoutProps {
    children: ReactNode;
}

export default function AuthLayout({ children }: AuthLayoutProps) {

    return (
        <div className={styles.wrapper}>
            <div className={styles.leftSide}>
                <LogoIcon />
                <div className={styles.formWrapper}>
                    {children}
                </div>
            </div>
            <div className={styles.rightSide}></div>
        </div>
    );
}
