import React from 'react';

interface IconProps {
    active?: boolean; // Необязательный параметр
}

const HomeIcon: React.FC<IconProps> = ({ active }) => {
    const fillColor = active ? '#71BE81' : '#AAAABB'; // Цвет в зависимости от параметра active

    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.0396 13.973C20.6264 14.2294 21.3385 14.1175 21.8198 13.6364C22.2352 13.2173 22.3736 12.6243 22.2352 12.0896C22.1692 11.8236 22.0308 11.572 21.8198 11.3635L13.1363 2.68494C12.5099 2.05733 11.4945 2.05733 10.8681 2.68494L2.18462 11.3636C1.55825 11.9912 1.55825 13.0088 2.18462 13.6364C2.66594 14.1175 3.37803 14.2294 3.96484 13.973V20.3749C3.96484 21.7063 5.04616 22.7857 6.37803 22.7857H17.6264C18.9583 22.7857 20.0396 21.7064 20.0396 20.3749V13.973ZM11.0039 13.973H13.0039C13.2808 13.973 13.5248 14.1122 13.6698 14.3244C13.7556 14.4531 13.8083 14.6088 13.8083 14.7765V18.6963C13.8083 19.1401 13.4523 19.5 13.0039 19.5H11.0039C10.5621 19.5 10.1995 19.1401 10.1995 18.6963V14.7765C10.1995 14.3327 10.5621 13.973 11.0039 13.973Z"
                fill={fillColor} // Устанавливаем цвет в зависимости от active
            />
        </svg>
    );
};

export default HomeIcon;
