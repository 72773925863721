import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ApproveRegistrationCode.module.scss';
import TelegramIcon from 'assets/icons/TelegramIcon';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { useApproveRegistrationCodeMutation, useRepeatConfirmCodeMutation } from 'services/api';
import LoadingOverlay from 'components/ui/LoadingOverlay/LoadingOverlay';

interface ConfirmationCodeData {
  confirmationCode: string;
}

const ApproveRegistrationCode = () => {
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const [email, setEmail] = useState('');

  const [approvRegistrationCode, { isLoading, error: approveError }] = useApproveRegistrationCodeMutation();
  const [repeatConfirmCode, { isLoading: isRepeatLoading }] = useRepeatConfirmCodeMutation();

  const [code, setCode] = useState(new Array(6).fill(''));

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, code.length);
  }, [code]);

  const handleRepeatCode = async () => {
    try {
      await repeatConfirmCode(email).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const handleBack = () => {
    navigate('/login')
  }

  const handleChange = async (index: any, value: any) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value.length === 1 && index < code.length - 1) {
      const nextInput: any = inputRefs.current[index + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }

    if (newCode.every((val) => val.length === 1)) {
      const confirmationCode = newCode.join('');
      const userData: ConfirmationCodeData = { confirmationCode };
      try {
        const result = await approvRegistrationCode(userData).unwrap();

        if (result?.status === 'inactive') {
          navigate('/registration-with-contact-agent');
        } else {
          navigate('/registration-done');
        }
      } catch (error: any) {
        console.error('Error:', error);
      }
    }
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    const pasteArray = paste.slice(0, 6).split('');

    const newCode = [...code];
    for (let i = 0; i < pasteArray.length; i++) {
      newCode[i] = pasteArray[i];
      if (inputRefs.current[i]) {
        // @ts-ignore
        inputRefs.current[i].value = pasteArray[i];
      }
    }
    setCode(newCode);

    if (pasteArray.length === 6) {
      const confirmationCode = newCode.join('');

      approvRegistrationCode({ confirmationCode })
        .unwrap()
        .then((result: any) => {
          if (result?.status === 'inactive') {
            navigate('/registration-with-contact-agent');
          } else {
            navigate('/registration-done');
          }
        })
        .catch((error: any) => {
          console.error('Error:', error);
        });
    } else {
      const nextInput = inputRefs.current[pasteArray.length];
      if (nextInput) {
        // @ts-ignore
        nextInput.focus();
      }
    }
  };

  const handleKeyDown = (e: any, index: any) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      if (e.key === 'Backspace' && e.target.value === '' && index > 0) {
        // @ts-ignore
        inputRefs.current[index - 1].focus();
      } else if (
        e.key === 'Delete' &&
        e.target.value === '' &&
        index < code.length - 1
      ) {
        // @ts-ignore
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const hasError = !!approveError; // определяем, есть ли ошибка

  return (
    <div className={styles.approveRegistration}>
      {(isLoading || isRepeatLoading) && <LoadingOverlay />}
      <div className={styles.approveRegistrationBox}>
        <div className={styles.approveRegistrationBoxContainer}>
          <TelegramIcon />
          <TextUI variant="bodyL" align="center"> На Вашу электронную почту отправлено письмо, введите шестизначный код.</TextUI>
          <div className={styles.approveRegistrationInputSection}>
            {code.map((_, index) => (
              <input
                // className={styles.approveRegistrationInput}
                className={`${styles.approveRegistrationInput} ${hasError ? styles.error : ''}`} // Применяем стиль ошибки при наличии ошибки
                key={index}
                type='text'
                name={`code${index + 1}`}
                // @ts-ignore
                maxLength='1'
                value={code[index]}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                // @ts-ignore
                ref={(el) => (inputRefs.current[index] = el)}
                required
              />
            ))}
          </div>
          {approveError && (
            <TextUI variant='bodyS' color='error'>
              Некорректный код
            </TextUI>
          )}
          <ButtonUI label={'Вернуться к регистрации'} variant={'main2'} onClick={handleBack} fullWidth />
          <div>
            <TextUI variant='bodyS' color='secondary' align='center'>
              Не пришел код?
            </TextUI>
            <ButtonUI label={'Отправить код еще раз'} variant={'link'} onClick={handleRepeatCode} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveRegistrationCode;
