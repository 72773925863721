import React, { forwardRef, useEffect, useRef, useState } from 'react';
import profiArrow from '../../../assets/icons/profi-arrow.svg';
import LogoIcon from 'assets/icons/LogoIcon';
import ArrowGray from '../../../assets/icons/arrow-gray.svg';
import BurgerMenuIcon from 'assets/icons/BurgerMenuIcon'; // Импорт иконки бургер-меню
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from 'features/login/loginSlice';
import MainContainer from '../MainContainer/MainContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextUI } from '../TextUI/ui/TextUI';
import HeaderSettingsIcon from 'assets/icons/HeaderSettingsIcon';
// import OffersIcon from '../../../assets/icons/offers_icon.svg';
import OffertsIcon from '../../../assets/icons/offerts_icon.svg';
import StatisticsIcon from '../../../assets/icons/statistics_icon.svg';
// import useHover from 'shared/hooks/useHover';
import LogoutIcon from 'assets/icons/LogoutIcon';
import { apiSlice } from 'services/api';
import { MenuItem } from 'components/MenuItem';

import styles from './MainHeader.module.scss';
import OffersIcon from 'assets/icons/OffersIcon';

interface SettingsModalProps {
  handleLogout: () => void;
  className?: any
}

const SettingsModal = forwardRef<HTMLDivElement, SettingsModalProps>(({ handleLogout, className }, ref) => {
  const navigate = useNavigate();
  const location = useLocation();

  // const { isHovered, onMouseEnter, onMouseLeave } = useHover();

  const toSettings = () => {
    navigate('/settings');
  };

  return (
    <div ref={ref} className={`${styles.settingsModal} ${className}`}> {/* Реф передается здесь */}

      <MenuItem
        icon={<HeaderSettingsIcon />}
        text="Настройки"
        onClick={toSettings}
        hoveredColor="#71BE81"
        defaultColor="#AAAABB"
        isActive={location.pathname === '/settings'}
      />
      <MenuItem
        icon={<LogoutIcon />}
        text="ВЫЙТИ"
        onClick={handleLogout}
        hoveredColor="#71BE81"
        defaultColor="#AAAABB"
      />
    </div>
  );
});

const MainHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Отдельные состояния для мобильной и десктопной версий
  const [showDesktopSettings, setShowDesktopSettings] = useState(false);
  const [showMobileSettings, setShowMobileSettings] = useState(false);
  // console.log("🚀 ~ MainHeader ~ showMobileSettings:", showMobileSettings)
  const [showMobileVersionSettings, setShowMobileVersionSettings] = useState(false);

  const user = useSelector((state: any) => state.login?.user);

  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowDesktopSettings(false); // Закрываем десктопное меню
        setShowMobileSettings(false);  // Закрываем мобильное меню
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Обработчик для десктопа
  const handleOpenDesktopModal = () => {
    setShowDesktopSettings(!showDesktopSettings);
  };

  const handleOpenMobileModal = () => {
    setShowMobileVersionSettings(prevState => {
      // console.log("🚀 ~ handleOpenMobileModal ~ prevState:", prevState);
      return !prevState; // Переключение между true и false
    });
  };
  const navigate = useNavigate();

  const isAuth = useSelector((state: any) => state.login?.user?.isAuth);


  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(apiSlice.util.resetApiState());
    navigate('/login')
  };

  const handleOffer = () => {
    navigate('/offers')
  }

  const handleDailyStatistic = () => {
    navigate('/daily-statistic')
  }

  const handleStatisticByOffers = () => {
    navigate('/statistic-by-offers')
  }


  const handleApplications = () => {
    navigate('/applications')
  }

  const handleSettings = () => {
    navigate('/applications')
  }

  return (
    <header className={styles.mainHeader}>
      <MainContainer>
        <div className={styles.fullWidth}>
          <LogoIcon />

          {/* Бургер-меню для мобильных устройств */}
          <div className={styles.mobileMenu} onClick={handleOpenMobileModal}>
            {isAuth && <BurgerMenuIcon />}
          </div>

          {showMobileVersionSettings && <div className={`${styles.mobileVwerisonSettingsModal}`}> {/* Реф передается здесь */}
            <div className={styles.settingsItem} onClick={handleOffer} >
              <div className={styles.iconWrapper}>
                {/* <img src={OffersIcon} /> */}
                <OffersIcon active={location?.pathname === "/offers" ? true : false} />

              </div>
              <TextUI className={location?.pathname === "/offers" ? styles.activeRoute : ''} variant="menuMain" align="left">офферы</TextUI>
            </div>
            <div className={styles.settingsItem}  >
              <div className={styles.iconWrapper}>
                <img src={StatisticsIcon} />
              </div>
              <TextUI variant="menuMain" align="left">Статистика</TextUI>
            </div>
            <div className={styles.headerStatisticBox}>
              <div className={styles.verticalLine}></div>
              <ul>
                <li onClick={handleDailyStatistic}>по дням</li>
                <li onClick={handleStatisticByOffers}>по офферм</li>
              </ul>
            </div>

            <div className={styles.settingsItem} onClick={handleApplications} >
              <div className={styles.iconWrapper}>
                <img src={OffertsIcon} />
              </div>
              <TextUI className={location?.pathname === "/applications" ? styles.activeRoute : ''} variant="menuMain" align="left">заявки</TextUI>
            </div>

            <div className={styles.settingsItem} onClick={handleOffer} >
              {/* <img src={HeaderSettings} /> */}
              <div className={styles.iconWrapper}>
                <HeaderSettingsIcon />
              </div>
              <TextUI className={location?.pathname === "/settings" ? styles.activeRoute : ''} variant="menuMain" align="left">Настройки</TextUI>
            </div>

            <div className={styles.settingsItem} onClick={handleLogout}>
              {/* <img src={Logout} /> */}
              <LogoutIcon />
              <p>Выйти</p>
            </div>
          </div>}

          {/* Десктопное меню */}
          {
            isAuth && <div className={`${styles.headerRightSide} ${showDesktopSettings ? styles.openModal : ''} ${styles.desktopMenu}`}>
              <div className={styles.headerRightSideLeft}>
                <p>{user?.email}</p>
                <p className={styles.idText}>id{user?.userId}</p>
              </div>
              {!showDesktopSettings && <img src={profiArrow} onClick={handleOpenDesktopModal} className={styles.arrow} />}
              {showDesktopSettings && <img src={ArrowGray} onClick={handleOpenDesktopModal} className={styles.arrow} />}

              {showDesktopSettings && (
                <SettingsModal ref={menuRef} handleLogout={handleLogout} />
              )}
            </div>
          }
        </div>
      </MainContainer>

      {showMobileSettings && (
        <SettingsModal handleLogout={handleLogout} className={styles.mobileSettingsModal} ref={menuRef} />
      )}
    </header>
  );
};

export default MainHeader;
