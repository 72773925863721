import React from 'react'
import MainContainer from 'components/ui/MainContainer/MainContainer'
import MainHeader from 'components/ui/MainHeader/MainHeader'
import RouterComponent from 'components/ui/Router/Router'
import FooterComponent from 'components/ui/Footer/Footer'
// import styles from './MainAppLayout.module.scss';
import { useSelector } from 'react-redux'

const MainAppLayout = ({ children }: any) => {
  const isAuth = useSelector((state: any) => state.login?.user?.isAuth);
  return (
    <>
      <MainHeader />
      {isAuth && <RouterComponent />}
      <MainContainer>
        {children}
      </MainContainer>
      <FooterComponent />
    </>
  )
}

export default MainAppLayout