import React, { useState } from 'react';
import styles from './SliderSwitch.module.scss';

const SliderSwitch: React.FC = () => {
    const [selectedOption, setSelectedOption] = useState('application');

    const handleOptionChange = (option: string) => {
        setSelectedOption(option);
    };

    return (
        <div className={styles.sliderContainer}>
            <div
                className={`${styles.option} ${selectedOption === 'application' ? styles.active : ''}`}
                onClick={() => handleOptionChange('application')}
            >
                ЗАЯВКА
            </div>
            <div
                className={`${styles.option} ${selectedOption === 'training' ? styles.active : ''}`}
                onClick={() => handleOptionChange('training')}
            >
                ОБУЧЕНИЕ
            </div>
        </div>
    );
};

export default SliderSwitch;
