import React from 'react';
// import { PersonalDataForm } from 'components/Forms/PersonalDataForm';
// import { useDispatch } from 'react-redux';
// import { savePersonalData } from 'features/request/requestSlice';

import styles from './Step7.module.scss';
import { EanCardCheckForm } from 'components/EanCardCheckForm';

// interface Step7Props {
//     setIsFormValid: (isValid: boolean) => void; // Проп для управления состоянием валидности формы
//     onFormSubmit: (data: any) => void; // Проп для обработки отправки данных формы
// }

const Step7: React.FC = ({ }) => {
    // const dispatch = useDispatch();

    const handleFormSubmit = (data: any) => {
        console.log("🚀 ~ handleFormSubmit ~ data:", data);
        // Сохраняем данные в Redux при отправке формы
        // dispatch(savePersonalData(data));
    };

    return (
        <div className={styles.step7}>
            <EanCardCheckForm onSubmit={handleFormSubmit} />
        </div>
    );
};

export default Step7;
