import React, { useState, useEffect } from 'react';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import { setCreditAmount } from 'features/request/requestSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCardRequestByIdQuery, useUpdateRequestStepMutation } from 'services/api'; // Импортируем мутацию для обновления

import styles from './CreditSlider.module.scss';

interface CreditSliderProps {
    requestId: string | null;
}

export const CreditSlider: React.FC<CreditSliderProps> = ({ requestId }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState<number>(10000);

    const creditAmount = useSelector((state: any) => state.request.data.creditAmount);

    // const [updateRequestStep] = useUpdateRequestStepMutation(); // Мутация для обновления данных на сервере
    const { data: requestData } = useGetCardRequestByIdQuery(requestId, { skip: !requestId });
    // console.log("🚀 ~ requestData:", requestData)

    useEffect(() => {
        if (creditAmount !== undefined) {
            setValue(creditAmount);
        }
    }, [creditAmount]);

    useEffect(() => {
        if (requestData && requestData.creditAmount !== undefined) {
            setValue(requestData.creditAmount);
            dispatch(setCreditAmount(requestData.creditAmount)); // Обновляем Redux при изменении данных с сервера
        }
    }, [requestData, dispatch]);


    const handleSliderChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(event.target.value);
        // console.log("🚀 ~ handleSliderChange ~ newValue:", newValue)
        setValue(newValue);
        dispatch(setCreditAmount(newValue)); // Обновляем Redux состояние
    };

    const formatCurrency = (num: number) => {
        return num.toLocaleString('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            minimumFractionDigits: 0
        });
    };

    return (
        <CardWrapper title="Настройте Вашу карту">
            <div className={styles.creditAmount}>
                <div>Запрашиваемая сумма кредита:</div>
                <div className={styles.value}>{formatCurrency(value)}</div>
            </div>
            <input
                type="range"
                min="10000"
                max="510000"
                step="10000"
                value={value}
                onChange={handleSliderChange}
                className={styles.slider}
            />
            <div className={styles.markers}>
                <span>10 ТЫС.</span>
                <span>120 ТЫС.</span>
                <span>280 ТЫС.</span>
                <span>510 ТЫС.</span>
            </div>
        </CardWrapper>
    );
};
