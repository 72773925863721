import React from 'react';
import styles from './RadioButton.module.scss';

interface RadioButtonProps {
    name: string;
    label: string;
    value: string;
    checked: boolean;
    onChange: () => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({ name, label, value, checked, onChange }) => {
    return (
        <label className={styles.radioLabel}>
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                className={styles.radioInput}
            />
            <span className={styles.customRadio}></span>
            {label}
        </label>
    );
};

export default RadioButton;
