import React from 'react';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import ArrowLeft from 'assets/icons/ArrowLeft';
import styles from './MobileFilter.module.scss';

interface MobileFilterProps {
    onClose: () => void;
    children: React.ReactNode;
    onShow: () => void;
    onReset: () => void;
    title: string;
    className?: string; // Add this line to accept an optional className prop
    filterBtnClassName?: string;
}

const MobileFilter: React.FC<MobileFilterProps> = ({ onClose, children, onShow, onReset, title, className, filterBtnClassName  }) => {
    return (
        <div className={styles.modalBackdrop} onClick={onClose}>
            <div className={styles.modalWrapper} onClick={(e) => e.stopPropagation()}>
                <div className={styles.lineWrapper}>
                    <div className={styles.arrowLeft} onClick={onClose}>
                        <ArrowLeft />
                    </div>
                    <h1 className={styles.title}>{title}</h1>
                </div>

                {/* Фильтры, переданные в children */}
                <div className={styles.filterContent}>{children}</div>

                <div className={`${styles.filterButtons} ${className ? className : ''} `}>
                    <ButtonUI label="Показать" variant="main" onClick={onShow} />
                    <ButtonUI label="Сбросить фильтр" className={` ${filterBtnClassName ? filterBtnClassName : ''} `} variant="main2" onClick={onReset} />
                </div>
            </div>
        </div>
    );
};

export default MobileFilter;
