import React from 'react'
import AuthLayout from 'app/layouts/AuthLayout/AuthLayout'
import AuthorizeWithContactAgent from 'components/AuthorizeWithContactAgent/AuthorizeWithContactAgent'

export default function AuthorizeWithContactAgentPage() {
    return (
        <AuthLayout>
            <AuthorizeWithContactAgent />
        </AuthLayout>
    )
}