import React from 'react';

interface IconProps {
    active?: boolean; // Необязательный параметр
}

const OffersIcon: React.FC<IconProps> = ({ active }) => {
    const fillColor = active ? '#71BE81' : '#AAAABB'; // Цвет в зависимости от параметра active

    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.60156 11.144C9.60156 9.40761 11.0092 8 12.7456 8H19.3204C21.0568 8 22.4644 9.40762 22.4644 11.144V23.0396C22.4644 24.2759 21.0839 25.0108 20.0583 24.3205L18.5911 23.3329L17.2717 24.3539C16.5423 24.9184 15.5236 24.9184 14.7943 24.3539L13.4749 23.3329L12.0077 24.3205C10.9821 25.0108 9.60156 24.2759 9.60156 23.0396V11.144ZM12.7456 9.488C11.831 9.488 11.0896 10.2294 11.0896 11.144V23.0396C11.0896 23.0576 11.0936 23.0649 11.096 23.0686C11.0997 23.0746 11.1072 23.0826 11.1192 23.089C11.1313 23.0954 11.1421 23.0971 11.1492 23.0969C11.1536 23.0968 11.1619 23.0961 11.1768 23.086L12.8022 21.992C13.24 21.6974 13.8174 21.7165 14.2347 22.0394L15.7049 23.1771C15.8981 23.3266 16.1679 23.3266 16.361 23.1771L17.8312 22.0394C18.2486 21.7165 18.826 21.6974 19.2638 21.992L20.8891 23.086C20.9041 23.0961 20.9124 23.0968 20.9168 23.0969C20.9238 23.0971 20.9347 23.0954 20.9467 23.089C20.9587 23.0826 20.9662 23.0746 20.97 23.0686C20.9723 23.0649 20.9764 23.0576 20.9764 23.0396V11.144C20.9764 10.2294 20.235 9.488 19.3204 9.488H12.7456Z" fill={'white'} />
        </svg>
    );
};

export default OffersIcon;
