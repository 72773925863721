import React from 'react';

const EmailIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5621 5.5621C0 7.12419 0 9.63835 0 14.6667V16.16C0 21.1883 0 23.7025 1.5621 25.2646C3.12419 26.8267 5.63835 26.8267 10.6667 26.8267H21.3333C26.3616 26.8267 28.8758 26.8267 30.4379 25.2646C32 23.7025 32 21.1883 32 16.16V14.6667C32 9.63835 32 7.12419 30.4379 5.5621C28.8758 4 26.3616 4 21.3333 4H10.6667C5.63835 4 3.12419 4 1.5621 5.5621ZM4.22243 7.97281C3.70667 7.6438 3.02185 7.79518 2.69284 8.31093C2.36382 8.82669 2.51521 9.51151 3.03096 9.84052L11.4645 15.2205C12.3915 15.8119 13.1596 16.302 13.8319 16.637C14.5396 16.9896 15.2269 17.2164 16.0008 17.2149C16.7748 17.2133 17.4612 16.9839 18.1675 16.6284C18.8384 16.2908 19.6046 15.7977 20.5293 15.2027L28.8661 9.83817C29.3806 9.50713 29.5292 8.82172 29.1982 8.30726C28.8672 7.79281 28.1817 7.64412 27.6673 7.97516L19.3794 13.3082C18.3935 13.9427 17.7255 14.3708 17.1716 14.6495C16.6413 14.9164 16.3039 14.9989 15.9965 14.9995C15.689 15.0001 15.3514 14.9189 14.82 14.6541C14.265 14.3776 13.5954 13.9521 12.607 13.3216L4.22243 7.97281Z'
        fill='#E2E2ED'
      />
    </svg>
  );
};

export default EmailIcon;
