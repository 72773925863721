import { CreditSlider } from 'components/CreditSlider';
import styles from './Step2.module.scss';

interface Step2Props {
    requestId: string | null;
}

const Step2: React.FC<Step2Props> = ({ requestId }) => {
    // console.log("🚀 ~ requestId:", requestId)
    return (
        <div className={styles.step2}>
            <CreditSlider requestId={requestId} />
        </div>
    );
};

export default Step2;
