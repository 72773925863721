import React from 'react';
// import { useDispatch } from 'react-redux';

import styles from './Step10.module.scss';

// interface Step7Props {
//     setIsFormValid: (isValid: boolean) => void; // Проп для управления состоянием валидности формы
//     onFormSubmit: (data: any) => void; // Проп для обработки отправки данных формы
// }

const Step10: React.FC = ({ }) => {
    // const dispatch = useDispatch();

    // const handleFormSubmit = (data: any) => {
    //     console.log("🚀 ~ handleFormSubmit ~ data:", data);
    // };

    // const handleFormValidity = (data: any) => {
    //     console.log("🚀 ~ handleFormValidity ~ data:", data);
    // };

    return (
        <div className={styles.cardWrapper}>
            <div className={styles.card}>
                <h3 className={styles.title}>Карта готова <br />
                    для выдачи клиенту</h3>
                <div>Статус:</div>
                <div className={styles.approveWrapper}>
                    <span style={{ color: 'green' }}>✔</span>
                    <span className={styles.approveText}>Одобрено</span>
                </div>
            </div>
        </div>
    );
};

export default Step10;
