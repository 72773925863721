import React from 'react';

const FiltersIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="filter_icon">
        <g id="Group 514">
          <path id="Vector" d="M20.6637 8.11472C20.6637 7.92164 20.5936 7.73647 20.4688 7.59995C20.344 7.46342 20.1747 7.38672 19.9981 7.38672H13.0094C12.8328 7.38672 12.6635 7.46342 12.5387 7.59995C12.4139 7.73647 12.3438 7.92164 12.3438 8.11472C12.3438 8.3078 12.4139 8.49297 12.5387 8.62949C12.6635 8.76602 12.8328 8.84272 13.0094 8.84272H19.9981C20.1747 8.84272 20.344 8.76602 20.4688 8.62949C20.5936 8.49297 20.6637 8.3078 20.6637 8.11472Z" fill="#2F2F33" />
          <path id="Vector_2" d="M4.06159 8.91825H6.01317C6.18527 9.40502 6.52391 9.81528 6.96924 10.0765C7.41458 10.3378 7.93792 10.4332 8.44679 10.3459C8.95566 10.2585 9.41727 9.99415 9.75006 9.59941C10.0828 9.20467 10.2654 8.70499 10.2654 8.18869C10.2654 7.67239 10.0828 7.17271 9.75006 6.77796C9.41727 6.38322 8.95566 6.11883 8.44679 6.03152C7.93792 5.94422 7.41458 6.03961 6.96924 6.30085C6.52391 6.56209 6.18527 6.97235 6.01317 7.45913H4.06159C3.8681 7.45913 3.68253 7.53599 3.54571 7.67281C3.4089 7.80963 3.33203 7.9952 3.33203 8.18869C3.33203 8.38218 3.4089 8.56775 3.54571 8.70457C3.68253 8.84139 3.8681 8.91825 4.06159 8.91825ZM8.07418 7.45913C8.21847 7.45913 8.35953 7.50192 8.4795 7.58208C8.59948 7.66225 8.69299 7.77619 8.74821 7.9095C8.80343 8.04281 8.81787 8.1895 8.78972 8.33102C8.76157 8.47254 8.69209 8.60254 8.59006 8.70457C8.48803 8.8066 8.35803 8.87608 8.21651 8.90423C8.07499 8.93238 7.9283 8.91793 7.79499 8.86272C7.66168 8.8075 7.54774 8.71399 7.46757 8.59401C7.38741 8.47404 7.34462 8.33298 7.34462 8.18869C7.34462 7.9952 7.42148 7.80963 7.5583 7.67281C7.69512 7.53599 7.88069 7.45913 8.07418 7.45913Z" fill="#2F2F33" />
          <path id="Vector_3" d="M3.33234 15.2654C3.33234 15.4585 3.40247 15.6437 3.52729 15.7802C3.65212 15.9167 3.82142 15.9934 3.99794 15.9934L10.9867 15.9934C11.1633 15.9934 11.3326 15.9167 11.4574 15.7802C11.5822 15.6437 11.6523 15.4585 11.6523 15.2654C11.6523 15.0723 11.5822 14.8872 11.4574 14.7506C11.3326 14.6141 11.1633 14.5374 10.9867 14.5374L3.99794 14.5374C3.82142 14.5374 3.65212 14.6141 3.52729 14.7506C3.40247 14.8872 3.33234 15.0723 3.33234 15.2654Z" fill="#2F2F33" />
          <path id="Vector_4" d="M19.9345 14.6098L17.9829 14.6098C17.8108 14.1231 17.4722 13.7128 17.0268 13.4516C16.5815 13.1903 16.0582 13.0949 15.5493 13.1822C15.0404 13.2695 14.5788 13.5339 14.246 13.9287C13.9133 14.3234 13.7307 14.8231 13.7307 15.3394C13.7307 15.8557 13.9133 16.3554 14.246 16.7501C14.5788 17.1449 15.0404 17.4092 15.5493 17.4966C16.0582 17.5839 16.5815 17.4885 17.0268 17.2272C17.4722 16.966 17.8108 16.5557 17.9829 16.0689H19.9345C20.128 16.0689 20.3136 15.9921 20.4504 15.8553C20.5872 15.7184 20.6641 15.5329 20.6641 15.3394C20.6641 15.1459 20.5872 14.9603 20.4504 14.8235C20.3136 14.6867 20.128 14.6098 19.9345 14.6098ZM15.9219 16.0689C15.7776 16.0689 15.6366 16.0262 15.5166 15.946C15.3966 15.8658 15.3031 15.7519 15.2479 15.6186C15.1927 15.4853 15.1782 15.3386 15.2064 15.1971C15.2345 15.0555 15.304 14.9255 15.406 14.8235C15.5081 14.7215 15.6381 14.652 15.7796 14.6238C15.9211 14.5957 16.0678 14.6101 16.2011 14.6654C16.3344 14.7206 16.4484 14.8141 16.5285 14.9341C16.6087 15.054 16.6515 15.1951 16.6515 15.3394C16.6515 15.5329 16.5746 15.7184 16.4378 15.8553C16.301 15.9921 16.1154 16.0689 15.9219 16.0689Z" fill="#2F2F33" />
        </g>
      </g>
    </svg>
  );
};

export default FiltersIcon;
