import React from 'react';
import styles from './CategorySelect.module.scss'; // Подключаем стили для этого компонента

type CategorySelectProps = {
    selectedCategory: string;
    setSelectedCategory: (value: string) => void;
    offers: any[];
};

// Функция для удаления дубликатов категорий по id
const getUniqueCategories = (offers: any[]) => {
    const categoriesMap = new Map();
    offers.forEach(offer => {
        offer.full_categories?.forEach((category: any) => {
            categoriesMap.set(category.id, category.title);
        });
    });
    return Array.from(categoriesMap, ([id, title]) => ({ id, title }));
};

const CategorySelect: React.FC<CategorySelectProps> = ({ selectedCategory, setSelectedCategory, offers }) => {
    // Получаем уникальные категории
    const uniqueCategories = getUniqueCategories(offers);

    return (
        <div className={styles.selectWrapper}>
            <label htmlFor="categorySelect" className={styles.selectLabel}>
                Категории
            </label>
            <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className={`${styles.selectCategory} ${selectedCategory === '' ? styles.allSelected : ''}`}
            >
                <option value="" style={{ color: 'gray' }}>Все</option>
                {uniqueCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                        {category.title}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default CategorySelect;
